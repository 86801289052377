import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { useHistory } from "react-router-dom";
import { useSchoolInquiryItemsTools } from "../../../../useSchoolInquiryItemsTools";
import {
  useCancelAssignmentMutation,
  useDeleteAssignmentMutation,
} from "../../../../../../slices/schoolInquiries/api";
import { NewDesignContextMenu } from "../../../../../../components/NewDesignContextMenu";

type ActionProp = {
  disabled: boolean;
};

type ContextMenuProps = {
  inquiryId: number;
  departmentInquiryId: number;
  assignmentId: number;
  editAction: ActionProp;
  cancelAction: ActionProp;
  deleteAction: ActionProp;
};

export const ContextMenu = ({
  inquiryId,
  departmentInquiryId,
  assignmentId,
  editAction,
  cancelAction,
  deleteAction,
}: ContextMenuProps) => {
  const { t } = useTranslation("pages");
  const history = useHistory();

  const { handleSuccess, handleError } = useMessageHandlers();

  const { baseUrl } = useSchoolInquiryItemsTools();

  const [deleteAssignment] = useDeleteAssignmentMutation();
  const [cancel] = useCancelAssignmentMutation();

  const deleteHandler = useCallback(() => {
    deleteAssignment({
      inquiryId,
      departmentInquiryId,
      assignmentId,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    assignmentId,
    deleteAssignment,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
  ]);

  const cancelHandler = useCallback(() => {
    cancel({ inquiryId, departmentInquiryId, assignmentId })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    assignmentId,
    cancel,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
  ]);

  const editClickHandler = useCallback(() => {
    history.push(`${baseUrl}/edit/assignment`);
  }, [baseUrl, history]);

  return useMemo(() => {
    return (
      <NewDesignContextMenu
        sx={{ marginLeft: "auto" }}
        buttons={[
          {
            label: t("schoolInquiryDetails.assignmentControls.cancel.label"),
            handler: cancelHandler,
            disabled: cancelAction.disabled,
            confirmation: {
              title: t(
                "schoolInquiryDetails.assignmentControls.cancel.confirmationTitle"
              ),
              description: t("noUndo", { ns: "common" }),
            },
          },
          {
            label: t("schoolInquiryDetails.assignmentControls.edit"),
            disabled: editAction.disabled,
            handler: editClickHandler,
          },
          {
            label: t("schoolInquiryDetails.assignmentControls.delete.label"),
            handler: deleteHandler,
            disabled: deleteAction.disabled,
            confirmation: {
              title: t(
                "schoolInquiryDetails.assignmentControls.delete.confirmationTitle"
              ),
              description: t("noUndo", { ns: "common" }),
            },
          },
        ]}
      />
    );
  }, [
    cancelAction.disabled,
    cancelHandler,
    deleteAction.disabled,
    deleteHandler,
    editAction.disabled,
    editClickHandler,
    t,
  ]);
};
