import React, { useCallback, useState } from "react";
import { ReviewComments } from "./ReviewComments/ReviewComments";
import {
  useAddRequestedItemReviewCommentMutation,
  useGetRequestedItemReviewCommentsQuery,
} from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";

type RequestedItemReviewCommentsProps = {
  inquiryId: number;
  departmentInquiryId: number;
  assignmentId: number;
  id: number;
  reviewCommentsCount: number;
  code: string;
};

export const RequestedItemReviewComments = ({
  inquiryId,
  departmentInquiryId,
  assignmentId,
  id,
  reviewCommentsCount,
  code,
}: RequestedItemReviewCommentsProps) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const { data: reviewComments = [], isLoading: areReviewCommentsLoading } =
    useGetRequestedItemReviewCommentsQuery(
      open
        ? {
            inquiryId,
            departmentInquiryId,
            assignmentId,
            requestedItemId: id,
          }
        : skipToken
    );

  const [
    addRequestedItemReviewComment,
    { isLoading: isRequestedItemReviewCommentAdding },
  ] = useAddRequestedItemReviewCommentMutation();

  const addCommentHandler = useCallback(
    (reviewComment: string) => {
      addRequestedItemReviewComment({
        requestedItemId: id,
        reviewComment,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    },
    [addRequestedItemReviewComment, handleError, handleSuccess, id]
  );

  if (reviewCommentsCount === 0) {
    return null;
  }

  return (
    <ReviewComments
      open={open}
      onOpen={openDialog}
      onClose={closeDialog}
      code={code}
      reviewComments={reviewComments}
      areReviewCommentsLoading={areReviewCommentsLoading}
      onSubmit={addCommentHandler}
      isSubmitting={isRequestedItemReviewCommentAdding}
    />
  );
};
