import { createYupSchema } from "../../../../utils/validation";
import * as yup from "yup";

export const astraEntitySchema = createYupSchema((t) =>
  yup.object({
    logo: yup.mixed().optional().nullable(),
    name: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    code: yup
      .string()
      .min(2, t("certainLength", { characters: 2 }))
      .max(2, t("certainLength", { characters: 2 }))
      .required(t("required")),
    addressLine: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(50, t("tooLong", { maxLength: 50 }))
      .required(t("required")),
    addressLine2: yup
      .string()
      .max(50, t("tooLong", { maxLength: 50 }))
      .nullable()
      .optional(),
    city: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(50, t("tooLong", { maxLength: 50 }))
      .required(t("required")),
    state: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(50, t("tooLong", { maxLength: 50 }))
      .required(t("required")),
    zipCode: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(20, t("tooLong", { maxLength: 50 }))
      .required(t("required")),
    countryId: yup.number().positive(t("required")).nullable(),
    phone: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .optional()
      .nullable(),
    email: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .optional()
      .nullable(),
    website: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .optional()
      .nullable(),
    crNumber: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .optional()
      .nullable(),
  })
);
