import React from "react";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { usePermissions } from "../../../utils/usePermissions";
import { WideDrawer } from "../../../components/wideDrawer/WideDrawer";
import { AddAstraEntity } from "./astraEntityForm/AddAstraEntity";
import { EditAstraEntity } from "./astraEntityForm/EditAstraEntity";
import { AstraEntityDetails } from "./AstraEntityDetails";

export const Drawers = () => {
  const can = usePermissions();

  const { addDrawerOpen, editDrawerOpen, detailsDrawerOpen, closeDrawer } =
    useTableUrlTools("/astra-entities");

  return (
    <>
      {can("addAstraEntities") && (
        <WideDrawer open={addDrawerOpen} close={closeDrawer}>
          <AddAstraEntity />
        </WideDrawer>
      )}
      {can("editAstraEntities") && (
        <WideDrawer open={editDrawerOpen} close={closeDrawer}>
          <EditAstraEntity />
        </WideDrawer>
      )}
      {can("getAstraEntities") && (
        <WideDrawer open={detailsDrawerOpen} close={closeDrawer}>
          <AstraEntityDetails />
        </WideDrawer>
      )}
    </>
  );
};
