import React, { useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectRole } from "../../../components/prefilledSelectors/SelectRole";
import { AddUserForm, EditUserForm } from "./types";
import { usePermissions } from "../../../utils/usePermissions";
import { fieldError, FieldsErrorState } from "../../../utils/formHelpers";
import * as yup from "yup";
import { createYupSchema } from "../../../utils/validation";
import { ControlledCustomField } from "../../../components/controlledFields/ControlledCustomField";
import { SelectSchool } from "../../../components/prefilledSelectors/SelectSchool";
import { Role, roles } from "../../../permissions/permissions";
import { capitalize } from "../../../utils/capitalize";
import { Actions } from "../../../permissions/actions";
import { useSelector } from "../../../store";
import { StyledFieldTitle } from "../../SignIn/StyledComponents";
import { selectSelf } from "../../../slices/auth/selectors";
import { SelectInquiry } from "../../../components/prefilledSelectors/SelectInquiry";

export const userFormSchemaPasswordCreator = createYupSchema((t) =>
  yup
    .string()
    .required(t("required"))
    .min(8, t("tooShort", { minLength: 8 }))
    .max(256, t("tooLong", { maxLength: 256 }))
    .matches(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{0,}$/,
      t("atLeastOneNumAndChar")
    )
);

export const userFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    email: yup
      .string()
      .email(t("incorrectEmail"))
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    firstName: yup
      .string()
      .matches(/^([A-Za-z. ]*)$/, t("incorrectName"))
      .nullable()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    lastName: yup
      .string()
      .matches(/^([A-Za-z. ]*)$/, t("incorrectName"))
      .nullable()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    role: yup.string().required(t("required")),
    schoolId: yup
      .number()
      .positive(t("required"))
      .when("role", {
        is: (value: Role) =>
          value === "teacher" ||
          value === "headOfDepartment" ||
          value === "principal",
        then: (schema) => schema.required(t("required")),
        otherwise: (schema) => schema.nullable(),
      }),
    inquiryManagerInquiriesIds: yup
      .array()
      .of(yup.number().positive())
      .nullable()
      .optional(),
    orderManagerInquiriesIds: yup
      .array()
      .of(yup.number().positive())
      .nullable()
      .optional(),
  })
);

type UserFormProps = {
  fieldsErrors?: FieldsErrorState;
  isActiveCheckboxShown?: boolean;
  editing?: number | null;
  isEditSchoolUserPropsDisabled?: boolean;
};

export const UserForm = ({
  fieldsErrors,
  isActiveCheckboxShown = false,
  editing,
  isEditSchoolUserPropsDisabled,
}: UserFormProps) => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const self = useSelector(selectSelf);

  const { control } = useFormContext<AddUserForm | EditUserForm>();

  const role = useWatch({
    control,
    name: "role",
  });

  const showSchoolField =
    role === "principal" || role === "teacher" || role === "headOfDepartment";
  const showInquiryManagerInquiriesField =
    role === "inquiryManager" && self?.role === "admin";
  const showOrderManagerInquiriesField =
    role === "orderManager" && self?.role === "admin";

  const rolesDisabled = useMemo(
    () =>
      !(
        can("setRoleAdmin") ||
        can("setRoleTeacher") ||
        can("setRolePrincipal") ||
        can("setRoleHeadOfDepartment") ||
        can("setRoleOrderManager") ||
        can("setRoleInquiryManager")
      ) || editing === self?.id,
    [can, editing, self?.id]
  );

  const availableRoles = useMemo(
    () => roles.filter((role) => can(`setRole${capitalize(role)}` as Actions)),
    [can]
  );

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={"12px"}
    >
      <Grid item gridColumn={`span 12`}>
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("users.fields.email")} *`}
        </StyledFieldTitle>
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "email",
              fieldsErrors
            );
            return (
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder={`${t("users.fields.email")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("users.fields.firstName")} *`}
        </StyledFieldTitle>
        <Controller
          control={control}
          name="firstName"
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "firstName",
              fieldsErrors
            );
            return (
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder={`${t("users.fields.firstName")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("users.fields.lastName")} *`}
        </StyledFieldTitle>
        <Controller
          control={control}
          name="lastName"
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "lastName",
              fieldsErrors
            );
            return (
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder={`${t("users.fields.lastName")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
          {`${t("users.fields.role")} *`}
        </StyledFieldTitle>
        <Controller
          control={control}
          name="role"
          defaultValue="inquiryManager"
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "role",
              fieldsErrors
            );
            return (
              <SelectRole
                availableRoles={availableRoles}
                selectProps={field}
                label={`${t("users.fields.role")} *`}
                id="add-user"
                disabled={rolesDisabled || isEditSchoolUserPropsDisabled}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                size="small"
              />
            );
          }}
        />
      </Grid>
      {showSchoolField && (
        <>
          <StyledFieldTitle
            sx={{
              marginTop: "0px !important",
              whiteSpace: "nowrap",
              marginBottom: "-15px",
            }}
          >
            {`${t("users.fields.school")} *`}
          </StyledFieldTitle>
          <Grid item gridColumn="span 12">
            <ControlledCustomField
              control={control}
              name="schoolId"
              render={(errorMessage, { field }) => (
                <SelectSchool
                  fullWidth
                  placeholder={`${t("users.fields.school")} *`}
                  error={!!errorMessage}
                  disabled={isEditSchoolUserPropsDisabled}
                  helperText={errorMessage}
                  {...field}
                />
              )}
            />
          </Grid>
        </>
      )}
      {showInquiryManagerInquiriesField && (
        <>
          <StyledFieldTitle
            sx={{
              marginTop: "0px !important",
              whiteSpace: "nowrap",
              marginBottom: "-11px",
            }}
          >
            {t("users.fields.inquiries")}
          </StyledFieldTitle>
          <Grid item gridColumn="span 12">
            <ControlledCustomField
              control={control}
              name="inquiryManagerInquiriesIds"
              defaultValue={[]}
              render={(errorMessage, { field: { value, onChange } }) => (
                <SelectInquiry
                  error={!!errorMessage}
                  helperText={errorMessage}
                  value={value as number[]}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </>
      )}
      {showOrderManagerInquiriesField && (
        <>
          <StyledFieldTitle
            sx={{
              marginTop: "0px !important",
              whiteSpace: "nowrap",
              marginBottom: "-11px",
            }}
          >
            {t("users.fields.inquiries")}
          </StyledFieldTitle>
          <Grid item gridColumn="span 12">
            <ControlledCustomField
              control={control}
              name="orderManagerInquiriesIds"
              defaultValue={[]}
              render={(errorMessage, { field: { value, onChange } }) => (
                <SelectInquiry
                  error={!!errorMessage}
                  helperText={errorMessage}
                  value={value as number[]}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        </>
      )}
      {isActiveCheckboxShown && (
        <Grid item gridColumn="span 12">
          <StyledFieldTitle sx={{ marginTop: "0px !important" }}>
            {t("users.fields.active")}
          </StyledFieldTitle>
          <Controller
            control={control}
            name="active"
            defaultValue={true}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                disabled={
                  !can("changeUserActive") &&
                  !can("changeUserOfOwnSchoolStatus") &&
                  !can("changeTeacherOfOwnSchoolStatus")
                }
                label={t("users.fields.active")}
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};
