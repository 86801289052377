import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { ConfirmationButton } from "../../../../../../components/ConfirmationButton";
import { Button } from "@mui/material";
import { useApproveAssignmentMutation } from "../../../../../../slices/schoolInquiries/api";
import { Hint } from "../../../../../../components/hints/Hint";
import { colors } from "../../../../../../theme/astra/colors";

type ApproveProps = {
  inquiryId: number;
  departmentInquiryId: number;
  assignmentId: number;
  disabled: boolean;
  displayHelp: boolean;
};

export const Approve = ({
  inquiryId,
  departmentInquiryId,
  assignmentId,
  disabled,
  displayHelp,
}: ApproveProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const [approveAssignment, { isLoading: isApproving }] =
    useApproveAssignmentMutation();

  const approveHandler = useCallback(() => {
    if (disabled || displayHelp) {
      return;
    }

    approveAssignment({
      inquiryId,
      departmentInquiryId,
      assignmentId,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    approveAssignment,
    assignmentId,
    departmentInquiryId,
    disabled,
    displayHelp,
    handleError,
    handleSuccess,
    inquiryId,
  ]);

  return (
    <Hint
      helpText={
        displayHelp
          ? t("hints.schoolInquiryDetails.assignmentControls.approve.disabled")
          : t("hints.schoolInquiryDetails.assignmentControls.approve.available")
      }
    >
      <ConfirmationButton
        component={Button}
        variant="contained"
        loading={isApproving}
        onClick={displayHelp ? undefined : approveHandler}
        proceedButtonText={displayHelp ? t("ok", { ns: "legacy" }) : undefined}
        confirmationTitle={
          displayHelp
            ? t(
                "schoolInquiryDetails.assignmentControls.approve.approveAllFirst"
              )
            : t(
                "schoolInquiryDetails.assignmentControls.approve.confirmationTitle"
              )
        }
        confirmationDescription={
          displayHelp
            ? t(
                "schoolInquiryDetails.assignmentControls.approve.approveItemsFirst"
              )
            : t("noUndo", { ns: "common" })
        }
        disabled={disabled && !displayHelp}
        sx={{
          textTransform: "capitalize",
          color: colors.white,
          backgroundColor: colors.blue,
        }}
      >
        {t("schoolInquiryDetails.assignmentControls.approve.label")}
      </ConfirmationButton>
    </Hint>
  );
};
