import { ItemDetails } from "./types";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { useSelector } from "../../../../../../store";
import { selectSelf } from "../../../../../../slices/auth/selectors";
import { useChangeItemsStatusMutation } from "../../../../../../slices/schoolInquiries/api";
import { useCallback } from "react";

export const useApproveHandler = ({
  inquiryId,
  departmentInquiryId,
  assignmentsIds,
  itemId,
  possibleActions,
}: ItemDetails) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const [changeItemsStatus, { isLoading }] = useChangeItemsStatusMutation();

  const handle = useCallback(() => {
    const schoolStatus = (() => {
      if (self?.role === "headOfDepartment") {
        return "approvedByHead";
      }

      if (self?.role === "principal") {
        return "approvedByPrincipal";
      }
    })();

    if (!schoolStatus) {
      return;
    }

    changeItemsStatus({
      inquiryId,
      departmentInquiryId,
      assignmentsIds,
      itemsIds: [itemId],
      schoolStatus,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    assignmentsIds,
    changeItemsStatus,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    itemId,
    self?.role,
  ]);

  const isPossible = (() => {
    if (self?.role === "headOfDepartment") {
      return possibleActions.includes("setApprovedByHead");
    }

    if (self?.role === "principal") {
      return possibleActions.includes("setApprovedByPrincipal");
    }

    return false;
  })();

  return { handle, isLoading, isPossible };
};
