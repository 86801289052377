import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";
import { CustomDataGrid } from "../../../components/CustomDataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { propToString } from "../../../utils/propToString";
import { GridColDef } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { Actions } from "./Actions";
import { useGetDNsQuery } from "../../../slices/deliveryNotes/api";
import { DeliveryNote } from "../../../slices/deliveryNotes/types";
import { DisplayDateWithoutTime } from "../../../components/DisplayDateWithoutTime";
import { useGetInquiryDetailsQuery } from "../../../slices/inquiries/api";
import { GroupsSwitch } from "../GroupsSwitch";
import { DownloadFileCellBtn } from "./DownloadFileCellBtn";
import { useInquiryDetailsUrlTools } from "../hooks";

export const DataTable: React.FC = () => {
  const { t } = useTranslation("pages");

  const { baseUrl, inquiryId } = useInquiryDetailsUrlTools();

  const { isLoading, isFetching, data, error, refetch } =
    useGetDNsQuery(inquiryId);
  const { data: inquiry } = useGetInquiryDetailsQuery(inquiryId);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: propToString<DeliveryNote>().id.toString(),
        headerName: t("deliveryNotes.fields.id"),
        flex: 0.3,
        type: "number",
      },
      {
        field: propToString<DeliveryNote>().DNNumber || "",
        headerName: t("deliveryNotes.fields.DNNumber"),
        flex: 1,
        renderCell: (params) => (
          <MaterialLink component={Link} to={`${baseUrl}/dn/${params.row.id}`}>
            {params.value}
          </MaterialLink>
        ),
      },
      {
        field: "date",
        headerName: t("deliveryNotes.fields.date"),
        flex: 1,
        renderCell: ({ row }) => (
          <DisplayDateWithoutTime timestamp={row.createdAt.toString()} />
        ),
      },
      {
        field: propToString<DeliveryNote>().originalFile,
        headerName: t("deliveryNotes.fields.originalFile"),
        flex: 1,
        renderCell: (params) => {
          return <DownloadFileCellBtn deliveryNoteId={params.id} original />;
        },
      },
      {
        field: "uploadedFile",
        headerName: t("deliveryNotes.fields.uploadedFile"),
        flex: 1,
        renderCell: (params) => {
          return params.value ? (
            <DownloadFileCellBtn deliveryNoteId={params.id} />
          ) : (
            <p style={{ paddingLeft: "0.7rem" }}>{t("na", { ns: "common" })}</p>
          );
        },
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.15,
        minWidth: 100,
        renderCell: ({ id: dnId }) => (
          <Actions
            inquiryId={inquiryId}
            dnId={typeof dnId === "string" ? parseInt(dnId) : dnId}
          />
        ),
      },
    ],
    [baseUrl, inquiryId, t]
  );

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("inquiries.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage
      breadcrumbs={{
        paths: [
          { href: "/inquiries", text: t("inquiries.title") },
          {
            href: baseUrl,
            text: inquiry?.name || t("inquiries.title"),
          },
        ],
        current: t("deliveryNotes.title"),
        endAdornment: <GroupsSwitch />,
      }}
    >
      <CustomDataGrid
        tableName="department-inquiries-dn"
        columns={columns}
        hideAddButtonPlusSymbol
        rows={data}
        disableSelectionOnClick
        searchFields={["DNNumber"]}
        defaultSortModel={[
          {
            field: "id",
            sort: "desc",
          },
        ]}
      />
    </PaperPage>
  );
};
