import { useMemo } from "react";
import { InquiryDetailsFlattened } from "../../../../slices/inquiries/types";

export const useItemCounts = ({
  inquiryDetails,
  selectedIds,
}: {
  inquiryDetails: InquiryDetailsFlattened;
  selectedIds: number[];
}) => {
  return useMemo(() => {
    const departmentInquiries = inquiryDetails.relatedDepartmentsInquiries;

    const selectedDepartmentInquiries =
      selectedIds.length > 0
        ? departmentInquiries?.filter(({ id }) => selectedIds.includes(id))
        : departmentInquiries;

    return {
      newItemsCount:
        selectedDepartmentInquiries
          ?.map(
            ({ newItemsForCommercialOfferCount }) =>
              newItemsForCommercialOfferCount
          )
          .reduce((prev, curr) => prev + curr, 0) ?? 0,
      submittedItemsCount:
        selectedDepartmentInquiries
          ?.map(
            ({ submittedItemsForCommercialOfferCount }) =>
              submittedItemsForCommercialOfferCount
          )
          .reduce((prev, curr) => prev + curr, 0) ?? 0,
    };
  }, [inquiryDetails.relatedDepartmentsInquiries, selectedIds]);
};
