import React, { useState } from "react";
import { alpha, Box, BoxProps, ButtonBase, useTheme } from "@mui/material";
import { colors } from "../theme/astra/colors";
import { useTranslation } from "react-i18next";
import { ShopProduct } from "../slices/shop/types";
import photoPlaceholder from "../assets/no-photo.svg";

type ImageListProps = Pick<ShopProduct, "images" | "name">;

export const ImageList = ({
  images,
  name,
  sx,
  ...props
}: BoxProps & ImageListProps) => {
  const theme = useTheme();
  const { t } = useTranslation("pages");

  const [selectedIndex, setSelectedIndex] = useState(0);

  const isEmpty = images.length === 0;
  const selectedImage = images[selectedIndex];

  return (
    <Box
      sx={{
        width: "668px",
        height: "552px",
        borderRadius: "4px",
        border: `1px solid ${colors.dustBlue6}`,
        boxShadow: "2px 4px 10px 0px rgba(22, 65, 115, 0.05)",
        padding: "15px",
        display: "flex",
        gap: "16px",
        ...sx,
      }}
      {...props}
    >
      {!isEmpty && (
        <Box
          sx={{
            width: "100px",
            height: "100%",
            overflowY: "auto",
            flexShrink: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {images.map((image, index) => (
              <ButtonBase
                key={image}
                sx={{
                  borderRadius: "4px",
                  border: `1px solid ${theme.palette.secondary.dark}`,
                  overflow: "hidden",
                  opacity: index === selectedIndex ? 1 : 0.5,

                  img: {
                    display: "block",
                    width: "100%",
                    height: "auto",
                  },
                }}
                onClick={() => setSelectedIndex(index)}
              >
                <img
                  src={image}
                  alt={`${t("shopDetails.imageList.previewAlt", {
                    product: name,
                  })} ${index + 1}`}
                />
              </ButtonBase>
            ))}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          height: "100%",
          borderRadius: "4px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: alpha(theme.palette.primary.dark, 0.035),

          img: {
            display: "block",
            width: "auto",
            maxWidth: "100%",
            height: "auto",
            maxHeight: "100%",
          },
        }}
      >
        {!isEmpty ? (
          <img
            src={selectedImage}
            alt={t("shopDetails.imageList.imageAlt", { product: name })}
          />
        ) : (
          <img
            src={photoPlaceholder}
            alt={t("shopDetails.imageList.noImageAlt", { product: name })}
          />
        )}
      </Box>
    </Box>
  );
};
