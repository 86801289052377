import React from "react";
import { useTranslation } from "react-i18next";
import { useSchoolInquiryItemsTools } from "./useSchoolInquiryItemsTools";
import { useHeaderTitle } from "../../slices/ui/useHeaderTitle";
import { getTitle } from "../../utils/getTitle";
import { Helmet } from "react-helmet";
import { Content } from "./Content/Content";
import { Drawers } from "./Drawers/Drawers";
import { useScrollToTop } from "../../utils/useScrollToTop";
import { useGetSchoolInquiryQuery } from "../../slices/schoolInquiries/api";

export const SchoolInquiryItems = () => {
  const { t } = useTranslation("pages");

  const { inquiryId } = useSchoolInquiryItemsTools();

  const { data: inquiry } = useGetSchoolInquiryQuery(inquiryId);

  const title: string = inquiry?.name ?? t("schoolInquiryDetails.loadingTitle");

  useHeaderTitle(title);

  useScrollToTop();

  return (
    <>
      <Helmet>
        <title>{getTitle(title)}</title>
      </Helmet>
      <Content />
      <Drawers />
    </>
  );
};
