import React from "react";
import { AssignmentSchoolStatus } from "../../../../../types";
import { ASSIGNMENT_STATUS_CHIP_STYLES } from "../../../../../consts";
import { SchoolStatusChip } from "../../../../../components/SchoolStatusChip";
import { useTranslation } from "react-i18next";

type StatusProps = {
  schoolStatus: AssignmentSchoolStatus;
};

export const Status = ({ schoolStatus }: StatusProps) => {
  const { t } = useTranslation("common");

  return (
    <SchoolStatusChip
      chipStyle={ASSIGNMENT_STATUS_CHIP_STYLES[schoolStatus]}
      label={t(`assignmentSchoolStatuses.${schoolStatus}`)}
    />
  );
};
