import React, { useCallback } from "react";
import { Details } from "./Details";
import { useHistory, useRouteMatch } from "react-router-dom";
import { parseNumericParam } from "../../../utils/parseNumericParam";
import { WideDrawer } from "../../../components/wideDrawer/WideDrawer";
import { UserDetails } from "./UserDetails";

type MatchesParams = {
  id: string;
};

export const Drawers = () => {
  const editingUrlMatch = useRouteMatch<MatchesParams>({
    path: `/searches/:id`,
    exact: true,
  });

  const userDetailsUrlMatch = useRouteMatch<MatchesParams>({
    path: `/searches/:id/user`,
    exact: true,
  });

  const history = useHistory();

  const closeDrawerHandler = useCallback(() => {
    history.push("/searches");
  }, [history]);

  return (
    <>
      <WideDrawer open={!!editingUrlMatch} close={closeDrawerHandler}>
        <Details
          id={parseNumericParam(editingUrlMatch?.params.id)}
          onClose={closeDrawerHandler}
        />
      </WideDrawer>
      <WideDrawer open={!!userDetailsUrlMatch} close={closeDrawerHandler}>
        <UserDetails
          id={parseNumericParam(userDetailsUrlMatch?.params.id)}
          onClose={closeDrawerHandler}
        />
      </WideDrawer>
    </>
  );
};
