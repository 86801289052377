import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { useSendAssignmentToReviewMutation } from "../../../../../../slices/schoolInquiries/api";
import { ConfirmationButton } from "../../../../../../components/ConfirmationButton";
import { Button } from "@mui/material";
import { Hint } from "../../../../../../components/hints/Hint";

type NeedReviewProps = {
  inquiryId: number;
  departmentInquiryId: number;
  assignmentId: number;
  disabled: boolean;
  ownAssignment: boolean;
};

export const NeedReview = ({
  inquiryId,
  departmentInquiryId,
  assignmentId,
  disabled,
  ownAssignment,
}: NeedReviewProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const [sendToReview, { isLoading: isSending }] =
    useSendAssignmentToReviewMutation();

  const sendToReviewHandler = useCallback(() => {
    if (disabled) {
      return;
    }

    sendToReview({
      inquiryId,
      departmentInquiryId,
      assignmentId,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    disabled,
    departmentInquiryId,
    assignmentId,
    sendToReview,
    inquiryId,
    handleSuccess,
    handleError,
  ]);

  return (
    <Hint
      helpText={
        ownAssignment
          ? t("hints.schoolInquiryDetails.assignmentControls.needReviewSelf")
          : t("hints.schoolInquiryDetails.assignmentControls.needReview")
      }
    >
      <ConfirmationButton
        component={Button}
        variant="outlined"
        loading={isSending}
        onClick={sendToReviewHandler}
        confirmationTitle={t(
          "schoolInquiryDetails.assignmentControls.sendToReview.confirmationTitle"
        )}
        confirmationDescription={t("noUndo", { ns: "common" })}
        disabled={disabled}
        sx={{
          textTransform: "capitalize",
          fontSize: "13px",
        }}
      >
        {t("schoolInquiryDetails.assignmentControls.sendToReview.label")}
      </ConfirmationButton>
    </Hint>
  );
};
