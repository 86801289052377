import { RequestedItemDetails } from "./types";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { useCallback } from "react";
import { useChangeRequestedItemsStatusMutation } from "../../../../../../slices/schoolInquiries/api";

export const useSendToReviewHandler = ({
  inquiryId,
  departmentInquiryId,
  assignmentId,
  requestedItemId,
  possibleActions,
}: RequestedItemDetails) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const [submitWithoutComment, { isLoading: isSubmittingWithoutComment }] =
    useChangeRequestedItemsStatusMutation();

  const submitWithoutCommentHandler = useCallback(() => {
    return submitWithoutComment({
      inquiryId,
      departmentInquiryId,
      assignmentId,
      itemsIds: [requestedItemId],
      schoolStatus: "needReviewByTeacher",
    })
      .unwrap()
      .then(handleSuccess)
      .catch((e) => {
        handleError(e);
      });
  }, [
    assignmentId,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    requestedItemId,
    submitWithoutComment,
  ]);

  const [submitWithComment, { isLoading: isSubmittingWithComment }] =
    useChangeRequestedItemsStatusMutation();

  const submitWithCommentHandler = useCallback(
    (reviewComment?: string) => {
      return submitWithComment({
        inquiryId,
        departmentInquiryId,
        assignmentId,
        itemsIds: [requestedItemId],
        schoolStatus: "needReviewByTeacher",
        reviewComment,
      })
        .unwrap()
        .then(handleSuccess)
        .catch((e) => {
          handleError(e);
        });
    },
    [
      assignmentId,
      departmentInquiryId,
      handleError,
      handleSuccess,
      inquiryId,
      requestedItemId,
      submitWithComment,
    ]
  );

  const isPossible = possibleActions.includes("setNeedReviewByTeacher");

  const isVisible = !possibleActions.includes("hideNeedReviewAction");

  return {
    submitWithoutCommentHandler,
    isSubmittingWithoutComment,
    submitWithCommentHandler,
    isSubmittingWithComment,
    isPossible,
    isVisible,
  };
};
