import { useCallback, useEffect, useState } from "react";
import { useGetInquiryCustomFieldsQuery } from "../../../../slices/inquiries/api";
import { Orientation } from "../../../../types";
import { existingFields, nonSortableFields } from "./existingFields";
import { IncludedFields } from "./types";
import { useLocalStorage } from "usehooks-ts";
import { difference } from "lodash";

export const useOptions = ({ inquiryId }: { inquiryId: number }) => {
  const [orientation, setOrientation] = useState<Orientation>("portrait");

  const [includedFields, setIncludedFields] = useLocalStorage<IncludedFields>(
    `inquiry.${inquiryId}.generateCommercialOffer.includedFields`,
    []
  );

  const { data: customFields, isLoading: areCustomFieldsLoading } =
    useGetInquiryCustomFieldsQuery({ inquiryId });

  useEffect(() => {
    if (customFields) {
      const fields: IncludedFields = [];

      for (const existingField of existingFields.filter(
        (existingField) => !nonSortableFields.includes(existingField)
      )) {
        fields.push({
          id: existingField,
          enabled: true,
        });
      }

      for (const customField of customFields) {
        fields.push({
          id: customField,
          enabled: true,
        });
      }

      for (const nonSortableField of nonSortableFields) {
        fields.push({
          id: nonSortableField,
          enabled: true,
        });
      }

      if (
        difference(
          fields.map((field) => field.id),
          includedFields.map((includedField) => includedField.id)
        ).length > 0
      ) {
        setIncludedFields(fields);
      }
    }
  }, [customFields, includedFields, setIncludedFields]);

  const orientationChangeHandler = useCallback((value: Orientation) => {
    setOrientation(value);
  }, []);

  const includedFieldsChangeHandler = useCallback(
    (fields: IncludedFields) => {
      setIncludedFields(fields);
    },
    [setIncludedFields]
  );

  return {
    areOptionsLoading: areCustomFieldsLoading,
    orientation,
    orientationChangeHandler,
    includedFields,
    includedFieldsChangeHandler,
  };
};
