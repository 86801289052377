import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useYup } from "../../../utils/validation";
import { editDepartmentInquirySchemaCreator } from "./DepartmentForm/schema";
import { useForm } from "react-hook-form";
import { EditSchoolDepartmentInquiryForm } from "../../../slices/schoolInquiries/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import {
  useEditSchoolDepartmentInquiryMutation,
  useGetSchoolDepartmentInquiryQuery,
  useGetSchoolInquiryQuery,
} from "../../../slices/schoolInquiries/api";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { DepartmentForm } from "./DepartmentForm/DepartmentForm";
import { useGetCurrenciesQuery } from "../../../slices/currencies/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { NewDesignForm } from "../../../components/wideDrawer/NewDesignForm";
import { useSchoolInquiryItemsTools } from "../useSchoolInquiryItemsTools";

export const EditDepartment = () => {
  const { t } = useTranslation("pages");
  const feedSuccess = useFeedSuccess();
  const { closeDrawer, inquiryId, assignmentId, departmentInquiryId } =
    useSchoolInquiryItemsTools();

  const { data: inquiry, isLoading: isInquiryLoading } =
    useGetSchoolInquiryQuery(inquiryId);

  const schema = useYup(editDepartmentInquirySchemaCreator);
  const { handleSubmit, control, reset } =
    useForm<EditSchoolDepartmentInquiryForm>({
      resolver: yupResolver(schema),
    });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schoolInquiry.errors.addDepartment")
  );

  const [editSchoolDepartmentInquiry, { isLoading }] =
    useEditSchoolDepartmentInquiryMutation();

  const onSubmit = useCallback(
    (form: EditSchoolDepartmentInquiryForm) => {
      if (departmentInquiryId) {
        editSchoolDepartmentInquiry({
          inquiryId,
          departmentInquiryId,
          ...form,
        })
          .unwrap()
          .then((res) => {
            feedSuccess(res.message);
            closeDrawer();
            reset();
          })
          .catch(catchFormError);
      }
    },
    [
      catchFormError,
      closeDrawer,
      departmentInquiryId,
      editSchoolDepartmentInquiry,
      feedSuccess,
      inquiryId,
      reset,
    ]
  );

  const { isLoading: areCurrenciesLoading, data: currencies } =
    useGetCurrenciesQuery();

  const { data: departmentInquiry } = useGetSchoolDepartmentInquiryQuery(
    departmentInquiryId && !assignmentId
      ? { inquiryId, departmentInquiryId }
      : skipToken
  );

  useEffect(() => {
    if (currencies && departmentInquiry) {
      reset({
        budget:
          (departmentInquiry?.budget && departmentInquiry?.budget / 100) ||
          undefined,
        budgetCurrencyId:
          departmentInquiry.budgetCurrency?.id ??
          currencies.find(({ code }) => code === "QAR")?.id,
      });
    }
  }, [currencies, departmentInquiry, reset]);

  if (isInquiryLoading || areCurrenciesLoading) {
    return <Spinner />;
  }

  if (!inquiry) {
    return null;
  }

  return (
    <NewDesignForm
      heading={t("schoolInquiryDetails.editDepartment")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
      submitText={t("schoolInquiryDetails.submitEdit")}
    >
      <DepartmentForm
        control={control}
        fieldsErrors={fieldsErrors}
        schoolId={inquiry.school.id}
        isBudget={!!inquiry?.budget || false}
        editing
      />
    </NewDesignForm>
  );
};
