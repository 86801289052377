import React from "react";
import { ChevronDown } from "../astraIcons/ChevronDown";
import { IconButton, IconButtonProps } from "../astraComponents/IconButton";
import { colors } from "../../theme/astra/colors";
import { Plus } from "../astraIcons/Plus";
import { Minus } from "../astraIcons/Minus";

type ExpandButtonProps = Omit<IconButtonProps, "variant" | "sx"> & {
  expanded?: boolean;
  header?: boolean;
};

export const ExpandButton = ({
  expanded,
  header,
  ...props
}: ExpandButtonProps) => {
  const icon = (() => {
    if (header) {
      return expanded ? (
        <Minus sx={{ width: "14px !important" }} />
      ) : (
        <Plus sx={{ width: "16px !important" }} />
      );
    }

    return (
      <ChevronDown
        sx={{
          width: "8px !important",
          transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
        }}
      />
    );
  })();

  return (
    <IconButton
      variant="transparent"
      sx={{
        width: "24px",
        height: "24px",

        ".MuiSvgIcon-root": {
          color: colors.darkBlue,
        },
      }}
      {...props}
    >
      {icon}
    </IconButton>
  );
};
