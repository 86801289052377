import { getDownloadUrl } from "../../utils/S3UploadFile";
import { saveBlob } from "../../utils/downloadFileFromServer";

export const downloadFile = (intId: number, type: "origco" | "origdn") => {
  let filename: string;
  getDownloadUrl(intId, type)
    .then((response) => response.json())
    .then((resData) => {
      filename = resData.filename;
      return fetch(resData.url);
    })
    .then((fileResponse) => fileResponse.blob())
    .then((blob) => saveBlob(blob, filename));
};
