import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "./store";
import { closeInvitation } from "./slices/auth/slice";
import { usePermissions } from "./utils/usePermissions";
import { SignIn } from "./containers/SignIn/SignIn";
import { Users } from "./containers/Users/Users";
import { Countries } from "./containers/Countries/Countries";
import { Suppliers } from "./containers/Suppliers/Suppliers";
import { Departments } from "./containers/Departments/Departments";
import { Commoditycodes } from "./containers/Commoditycodes/Commoditycodes";
import { Categories } from "./containers/Categories/Categories";
import { Schools } from "./containers/Schools/Schools";
import { Inquiries } from "./containers/Inquiries/Inquiries";
import { InquiryDetails } from "./containers/InquiriesDetails/InquiryDetails";
import { Products } from "./containers/Products/Products";
import { Items } from "./containers/Items/Items";
import { ItemDetails } from "./containers/ItemDetails/ItemDetails";
import { Currencies } from "./containers/Currencies/Currencies";
import { ForgotPassword } from "./containers/SignIn/ForgotPassword/ForgotPassword";
import { SchoolInquiries } from "./containers/SchoolInquiries/SchoolInquiries";
import { InvitationDialog } from "./components/legacyLayout/Invitation";
import { useCanViewSchoolInquiries } from "./permissions/commonChecks";
import { NewItems } from "./containers/NewItems/NewItems";
import { Searches } from "./containers/Searches/Searches";
import { ShopCatalog } from "./containers/ShopCatalog/ShopCatalog";
import { ShopDetails } from "./containers/ShopDetails/ShopDetails";
import { DeprecatedLayout } from "./layouts/DeprecatedLayout/DeprecatedLayout";
import { MainLayout } from "./layouts/MainLayout/MainLayout";
import {
  isAuthenticated,
  isGreetingShown,
  selectSelf,
} from "./slices/auth/selectors";
import { useIsSchoolTeam } from "./utils/useIsSchoolTeam";
import { SchoolInquiryItems } from "./containers/SchoolInquiryItems/SchoolInquiryItems";
import { Inventory } from "./containers/Inventory/Inventory";
import { AstraEntities } from "./containers/AstraEntities/AstraEntities";

export const Navigator: React.FC = () => {
  const authenticated = useSelector(isAuthenticated);
  const showGreeting = useSelector(isGreetingShown);
  const dispatch = useDispatch();

  const user = useSelector(selectSelf);
  const can = usePermissions();

  const isSchoolTeam = useIsSchoolTeam();

  const allowedToViewSchoolInquiries = useCanViewSchoolInquiries();

  const closeInvitationPopup = () => {
    dispatch(closeInvitation());
  };

  useEffect(() => {
    const element = document.getElementById("tidio-chat");
    if (element) {
      element.style.display = user?.school ? "block" : "none";
    }

    if (!element && user?.school) {
      const script = document.createElement("script");

      script.src = "//code.tidio.co/h0w9ha71zzygtgnouzdhbwe8nlefrmfb.js";
      script.async = true;

      document.body.appendChild(script);
    }
  }, [user]);

  return (
    <>
      {/* TODO Remove if not used */}
      {showGreeting && (
        <InvitationDialog
          firstName={user?.firstName}
          open={showGreeting}
          handleProceed={closeInvitationPopup}
        />
      )}
      {authenticated ? (
        <Switch>
          <Route
            path={[
              "/shop/:l1category/:l2category/:l3category/product/:productId",
              "/shop/:l1category/:l2category/product/:productId",
              "/shop/:l1category/product/:productId",
              "/shop/product/:productId",
            ]}
          >
            <MainLayout cart>
              <ShopDetails />
            </MainLayout>
          </Route>
          <Route
            path={[
              "/shop/:l1category/:l2category/:l3category",
              "/shop/:l1category/:l2category",
              "/shop/:l1category",
              "/shop",
            ]}
          >
            <MainLayout cart>
              <ShopCatalog />
            </MainLayout>
          </Route>

          {(can("viewUsers") || can("viewUsersMenu")) && (
            <Route path="/users">
              {isSchoolTeam ? (
                <MainLayout>
                  <Users />
                </MainLayout>
              ) : (
                <DeprecatedLayout>
                  <Users />
                </DeprecatedLayout>
              )}
            </Route>
          )}
          {can("getCountries") && (
            <Route path="/countries">
              <DeprecatedLayout>
                <Countries />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getSuppliers") && can("viewSupplierMenu") && (
            <Route path="/suppliers">
              <DeprecatedLayout>
                <Suppliers />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getDepartments") && can("viewDepartmentsMenu") && (
            <Route path="/departments">
              <DeprecatedLayout>
                <Departments />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getCommoditycodes") && (
            <Route path="/commoditycodes">
              <DeprecatedLayout>
                <Commoditycodes />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getCategories") && can("showCategories") && (
            <Route path="/categories">
              <DeprecatedLayout>
                <Categories />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getAstraEntities") && (
            <Route path="/astra-entities">
              <DeprecatedLayout>
                <AstraEntities />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getSchools") && (
            <Route path="/schools">
              <DeprecatedLayout>
                <Schools />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getProducts") && (
            <Route path="/products">
              <DeprecatedLayout>
                <Products />
              </DeprecatedLayout>
            </Route>
          )}
          {can("viewInventory") && (
            <Route path="/inventory">
              <DeprecatedLayout>
                <Inventory />
              </DeprecatedLayout>
            </Route>
          )}
          {can("viewCurrencies") && (
            <Route path="/currencies">
              <DeprecatedLayout>
                <Currencies />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getItem") && (
            <Route path="/inquiries/:inquiryId/details/:detailId/items/:tableName/:itemId/details">
              <DeprecatedLayout>
                <ItemDetails />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getItems") && (
            <Route path="/inquiries/:inquiryId/details/:detailId/items/:tableName">
              <DeprecatedLayout>
                <Items />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getItem") && (
            <Route path="/newitems/:itemId/details">
              <DeprecatedLayout>
                <ItemDetails />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getItems") && (
            <Route path="/newitems">
              <DeprecatedLayout>
                <NewItems />
              </DeprecatedLayout>
            </Route>
          )}
          {can("getInquiry") && (
            <Route
              path={[
                "/inquiries/:inquiryId/details/:tableName",
                "/inquiries/:inquiryId/details",
              ]}
            >
              <DeprecatedLayout>
                <InquiryDetails />
              </DeprecatedLayout>
            </Route>
          )}
          {allowedToViewSchoolInquiries && (
            <Route
              path={[
                "/schoolinquiries/:inquiryId/details/:departmentInquiryId/assignments/:assignmentId/:itemId",
                "/schoolinquiries/:inquiryId/details/:departmentInquiryId/assignments/:assignmentId",
                "/schoolinquiries/:inquiryId/details/:departmentInquiryId/items/:itemId",
                "/schoolinquiries/:inquiryId/details/:departmentInquiryId/items",
                "/schoolinquiries/:inquiryId/details",
              ]}
            >
              <MainLayout>
                <SchoolInquiryItems />
              </MainLayout>
            </Route>
          )}
          {can("getInquiries") && (
            <Route path="/inquiries">
              <DeprecatedLayout>
                <Inquiries />
              </DeprecatedLayout>
            </Route>
          )}
          {allowedToViewSchoolInquiries && (
            <Route
              path={[
                "/schoolinquiries/:inquiryId/department/:departmentInquiryId/assignment/:assignmentId",
                "/schoolinquiries/:inquiryId/department/:departmentInquiryId",
                "/schoolinquiries/:inquiryId",
                "/schoolinquiries",
              ]}
            >
              <MainLayout>
                <SchoolInquiries />
              </MainLayout>
            </Route>
          )}
          {can("getSearches") && (
            <Route path="/searches">
              <DeprecatedLayout>
                <Searches />
              </DeprecatedLayout>
            </Route>
          )}
          {!can("getInquiries") && allowedToViewSchoolInquiries && (
            <Route path="/">
              <MainLayout>
                <SchoolInquiries />
              </MainLayout>
            </Route>
          )}
          {can("getInquiries") && (
            <Route path="/">
              <DeprecatedLayout>
                <Inquiries />
              </DeprecatedLayout>
            </Route>
          )}
        </Switch>
      ) : (
        <Switch>
          <Route path={"/forgotpassword"}>
            <DeprecatedLayout>
              <ForgotPassword />
            </DeprecatedLayout>
          </Route>
          <Route>
            <DeprecatedLayout>
              <SignIn />
            </DeprecatedLayout>
          </Route>
        </Switch>
      )}
    </>
  );
};
