import { useCallback } from "react";
import { useFeedError } from "../../../../../utils/feedHooks";
import { useItemsTableBaseUrl } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { COMMON_MAX_FILE_SIZE_IN_BYTES } from "../../../../../consts";
import { getDefaultHeaders, getUrl } from "../../../../../utils/api";

export const useImportHandler = () => {
  const { t } = useTranslation("pages");

  const { detailId } = useItemsTableBaseUrl();

  const feedError = useFeedError();

  return useCallback(() => {
    const uploadFileElement = document.createElement("input");

    uploadFileElement.setAttribute("type", "file");
    uploadFileElement.setAttribute("style", "display:none;");
    uploadFileElement.setAttribute("accept", ".xlsx");

    uploadFileElement.addEventListener("change", async (event) => {
      const target = event.target as HTMLInputElement;

      if (!target) {
        return;
      }

      const files = target.files;

      if (!files || files.length === 0) {
        feedError(t("noFile", { ns: "common" }));
        return;
      }

      if (files[0].name.split(".").slice(-1)[0].toLowerCase() !== "xlsx") {
        feedError(t("wrongFormat", { ns: "common" }));
        return;
      }

      if (files[0].size > COMMON_MAX_FILE_SIZE_IN_BYTES) {
        feedError(t("fileTooLarge", { ns: "validationErrors", size: "25 MB" }));
        return;
      }

      const data = new FormData();

      data.append("importFile", files[0]);
      data.append("departmentId", detailId.toString());

      const defaultHeaders = getDefaultHeaders(true);

      const resultFile = await fetch(getUrl("/import/items"), {
        headers: { Authorization: defaultHeaders["Authorization"] },
        method: "POST",
        body: data,
      });

      if (!resultFile.ok) {
        feedError(t("processingError", { ns: "validationErrors" }));
        return;
      }

      const resultFileBlob = await resultFile.blob();
      const resultFileURL = URL.createObjectURL(resultFileBlob);

      const hiddenElement = document.createElement("a");

      hiddenElement.href = resultFileURL;
      hiddenElement.download = `Items Import Report ${new Date().toLocaleString(
        "en-GB"
      )}.txt`;

      hiddenElement.click();

      URL.revokeObjectURL(resultFileURL);

      hiddenElement.remove();
      uploadFileElement.remove();

      location.reload();
    });

    uploadFileElement.click();
  }, [detailId, feedError, t]);
};
