import { useCallback } from "react";
import { useFeedError, useFeedSuccess } from "../../../../utils/feedHooks";
import { useGenerateCOMutation } from "../../../../slices/commercialOffers/api";
import { useSelector } from "../../../../store";
import { selectSelf } from "../../../../slices/auth/selectors";
import { InquiryDetailsFlattened } from "../../../../slices/inquiries/types";
import { useTranslation } from "react-i18next";
import { Orientation } from "../../../../types";
import { IncludedFields } from "./types";

export const useGenerate = ({
  inquiryId,
  inquiryDetails,
  selectedIds,
  orientation,
  includedFields,
}: {
  inquiryId: number;
  inquiryDetails: InquiryDetailsFlattened;
  selectedIds: number[];
  orientation: Orientation;
  includedFields: IncludedFields;
}) => {
  const { t } = useTranslation("pages");

  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();

  const self = useSelector(selectSelf);

  const [generateCommercialOffer] = useGenerateCOMutation();

  return useCallback(async () => {
    if (!self) {
      return null;
    }

    const departmentInquiriesIds =
      selectedIds.length > 0
        ? selectedIds
        : inquiryDetails.relatedDepartmentsInquiries.map(({ id }) => id) || [];

    return generateCommercialOffer({
      inquiryId,
      departmentInquiriesIds,
      userId: self.id,
      orientation,
      includedFields: includedFields
        .filter((includedField) => includedField.enabled)
        .map((includedField) => includedField.id),
    })
      .unwrap()
      .then((response) => {
        feedSuccess(t("commercialOffers.successes.add"));

        return response.id;
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("commercialOffers.errors.add"));
      });
  }, [
    self,
    selectedIds,
    inquiryDetails.relatedDepartmentsInquiries,
    generateCommercialOffer,
    inquiryId,
    orientation,
    includedFields,
    feedSuccess,
    t,
    feedError,
  ]);
};
