import React, { useCallback, useMemo } from "react";
import { Link as MaterialLink } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { CustomDataGrid } from "../../../components/CustomDataGrid/CustomDataGrid";
import { useTranslation } from "react-i18next";
import { Actions } from "./Actions";
import { GridColDef } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { usePermissions } from "../../../utils/usePermissions";
import { useGetAstraEntitiesQuery } from "../../../slices/astraEntities/api";

const searchFields = ["id", "name", "code"];

export const DataTable: React.FC = () => {
  const { t } = useTranslation("pages");
  const history = useHistory();

  const can = usePermissions();

  const {
    isLoading: areAstraEntitiesLoading,
    isFetching: areAstraEntitiesFetching,
    data: astraEntities,
    error: astraEntitiesError,
    refetch: refetchAstraEntities,
  } = useGetAstraEntitiesQuery();

  const onAddClick = useCallback(
    () => history.push("/astra-entities/add"),
    [history]
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "id",
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        minWidth: 50,
        flex: 0.2,
      },
      {
        field: "name",
        headerName: t("columns.name", { ns: "common" }),
        flex: 1,
        renderCell: (params) => (
          <MaterialLink component={Link} to={`/astra-entities/${params.id}`}>
            {params.value}
          </MaterialLink>
        ),
      },
      {
        field: "code",
        headerName: t("columns.code", { ns: "common" }),
        flex: 1,
      },
      {
        field: "shortAddress",
        headerName: t("astraEntities.columns.address"),
        flex: 1,
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.15,
        minWidth: 100,
        renderCell: (params) => <Actions id={params.id} />,
      },
    ],
    [t]
  );

  if (!can("getAstraEntities")) {
    return null;
  }

  if (astraEntitiesError) {
    return (
      <PaperPageError
        refetch={refetchAstraEntities}
        disabled={areAstraEntitiesFetching}
        message={t("astraEntities.errors.refetch")}
      />
    );
  }

  if (areAstraEntitiesLoading || !astraEntities) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage>
      <CustomDataGrid
        addButtonText={t("astraEntities.addAstraEntity")}
        addButtonDisabled={!can("addAstraEntities")}
        onAddClick={onAddClick}
        tableName="astraEntities"
        columns={columns}
        rows={astraEntities}
        disableSelectionOnClick
        searchFields={searchFields}
      />
    </PaperPage>
  );
};
