import { createYupSchema, useYup } from "../../../../utils/validation";
import * as yup from "yup";
import { SUPPLIER_PRODUCT_CODE_REGEXP } from "../../../../consts";
import * as Yup from "yup";

export const useItemFormSchema = (customFields: string[]) =>
  useYup(
    createYupSchema((t) => {
      const customFieldsSchema: Record<
        string,
        Yup.StringSchema<string | null | undefined>
      > = {};

      for (const customField of customFields) {
        customFieldsSchema[customField] = yup.string().nullable().optional();
      }

      return yup.object().shape(
        {
          name: yup
            .string()
            .max(256, t("tooLong", { maxLength: 256 }))
            .required(t("required")),
          supplierProductCode: yup
            .string()
            .matches(SUPPLIER_PRODUCT_CODE_REGEXP, {
              excludeEmptyString: true,
              message: t("supplierProductCode"),
            })
            .max(256, t("tooLong", { maxLength: 256 }))
            .when("supplierId", {
              is: (value: number | null) =>
                typeof value === "number" && value > 0,
              then: (schema) => schema.required(t("required")),
              otherwise: (schema) => schema.nullable().optional(),
            }),
          quantity: yup
            .number()
            .typeError(t("number"))
            .min(1, t("numMustBeGreater", { minNum: 0 }))
            .required(t("required")),
          supplierId: yup
            .number()
            .typeError(t("number"))
            .when("supplierProductCode", {
              is: (value: string | null) =>
                typeof value === "string" && value.length > 0,
              then: (schema) => schema.required(t("required")),
              otherwise: (schema) => schema.nullable().optional(),
            }),
          customerLineNumber: yup
            .number()
            .typeError(t("customId"))
            .positive(t("customId"))
            .nullable()
            .transform((_, val) => {
              if (!val) {
                return null;
              }
              return val === Number(val).toString() ? Number(val) : val;
            }),
          ...customFieldsSchema,
        },
        [["supplierProductCode", "supplierId"]]
      );
    })
  );
