import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useAddCountryMutation } from "../../../slices/countries/api";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { Form } from "../../../components/wideDrawer/Form";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { CountryForm } from "./CountryForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../utils/validation";
import { countryFormSchemaCreator } from "./schema";

export type AddCountryForm = {
  name: string;
  code: string;
  defaultAstraEntityId: number | null;
  active: boolean;
};

export const Add: React.FC = () => {
  const { t } = useTranslation();

  const { control, handleSubmit, reset } = useForm<AddCountryForm>({
    resolver: yupResolver(useYup(countryFormSchemaCreator)),
  });

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("countries.errors.add")
  );

  const [addCountry, { isLoading }] = useAddCountryMutation();

  const { closeDrawer } = useTableUrlTools("/countries");
  const onSubmit = useCallback(
    (form: AddCountryForm) => {
      addCountry({ ...form, active: true })
        .unwrap()
        .then(({ id }) => {
          feedSuccess(t("countries.addSuccess"), `/countries/${id}`);
          closeDrawer();
          reset();
        })
        .catch(catchFormError);
    },
    [addCountry, catchFormError, closeDrawer, feedSuccess, reset, t]
  );

  return (
    <Form
      heading={t("countries.addCountry")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
    >
      <CountryForm control={control} fieldsErrors={fieldsErrors} />
    </Form>
  );
};
