import React from "react";
import { RequestedItemDetails } from "./types";
import { useApproveHandler } from "./useApproveHandler";
import { useSendToReviewHandler } from "./useSendToReviewHandler";
import { useDeleteHandler } from "./useDeleteHandler";
import { Box } from "@mui/material";
import { ApproveAction } from "../buttons/ApproveAction";
import { SendToReviewAction } from "../buttons/SendToReviewAction";
import { DeleteAction } from "../buttons/DeleteAction";

export const RequestedItemStatusActions = (props: RequestedItemDetails) => {
  const {
    handle: approveHandler,
    isLoading: isApproving,
    isPossible: isApprovalPossible,
  } = useApproveHandler(props);

  const {
    submitWithoutCommentHandler,
    isSubmittingWithoutComment,
    submitWithCommentHandler,
    isSubmittingWithComment,
    isPossible: isSendingToReviewPossible,
    isVisible: isSendToReviewVisible,
  } = useSendToReviewHandler(props);

  const {
    handle: deleteHandler,
    isLoading: isRequestedItemDeleting,
    isPossible: isDeletionPossible,
    isVisible: isDeletionVisible,
  } = useDeleteHandler(props);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <ApproveAction
        onClick={approveHandler}
        isLoading={isApproving}
        disabled={!isApprovalPossible}
      />
      {isSendToReviewVisible && (
        <SendToReviewAction
          onSubmitWithoutComment={submitWithoutCommentHandler}
          isSubmittingWithoutComment={isSubmittingWithoutComment}
          onSubmitWithComment={submitWithCommentHandler}
          isSubmittingWithComment={isSubmittingWithComment}
          disabled={!isSendingToReviewPossible}
        />
      )}
      {isDeletionVisible && (
        <DeleteAction
          onClick={deleteHandler}
          isLoading={isRequestedItemDeleting}
          disabled={!isDeletionPossible}
        />
      )}
    </Box>
  );
};
