import { ItemDetails } from "./types";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { useChangeItemsStatusMutation } from "../../../../../../slices/schoolInquiries/api";
import { useCallback } from "react";

export const useCancelHandler = ({
  inquiryId,
  departmentInquiryId,
  assignmentsIds,
  itemId,
  possibleActions,
}: ItemDetails) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const [changeItemsStatus, { isLoading }] = useChangeItemsStatusMutation();

  const handle = useCallback(() => {
    changeItemsStatus({
      inquiryId,
      departmentInquiryId,
      assignmentsIds,
      itemsIds: [itemId],
      schoolStatus: "cancelled",
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    assignmentsIds,
    changeItemsStatus,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    itemId,
  ]);

  const isPossible = possibleActions.includes("setCancelled");

  const isVisible = possibleActions.includes("showCancelAction");

  return { handle, isLoading, isPossible, isVisible };
};
