import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useYup } from "../../../utils/validation";
import { useSchoolInquiriesTableUrlTools } from "../hooks";
import { useForm } from "react-hook-form";
import {
  AddAssignmentForm,
  SendToAstraForm as SendToAstraFormType,
} from "../../../slices/schoolInquiries/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { schemaCreator } from "./SendToAstraForm/schema";
import { SendToAstraForm } from "./SendToAstraForm/SendToAstraForm";
import { useSubmitSchoolInquiryMutation } from "../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../utils/useMessageHandlers";
import { NewDesignForm } from "../../../components/wideDrawer/NewDesignForm";

export const SendToAstra = () => {
  const { t } = useTranslation("pages");

  const { handleSuccess } = useMessageHandlers();

  const {
    closeDrawer,
    inquiryId,
    inquiryResult: { data: inquiry },
  } = useSchoolInquiriesTableUrlTools();

  const [submitInquiry, { isLoading: isSubmitting }] =
    useSubmitSchoolInquiryMutation();

  const schema = useYup(schemaCreator);
  const { handleSubmit, control, reset } = useForm<SendToAstraFormType>({
    resolver: yupResolver(schema),
  });
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("schoolInquiryDetails.inquiryControls.drawer.errorWhileSent")
  );

  const onSubmit = useCallback(
    (form: AddAssignmentForm) => {
      if (
        !inquiryId ||
        !inquiry?.departmentInquiries ||
        inquiry.departmentInquiries.length < 1
      ) {
        return null;
      }

      const departmentInquiriesIds =
        inquiry?.departmentInquiries.map(({ id }) => id) ?? [];

      submitInquiry({ id: inquiryId, departmentInquiriesIds, ...form })
        .unwrap()
        .then((res) => {
          handleSuccess(res);
          closeDrawer();
          reset();
        })
        .catch(catchFormError);
    },
    [
      catchFormError,
      closeDrawer,
      handleSuccess,
      inquiry?.departmentInquiries,
      inquiryId,
      reset,
      submitInquiry,
    ]
  );

  return (
    <NewDesignForm
      heading={t("schoolInquiryDetails.inquiryControls.drawer.label")}
      onSubmit={handleSubmit(onSubmit)}
      formError={formError}
      loading={isSubmitting}
      submitText={t("schoolInquiryDetails.inquiryControls.drawer.label")}
    >
      <SendToAstraForm control={control} fieldsErrors={fieldsErrors} />
    </NewDesignForm>
  );
};
