import React, { useCallback, useMemo, useState } from "react";
import {
  Chip,
  IconButton,
  Link as MaterialLink,
  Stack,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useGetInquiryDetailsQuery } from "../../../slices/inquiries/api";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../utils/usePermissions";
import { propToString } from "../../../utils/propToString";
import { DepartmentInquiryFlattened } from "../../../slices/inquiries/types";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../components/layout/PaperPage";
import { StatisticsBar } from "../StatisticsBar";
import { downloadFileFromServer } from "../../../utils/downloadFileFromServer";
import { INQUIRY_STATUS_COLORS } from "../../../consts";
import { Actions } from "./Actions";
import { GroupsSwitch } from "../GroupsSwitch";
import { useSelector } from "../../../store";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { useGenerateDNMutation } from "../../../slices/deliveryNotes/api";
import { FileDownloadOutlined } from "@mui/icons-material";
import { CircleButton } from "../../../components/toolbar/CircleButton";
import { useInquiryDetailsUrlTools } from "../hooks";
import { useLocalStorage } from "usehooks-ts";
import { FilteredCustomDataGrid } from "./FilteredCustomDataGrid";
import { useHeaderTitle } from "../../../slices/ui/useHeaderTitle";
import { GenerateCommercialOffer } from "./GenerateCommercialOffer/GenerateCommercialOffer";
import { downloadFile } from "../downloadFile";

const DEFAULT_SORT_MODEL: GridSortModel = [
  {
    field: "name",
    sort: "asc",
  },
];

export const Details = () => {
  const { t } = useTranslation("pages");
  const { inquiryId, baseUrl } = useInquiryDetailsUrlTools();
  const can = usePermissions();
  const userSelfData = useSelector((state) => state.auth.user);
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const [statusesFilter, setStatusesFilter] = useLocalStorage<string[]>(
    `filter.depInquiryByStatus-${inquiryId}`,
    []
  );

  const {
    isLoading: areInquiryDetailsLoading,
    isFetching: areInquiryDetailsFetching,
    data: inquiryDetails,
    error: inquiryDetailsError,
    refetch: refetchInquiryDetails,
  } = useGetInquiryDetailsQuery(inquiryId);

  useHeaderTitle(inquiryDetails?.name || t("inquiryDetails.title"));

  const warehouseItemsCount = useMemo(() => {
    const allDeps = inquiryDetails?.relatedDepartmentsInquiries;

    const selectedDeps =
      selectedIds.length > 0
        ? allDeps?.filter(({ id }) => selectedIds.includes(id))
        : allDeps;

    return (
      selectedDeps
        ?.map(({ warehouseItemsCount }) => warehouseItemsCount)
        .reduce((prev, curr) => prev + curr, 0) ?? 0
    );
  }, [inquiryDetails?.relatedDepartmentsInquiries, selectedIds]);
  const [generateDN] = useGenerateDNMutation();

  const onExportClick = useCallback(
    () => downloadFileFromServer(inquiryId),
    [inquiryId]
  );

  const onGenerateDNClick = useCallback(async () => {
    const departmentInquiriesIds =
      selectedIds.length > 0
        ? selectedIds
        : inquiryDetails?.relatedDepartmentsInquiries.map(({ id }) => id) || [];

    generateDN({
      inquiryId,
      departmentInquiriesIds,
      userId: userSelfData ? userSelfData.id : 0,
    })
      .unwrap()
      .then((res) => {
        if (res.originalFile || res.id) {
          downloadFile(res.id, "origdn");
        }
        feedSuccess(t("deliveryNotes.successes.add"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("deliveryNotes.errors.add"));
      });
  }, [
    selectedIds,
    inquiryDetails?.relatedDepartmentsInquiries,
    generateDN,
    inquiryId,
    userSelfData,
    feedSuccess,
    t,
    feedError,
  ]);

  const buttons = useMemo(() => {
    const exportButton = (
      <Tooltip title={t("inquiryDetails.export") as string}>
        <IconButton disabled={!can("exportInquiries")} onClick={onExportClick}>
          <FileDownloadOutlined />
        </IconButton>
      </Tooltip>
    );

    const generateCommercialOffer = inquiryDetails ? (
      <GenerateCommercialOffer
        inquiryDetails={inquiryDetails}
        baseUrl={baseUrl}
        inquiryId={inquiryId}
        selectedIds={selectedIds}
      />
    ) : null;

    const generateDeliveryNote = (
      <CircleButton
        tooltip={t("inquiryDetails.generateDN.tooltip")}
        confirmation={{
          title: t("inquiryDetails.generateDN.confirmationTitle"),
          description: t("inquiryDetails.generateDN.confirmationDescription", {
            count: warehouseItemsCount,
          }),
        }}
        onClick={onGenerateDNClick}
        disabled={!can("addDeliveryNote")}
      >
        {t("inquiryDetails.generateDN.button")}
      </CircleButton>
    );

    if (selectedIds.length > 0) {
      return (
        <Stack direction="row" gap={"5px"}>
          {generateCommercialOffer}
          {generateDeliveryNote}
        </Stack>
      );
    }

    return (
      <Stack direction="row" gap={"5px"}>
        {exportButton}
        {generateCommercialOffer}
        {generateDeliveryNote}
      </Stack>
    );
  }, [
    baseUrl,
    can,
    inquiryDetails,
    inquiryId,
    onExportClick,
    onGenerateDNClick,
    selectedIds,
    t,
    warehouseItemsCount,
  ]);

  const columns = useMemo<GridColDef<DepartmentInquiryFlattened>[]>(
    () => [
      {
        field: "name",
        headerName: t("inquiryDetails.fields.name"),
        flex: 1,
        renderCell: ({ row }) => {
          let tableName = null;

          if (can("getItemsForOrdering")) {
            tableName = "ordering";
          }

          if (can("getItemsForMatching")) {
            tableName = "matching";
          }

          if (!can("getItems") || !tableName) {
            return <>{row.name}</>;
          }
          return (
            <MaterialLink
              component={Link}
              to={`${baseUrl}/${row.id}/items/${tableName}`}
            >
              {row.name}
            </MaterialLink>
          );
        },
      },
      {
        field: propToString<DepartmentInquiryFlattened>().status,
        headerName: t("inquiryDetails.fields.status"),
        flex: 1,
        renderCell: ({ row }) => (
          <Chip
            label={<b>{t(`inquiries.statuses.${row.status}`)}</b>}
            sx={{
              backgroundColor: INQUIRY_STATUS_COLORS[row.status],
              color: "white",
            }}
          />
        ),
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.15,
        minWidth: 100,
        renderCell: ({ row }) => (
          <Actions
            inquiryId={inquiryId}
            departmentId={row.id}
            status={row.status}
          />
        ),
      },
    ],
    [baseUrl, can, inquiryId, t]
  );

  if (inquiryDetailsError) {
    return (
      <PaperPageError
        refetch={refetchInquiryDetails}
        disabled={areInquiryDetailsFetching}
        message={t("inquiries.errors.refetch")}
      />
    );
  }

  if (areInquiryDetailsLoading || !inquiryDetails) {
    return <PaperPageSpinner />;
  }

  const {
    relatedDepartmentsInquiries: departmentInquiriesData,
    statistics,
    ...inquiryData
  } = inquiryDetails;

  return (
    <PaperPage
      breadcrumbs={{
        paths: [{ href: "/inquiries", text: t("inquiries.title") }],
        current: inquiryData.name,
        endAdornment: <GroupsSwitch />,
      }}
    >
      {/* Hidden in PC-409 */}
      {/* <InquiryData data={inquiryData} /> */}
      <StatisticsBar
        statistics={statistics}
        setStatusesFilter={setStatusesFilter}
      />
      <FilteredCustomDataGrid
        tableName={`department-inquiries-${inquiryId}`}
        columns={columns}
        hideAddButtonPlusSymbol
        rows={departmentInquiriesData}
        disableSelectionOnClick
        buttons={buttons}
        searchFields={["id", "name"]}
        onSelectionModelChange={(ids) => setSelectedIds(ids as number[])}
        checkboxSelection
        statusesFilter={statusesFilter}
        setStatusesFilter={setStatusesFilter}
        defaultSortModel={DEFAULT_SORT_MODEL}
      />
    </PaperPage>
  );
};
