import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GridRowId } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { useFeedError, useFeedSuccess } from "../../../utils/feedHooks";
import { CustomDataGridRowActions } from "../../../components/CustomDataGrid/CustomDataGridRowActions";
import { usePermissions } from "../../../utils/usePermissions";
import { useDeleteAstraEntityMutation } from "../../../slices/astraEntities/api";

type ActionsProps = {
  id: GridRowId;
};

export const Actions = ({ id }: ActionsProps) => {
  const { t } = useTranslation("pages");

  const history = useHistory();

  const can = usePermissions();
  const feedSuccess = useFeedSuccess();
  const feedError = useFeedError();

  const intId = typeof id === "string" ? parseInt(id) : id;

  const [deleteAstraEntity] = useDeleteAstraEntityMutation();

  const onEdit = useCallback(() => {
    history.push(`/astra-entities/${intId}/edit`);
  }, [history, intId]);

  const onDelete = useCallback(() => {
    deleteAstraEntity({ id: intId })
      .unwrap()
      .then(() => {
        feedSuccess(t("astraEntities.successes.delete"));
      })
      .catch((error) => {
        feedError(error?.data?.message ?? t("astraEntities.errors.delete"));
      });
  }, [deleteAstraEntity, feedError, feedSuccess, intId, t]);

  return (
    <CustomDataGridRowActions
      confirmationTitle={t("astraEntity.actions.delete.confirmationTitle")}
      confirmationDescription={t(
        "astraEntity.actions.delete.confirmationDescription"
      )}
      onEditClick={onEdit}
      onDeleteClick={onDelete}
      canEdit={can("editAstraEntities")}
      canDelete={can("deleteAstraEntities")}
    />
  );
};
