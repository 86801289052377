import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import { Box } from "@mui/material";
import { useCanBulkSubmit } from "./useCanBulkSubmit";
import {
  useChangeItemsStatusMutation,
  useGetSchoolInquiryQuery,
} from "../../../../../slices/schoolInquiries/api";
import { Hint } from "../../../../../components/hints/Hint";
import { useSelector } from "react-redux";

import { selectSelf } from "../../../../../slices/auth/selectors";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { ToolbarButton } from "../ToolbarButton";

type TableControlsProps = {
  allIds: number[];
  selectedIds?: number[];
};

export const TableControls = ({ allIds, selectedIds }: TableControlsProps) => {
  const { t } = useTranslation("pages");
  const self = useSelector(selectSelf);
  const { handleSuccess, handleError } = useMessageHandlers();

  const { inquiryId, departmentInquiryId } = useSchoolInquiryItemsTools();

  const { data: inquiry } = useGetSchoolInquiryQuery(inquiryId);

  const departmentInquiry = useMemo(
    () =>
      inquiry?.departmentInquiries.find(({ id }) => id === departmentInquiryId),
    [departmentInquiryId, inquiry?.departmentInquiries]
  );

  const [changeStatus, { isLoading: isChanging }] =
    useChangeItemsStatusMutation();

  const bulkApproveStatus = useCanBulkSubmit({ departmentInquiryId });

  const bulkSelectedApproveHandler = useCallback(() => {
    if (
      departmentInquiry &&
      bulkApproveStatus &&
      departmentInquiryId &&
      selectedIds &&
      selectedIds.length > 0
    ) {
      changeStatus({
        inquiryId,
        departmentInquiryId,
        assignmentsIds: departmentInquiry.assignments.map(({ id }) => id),
        itemsIds: selectedIds,
        schoolStatus: bulkApproveStatus,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    bulkApproveStatus,
    changeStatus,
    departmentInquiry,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    selectedIds,
  ]);

  const bulkApproveHandler = useCallback(() => {
    if (departmentInquiry && bulkApproveStatus && departmentInquiryId) {
      changeStatus({
        inquiryId,
        departmentInquiryId,
        assignmentsIds: departmentInquiry.assignments.map(({ id }) => id),
        itemsIds: allIds,
        schoolStatus: bulkApproveStatus,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    allIds,
    bulkApproveStatus,
    changeStatus,
    departmentInquiry,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
  ]);

  return (
    <Box
      sx={{
        marginTop: "16px",
        marginBottom: "16px",
        display: "flex",
        gap: "16px",
      }}
    >
      <Hint
        helpText={
          self?.role === "principal"
            ? !bulkApproveStatus || !selectedIds || selectedIds.length < 1
              ? t("hints.schoolInquiryDetails.approveItemsPrincipal.disabled")
              : t("hints.schoolInquiryDetails.approveItemsPrincipal.available")
            : !bulkApproveStatus || !selectedIds || selectedIds.length < 1
            ? t("hints.schoolInquiryDetails.approveItems.disabled")
            : t("hints.schoolInquiryDetails.approveItems.available")
        }
      >
        <ConfirmationButton
          component={ToolbarButton}
          onClick={bulkSelectedApproveHandler}
          confirmationTitle={t(
            "schoolInquiryDetails.itemsTableActions.approve.confirmationTitle"
          )}
          confirmationDescription={t("noUndo", { ns: "common" })}
          size="small"
          variant="outlined"
          loading={isChanging}
          disabled={
            !bulkApproveStatus || !selectedIds || selectedIds.length < 1
          }
          sx={{
            textTransform: "none",
          }}
        >
          {t("schoolInquiryDetails.itemsTableActions.approve.label")}
        </ConfirmationButton>
      </Hint>
      {allIds.length > 0 && (
        <ConfirmationButton
          component={ToolbarButton}
          confirmationTitle={t(
            "schoolInquiryDetails.itemsTableActions.approveAllItems.confirmationTitle"
          )}
          confirmationDescription={t(
            "schoolInquiryDetails.itemsTableActions.approveAllItems.confirmationDescription"
          )}
          size="small"
          variant="outlined"
          onClick={bulkApproveHandler}
          disabled={!bulkApproveStatus}
          sx={{
            textTransform: "none",
          }}
        >
          {t("schoolInquiryDetails.itemsTableActions.approveAllItems.label")}
        </ConfirmationButton>
      )}
    </Box>
  );
};
