import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import {
  AddAstraEntityRequestDto,
  AddAstraEntityResponseDto,
  DeleteAstraEntityRequestDto,
  DeleteAstraEntityResponseDto,
  EditAstraEntityRequestDto,
  EditAstraEntityResponseDto,
  GetAstraEntitiesAsOptionsResponseDto,
  GetAstraEntitiesResponseDto,
  GetAstraEntityRequestDto,
  GetAstraEntityResponseDto,
} from "./types";

export const astraEntitiesApi = createApi({
  reducerPath: "astraEntitiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/astra-entities"),
    prepareHeaders,
  }),
  tagTypes: ["AstraEntity"],
  endpoints: (builder) => ({
    getAstraEntities: builder.query<GetAstraEntitiesResponseDto, void>({
      query: () => "",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "AstraEntity" as const,
                id,
              })),
              { type: "AstraEntity", id: "list" },
            ]
          : [{ type: "AstraEntity", id: "list" }],
    }),
    getAstraEntitiesAsOptions: builder.query<
      GetAstraEntitiesAsOptionsResponseDto,
      void
    >({
      query: () => "/as-options",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "AstraEntity" as const,
                id,
              })),
              { type: "AstraEntity", id: "list" },
            ]
          : [{ type: "AstraEntity", id: "list" }],
    }),
    getAstraEntity: builder.query<
      GetAstraEntityResponseDto,
      GetAstraEntityRequestDto
    >({
      query: ({ id }) => `/${id}`,
      providesTags: (_result, _error, { id }) => [{ type: "AstraEntity", id }],
    }),
    addAstraEntity: builder.mutation<
      AddAstraEntityResponseDto,
      AddAstraEntityRequestDto
    >({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "AstraEntity", id: "list" }],
    }),
    editAstraEntity: builder.mutation<
      EditAstraEntityResponseDto,
      EditAstraEntityRequestDto
    >({
      query: ({ id, removeLogo, ...body }) => {
        return {
          url: removeLogo ? `/${id}?removeLogo=true` : `/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: "AstraEntity", id: "list" },
        { type: "AstraEntity", id },
      ],
    }),
    deleteAstraEntity: builder.mutation<
      DeleteAstraEntityResponseDto,
      DeleteAstraEntityRequestDto
    >({
      query: ({ id }) => ({
        url: `${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "AstraEntity", id: "list" },
        { type: "AstraEntity", id },
      ],
    }),
  }),
});

export const {
  useGetAstraEntitiesQuery,
  useGetAstraEntitiesAsOptionsQuery,
  useGetAstraEntityQuery,
  useAddAstraEntityMutation,
  useEditAstraEntityMutation,
  useDeleteAstraEntityMutation,
} = astraEntitiesApi;
