import React from "react";
import { NeedReview } from "../../../../components/astraIcons/NeedReview";
import { colors } from "../../../../theme/astra/colors";
import { SvgIconProps } from "@mui/material";

export const RequiresAttention = ({ sx, ...props }: SvgIconProps) => {
  return (
    <NeedReview
      sx={{
        color: colors.orange,
        ...sx,
      }}
      {...props}
    />
  );
};
