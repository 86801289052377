import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../components/wideDrawer/Form";
import { useForm } from "react-hook-form";
import { useFeedSuccess } from "../../../../utils/feedHooks";
import { useSubmitFormErrorHander } from "../../../../utils/formHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useEditInquiryMutation,
  useGetCommercialOffersCountQuery,
  useGetInquiryQuery,
} from "../../../../slices/inquiries/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useYup } from "../../../../utils/validation";
import { Spinner } from "../../../../components/wideDrawer/Spinner";
import { BasicDetailsForm, basicDetailsSchemaCreator } from "./BasicDetails";
import { useInquiryDetailsUrlTools } from "../../hooks";
import { useBasicDetailsUrlTools } from "../useBasicDetailsUrlTools";
import { EditInquiryDetailsForm } from "./types";

export const Edit: React.FC = () => {
  const { t } = useTranslation("pages");
  const { inquiryId: id, baseUrl } = useInquiryDetailsUrlTools();

  const { closeDrawer } = useBasicDetailsUrlTools(`${baseUrl}/details`);

  const {
    isFetching: isInquiriesDataLoading,
    data: inquiry,
    error: inquiriesDataLoadingError,
  } = useGetInquiryQuery(id ?? skipToken);

  const {
    data: commercialOffersCount,
    isLoading: isCommercialOffersCountLoading,
    error: commercialOffersCountLoadingError,
  } = useGetCommercialOffersCountQuery(id ?? skipToken);

  const { handleSubmit, control, formState, setValue, reset } =
    useForm<EditInquiryDetailsForm>({
      resolver: yupResolver(useYup(basicDetailsSchemaCreator)),

      defaultValues: {
        inquiryManagersIds: [],
        orderManagersIds: [],
        relatedDepartments: [],
      },
    });

  const [editInquiry, { isLoading: isEditLoading }] = useEditInquiryMutation();

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("inquiries.errors.edit")
  );

  const onSubmit = useCallback(
    ({ dueDate, customFields, ...form }: EditInquiryDetailsForm) => {
      if (!id) {
        return null;
      }

      editInquiry({
        ...form,
        id,
        dueDate: dueDate && dueDate.valueOf() + 12 * 3600 * 1000,
        customFields: customFields.map(({ name }) => name),
      })
        .unwrap()
        .then(() => {
          feedSuccess(t("inquiries.successes.edit"));
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [catchFormError, closeDrawer, editInquiry, feedSuccess, id, t]
  );

  useEffect(() => {
    if (inquiry) {
      reset({
        schoolId: inquiry.school.id,
        userId: inquiry.user?.id,
        inquiryManagersIds: inquiry
          ? inquiry.inquiryManagers.map((inquiryManager) => inquiryManager.id)
          : [],
        orderManagersIds: inquiry
          ? inquiry.orderManagers.map((orderManager) => orderManager.id)
          : [],
        name: inquiry.name ?? "",
        priority: inquiry ? inquiry.priority : "normal",
        dueDate: inquiry && inquiry.dueDate ? new Date(inquiry.dueDate) : null,
        relatedDepartments: inquiry ? inquiry.relatedDepartments : [],
        astraEntityId: inquiry.astraEntity?.id ?? null,
        customFields: inquiry.customFields.map((customField) => ({
          name: customField,
        })),
      });
    }
  }, [inquiry, reset]);

  useEffect(() => {
    if (inquiriesDataLoadingError || commercialOffersCountLoadingError) {
      closeDrawer();
    }
  }, [
    closeDrawer,
    commercialOffersCountLoadingError,
    inquiriesDataLoadingError,
  ]);

  if (isInquiriesDataLoading || isCommercialOffersCountLoading) {
    return <Spinner />;
  }

  if (!inquiry || typeof commercialOffersCount !== "number") {
    return null;
  }

  return (
    <Form
      heading={t("inquiries.edit")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <BasicDetailsForm
        control={control}
        formState={formState}
        setValue={setValue}
        fieldsErrors={fieldsErrors}
        astraEntityFieldDisabled={commercialOffersCount > 0}
      />
    </Form>
  );
};
