import React from "react";
import { createSvgIcon } from "@mui/material";

export const Approve = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM5.5 12C5.5 8.41015 8.41015 5.5 12 5.5C15.5899 5.5 18.5 8.41015 18.5 12C18.5 15.5899 15.5899 18.5 12 18.5C8.41015 18.5 5.5 15.5899 5.5 12ZM14.9005 10.1233C15.1067 9.764 14.9825 9.30563 14.6233 9.09949C14.264 8.89334 13.8056 9.01747 13.5995 9.37674L11.3059 13.374C11.2052 13.5495 10.9486 13.5389 10.8627 13.3558L10.429 12.4314C10.253 12.0564 9.8064 11.8951 9.43141 12.071C9.05643 12.247 8.89507 12.6936 9.07102 13.0686L9.50475 13.993C10.1063 15.275 11.9021 15.3489 12.6069 14.1206L14.9005 10.1233Z"
      fill="currentColor"
    />
  </svg>,
  "Approve"
);
