import { useCallback, useState } from "react";
import { getDownloadUrl } from "../../../../utils/S3UploadFile";
import { saveBlob } from "../../../../utils/downloadFileFromServer";

export const useDownloadPdf = () => {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const [pdf, setPdf] = useState<{
    name: string;
    url: string;
  } | null>(null);

  const preparePdfDownload = useCallback((commercialOfferId: number) => {
    getDownloadUrl(commercialOfferId, "origco")
      .then((response) => response.json())
      .then((resData) => {
        setPdf({
          name: resData.filename,
          url: resData.url,
        });
      });
  }, []);

  const downloadPdfClickHandler = useCallback(() => {
    if (pdf) {
      fetch(pdf.url, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => saveBlob(blob, pdf.name));
    }
  }, [pdf]);

  return {
    pdf,
    preparePdfDownload,
    downloadPdfClickHandler,
    isPreviewVisible,
    setIsPreviewVisible,
  };
};
