import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../../theme/astra/colors";
import { Line } from "./Line";

type TitleProps = {
  inquiryName: string;
  schoolName: string;
};

export const Title = memo(({ inquiryName, schoolName }: TitleProps) => {
  return (
    <Box
      sx={{
        marginTop: "50px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        component="h1"
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: colors.dustBlue1,
          lineHeight: "21px",
        }}
      >
        {inquiryName}
      </Typography>
      <Typography
        component="span"
        sx={{
          fontSize: "10px",
          fontWeight: "400",
          color: colors.dustBlue2,
          lineHeight: "10px",
        }}
      >
        {schoolName}
      </Typography>
      <Line />
    </Box>
  );
});
