export const existingFields = [
  "index",
  "department",
  "supplier",
  "supplierProductCode",
  "description",
  "quantity",
  "unitCost",
  "totalAmount",
] as const;

export const nonSortableFields: Array<typeof existingFields[number]> = [
  "unitCost",
  "totalAmount",
];
