import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { AstraEntityFormProp } from "./types";
import { FieldsErrorState } from "../../../../utils/formHelpers";
import { ControlledTextField } from "../../../../components/controlledFields/ControlledTextField";
import { AutocompleteCountry } from "../../../../components/prefilledSelectors/AutocompleteCountry";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { LogoField } from "./LogoField";

type AstraEntityForm = {
  control: Control<AstraEntityFormProp>;
  fieldsErrors?: FieldsErrorState;
};

// This looks like a false positive
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AstraEntityForm = ({ control, fieldsErrors }: AstraEntityForm) => {
  const { t } = useTranslation("pages");

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 2,
      }}
    >
      <Box
        sx={{
          gridColumn: "span 12",
        }}
      >
        <Controller
          control={control}
          name="logo"
          render={({ field: { value, onChange } }) => (
            <LogoField value={value} onChange={onChange} />
          )}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 8",
        }}
      >
        <ControlledTextField
          control={control}
          name="name"
          label={`${t("astraEntities.fields.name")} *`}
          fieldsErrors={fieldsErrors}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 4",
        }}
      >
        <ControlledTextField
          control={control}
          name="code"
          label={`${t("astraEntities.fields.code")} *`}
          fieldsErrors={fieldsErrors}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 12",
        }}
      >
        <ControlledTextField
          control={control}
          name="addressLine"
          label={`${t("astraEntities.fields.addressLine")} *`}
          fieldsErrors={fieldsErrors}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 12",
        }}
      >
        <ControlledTextField
          control={control}
          name="addressLine2"
          label={t("astraEntities.fields.addressLine2")}
          fieldsErrors={fieldsErrors}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 6",
        }}
      >
        <ControlledTextField
          control={control}
          name="state"
          label={`${t("astraEntities.fields.state")} *`}
          fieldsErrors={fieldsErrors}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 6",
        }}
      >
        <ControlledTextField
          control={control}
          name="city"
          label={`${t("astraEntities.fields.city")} *`}
          fieldsErrors={fieldsErrors}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 6",
        }}
      >
        <ControlledTextField
          control={control}
          name="zipCode"
          label={`${t("astraEntities.fields.zipCode")} *`}
          fieldsErrors={fieldsErrors}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 6",
        }}
      >
        <ControlledCustomField
          control={control}
          name="countryId"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field: { onChange, value } }) => (
            <AutocompleteCountry
              id="country"
              label={`${t("astraEntities.fields.country")} *`}
              onChange={onChange}
              value={value as number}
              error={!!errorMessage}
              helperText={errorMessage}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 6",
        }}
      >
        <ControlledTextField
          control={control}
          name="phone"
          label={t("astraEntities.fields.phone")}
          fieldsErrors={fieldsErrors}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 6",
        }}
      >
        <ControlledTextField
          control={control}
          name="email"
          label={t("astraEntities.fields.email")}
          fieldsErrors={fieldsErrors}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 12",
        }}
      >
        <ControlledTextField
          control={control}
          name="website"
          label={t("astraEntities.fields.website")}
          fieldsErrors={fieldsErrors}
        />
      </Box>
      <Box
        sx={{
          gridColumn: "span 12",
        }}
      >
        <ControlledTextField
          control={control}
          name="crNumber"
          label={t("astraEntities.fields.crNumber")}
          fieldsErrors={fieldsErrors}
        />
      </Box>
    </Box>
  );
};
