import React from "react";
import { useSelector } from "../../../../store";
import { selectSelfRole } from "../../../../slices/auth/selectors";
import { HeaderForPrincipal } from "./HeaderForPrincipal/HeaderForPrincipal";
import { HeaderForHead } from "./HeaderForHead/HeaderForHead";
import { HeaderForTeacher } from "./HeaderForTeacher/HeaderForTeacher";

export const Header = () => {
  const selfRole = useSelector(selectSelfRole);

  if (!selfRole) {
    return null;
  }

  switch (selfRole) {
    case "admin":
    case "principal":
      return <HeaderForPrincipal />;
    case "headOfDepartment":
      return <HeaderForHead />;
    case "teacher":
      return <HeaderForTeacher />;
    default:
      return null;
  }
};
