import React, { useCallback, useMemo, useState } from "react";
import {
  PaperPage,
  PaperPageError,
  PaperPageSpinner,
} from "../../../../components/layout/PaperPage";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  Link as MaterialLink,
  Stack,
  styled,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../../utils/usePermissions";
import {
  useChangeItemInquiryStatusMutation,
  useGetItemsQuery,
} from "../../../../slices/items/api";
import {
  ItemOrderStatistics,
  itemOrderStatuses,
  ItemServerCrudResponse,
} from "../../../../slices/items/types";
import { useLocalStorage } from "usehooks-ts";
import {
  DEFAULT_PAGE_SIZE,
  INQUIRY_STATUSES_FOR_ORDER_TABLE,
} from "../../../../consts";
import { OrderStatistics } from "./OrderStatistics";
import { SelectItemOrderStatusCell } from "../customCells/SelectItemOrderStatusCell";
import { useSearchDebounce } from "../../../../utils/useSearchDebounce";
import { GroupsSwitch } from "../GroupsSwitch";
import { ProductLink } from "../ProductLink";
import { Actions } from "./Actions";
import { useItemsTableBaseUrl } from "../../hooks";
import { MultipleSelectFilter } from "../../../../components/toolbar/MultipleSelectFilter";
import { CustomDataGridServerSide } from "../../../../components/CustomDataGrid/CustomDataGridServerSide";
import { GridSelectionModel } from "@mui/x-data-grid/models/gridSelectionModel";
import { AssignmentOutlined } from "@mui/icons-material";
import { useHeaderTitle } from "../../../../slices/ui/useHeaderTitle";
import { InStockIndicator } from "../../../../components/InStockIndicator";
import { IncludedInCommercialOffer } from "../customCells/IncludedInCommercialOffer";

const tableName = "ordering";

const defaultStatistics: ItemOrderStatistics = {
  new: 0,
  placed: 0,
  confirmed: 0,
  readyToDispatch: 0,
  shipped: 0,
  clearance: 0,
  warehouse: 0,
  delivered: 0,
  discontinued: 0,
};

export const OrderingTable = () => {
  const { t } = useTranslation("pages");
  const can = usePermissions();
  const { inquiryId, detailId, baseUrl } = useItemsTableBaseUrl();

  const [sortModel, setSortModel] = useLocalStorage<GridSortModel>(
    `table-${tableName}-${detailId}.sortModel`,
    [
      {
        field: "dueDate",
        sort: "asc",
      },
    ]
  );

  const [page, setPage] = useLocalStorage(
    `table-${tableName}-${detailId}.page`,
    0
  );

  const [pageSize, setPageSize] = useLocalStorage(
    `table-${tableName}-${detailId}.pageSize`,
    DEFAULT_PAGE_SIZE
  );

  const [search, setSearch] = useLocalStorage(
    `table-${tableName}-${detailId}.search`,
    ""
  );
  const debouncedSearch = useSearchDebounce(search);

  const [orderStatusesFilter, setOrderStatusesFilter] = useLocalStorage<
    string[]
  >(`filter.itemsByOrderStatuses-${detailId}`, []);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const selectionModelChangeHandler = useCallback(
    (selectionModel: GridSelectionModel) => {
      setSelectionModel(selectionModel);
    },
    []
  );

  const { isLoading, isFetching, data, error, refetch } = useGetItemsQuery({
    page,
    pageSize,
    sortModel,
    departmentInquiryId: detailId,
    search: debouncedSearch,
    inquiryStatusFilter: INQUIRY_STATUSES_FOR_ORDER_TABLE,
    orderStatusFilter: orderStatusesFilter,
  });

  const [, { isLoading: isChangeInquiryStatusLoading }] =
    useChangeItemInquiryStatusMutation();

  const na = t("na", { ns: "common" });

  const columns = useMemo<
    GridColDef<ItemServerCrudResponse["data"][number]>[]
  >(() => {
    const widthPercentForNames = can("editOrderStatus") ? 0.18 : 0.21;

    return [
      {
        field: "internalLineNumber",
        headerName: t("columns.id", { ns: "common" }),
        type: "number",
        minWidth: 75,
        flex: 0.05,
        cellClassName: "id-width-fix",
      },
      {
        field: "customerLineNumber",
        headerName: t("columns.idC", { ns: "common" }),
        type: "number",
        minWidth: 75,
        flex: 0.05,
        cellClassName: "id-width-fix",
      },
      {
        field: "item",
        headerName: t("items.fields.item"),
        flex: widthPercentForNames,
        valueGetter: ({ row: { supplier, supplierProductCode } }) =>
          `${supplier ?? na} ${supplierProductCode ?? na}`,
        renderCell: ({ row: { id, name, supplier, supplierProductCode } }) => {
          const nameFormatted = name ?? na;

          return (
            <Box>
              <Box>
                {supplier ?? na} {supplierProductCode ?? na}
              </Box>
              <Tooltip title={nameFormatted}>
                <MaterialLink component={Link} to={`${baseUrl}/${id}/details`}>
                  {nameFormatted}
                </MaterialLink>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        field: "match",
        headerName: t("items.fields.match"),
        flex: 0.1,
        renderCell: (params) => <>{params.row.match ?? na}</>,
      },
      {
        field: "product",
        headerName: t("items.fields.product"),
        flex: 0.2,
        renderCell: ({ row: { product } }) => {
          return (
            <Box>
              <Box>
                {product?.supplierProductCode?.supplier?.code ?? na}{" "}
                {product?.supplierProductCode?.code ?? na}
              </Box>
              <ProductLink
                product={product}
                inquiryId={inquiryId}
                detailId={detailId}
                tableName={tableName}
              />
            </Box>
          );
        },
      },
      {
        field: "orderStatus",
        headerName: t("items.fields.orderStatus"),
        flex: 0.2,
        valueGetter: ({ row }) => row?.orderStatus || "",
        hide: !can("editOrderStatus"),
        editeable: true,
        renderCell: (params) => {
          const confirmed = params.row.inquiryStatus === "confirmed";
          const delivered = params.row.inquiryStatus === "delivered";
          const discontinued = params.row.inquiryStatus === "discontinued";

          return (
            (confirmed || delivered || discontinued) && (
              <SelectItemOrderStatusCell
                gridCellProps={params}
                inquiryId={inquiryId}
              />
            )
          );
        },
      },
      {
        field: "inStock",
        headerName: t("inStock", { ns: "common" }),
        type: "actions",
        flex: 0.05,
        minWidth: 50,
        renderCell: ({ row: { inStock } }) =>
          inStock > 0 ? (
            <InStockIndicator quantity={inStock} />
          ) : (
            t("na", { ns: "common" })
          ),
      },
      {
        field: "includedInCommercialOffer",
        headerName: t("items.fields.includedInCommercialOffer"),
        type: "actions",
        flex: 0.05,
        minWidth: 75,
        renderCell: ({ row: { id, includedInCommercialOffer } }) => (
          <IncludedInCommercialOffer
            inquiryId={inquiryId}
            itemId={id}
            value={includedInCommercialOffer}
          />
        ),
      },
      {
        field: "actions",
        headerName: t("columns.actions", { ns: "common" }),
        type: "actions",
        flex: 0.05,
        minWidth: 100,
        renderCell: ({ id, row }) => (
          <Actions
            id={typeof id === "string" ? parseInt(id) : id}
            inquiryStatus={row.inquiryStatus}
            orderStatus={row.orderStatus}
            hasAssignedProduct={!!row.product}
          />
        ),
      },
    ];
  }, [baseUrl, can, detailId, inquiryId, na, t]);

  const actionButtons = useMemo(() => {
    if (selectionModel.length > 0) {
      const searchQuery = `?ids=${selectionModel.join(",")}`;

      return (
        <Stack direction="row">
          <Tooltip title={t("items.toolbarActions.assignMainOrder") as string}>
            <IconButton
              component={Link}
              to={{
                pathname: `${baseUrl}/assignMainOrder`,
                search: searchQuery,
              }}
              disabled={!can("assignItemMainOrder")}
            >
              <AssignmentOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    }
  }, [baseUrl, can, selectionModel, t]);

  const filters = useMemo(
    () => (
      <MultipleSelectFilter
        size="small"
        id="by-item-order-statuses"
        label={t("items.filters.orderStatus")}
        options={itemOrderStatuses.map((status) => ({
          label: t(`items.statuses.order.${status}`),
          value: status,
        }))}
        onChange={setOrderStatusesFilter}
        value={orderStatusesFilter}
      />
    ),
    [t, setOrderStatusesFilter, orderStatusesFilter]
  );

  const resetFilters = useCallback(() => {
    setOrderStatusesFilter([]);
    setSelectionModel([]);
  }, [setOrderStatusesFilter]);

  const title = data?.parentName;

  useHeaderTitle(title ?? t("items.title"));

  if (error) {
    return (
      <PaperPageError
        refetch={refetch}
        disabled={isFetching}
        message={t("items.errors.refetch")}
      />
    );
  }

  if (isLoading || !data) {
    return <PaperPageSpinner />;
  }

  return (
    <PaperPage
      breadcrumbs={{
        paths: [
          { href: "/inquiries", text: t("inquiries.title") },
          { href: `/inquiries/${inquiryId}/details`, text: data.inquiryName },
        ],
        current: title,
        endAdornment: (
          <GroupsSwitch {...{ inquiryId, detailId }} opened={tableName} />
        ),
      }}
    >
      {can("editOrderStatus") && (
        <OrderStatistics
          statistics={data.orderStatistics ?? defaultStatistics}
          setOrderStatusesFilter={(filters) => {
            setOrderStatusesFilter(filters);
            setSearch("");
          }}
        />
      )}
      <StyledDataGrid
        autoHeight
        buttons={actionButtons}
        columns={columns}
        rows={data.data}
        disableSelectionOnClick
        disableColumnMenu
        rowCount={data.rowCount}
        page={page}
        pageSize={pageSize}
        sortModel={sortModel}
        search={search}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortModelChange={setSortModel}
        setSearch={setSearch}
        loading={isFetching || isChangeInquiryStatusLoading}
        getRowClassName={(params) =>
          params.row.priority === "high" ? "item-priority-high" : ""
        }
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={selectionModelChangeHandler}
        filters={filters}
        resetCustomFilters={resetFilters}
      />
    </PaperPage>
  );
};

const StyledDataGrid = styled(CustomDataGridServerSide)({
  ".id-width-fix div": {
    "white-space": "break-spaces",
    "word-break": "break-all",
    "text-align": "end",
  },
});
