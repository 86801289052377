import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../utils/api";

export const unionItemsProductsInquiriesApi = createApi({
  reducerPath: "itemsProductsInquiriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/"),
    prepareHeaders,
  }),
  tagTypes: [
    "Item",
    "MatchedProductsList",
    "Product",
    "ProductPreviews",
    "Inquiry",
    "Assignment",
    "DetailedInquiry",
    "ItemReviewComment",
    "RequestedItemReviewComment",
    "PurchaseOrders",
    "MainOrders",
    "NextNewItemsList",
    "RequestedItem",
    "CartAssignments",
    "Inventory",
  ],
  endpoints: () => ({}),
});
