import React, { useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import { AddAPhoto } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export type FileFieldProps = {
  value: FileList | null | undefined;
  onChange: (value: FileList | null) => void;
  label: string;
};

export const FileField = ({ value, onChange, label }: FileFieldProps) => {
  const { t } = useTranslation("components");

  const changeHandler = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange(event.currentTarget.files);
    },
    [onChange]
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        startIcon={<AddAPhoto />}
        size="small"
        sx={{
          input: {
            display: "none",
          },
        }}
      >
        {label}
        <input type="file" onChange={changeHandler} />
      </Button>
      <Typography component="span" variant="caption">
        {value ? value[0].name : t("fileField.noFile")}
      </Typography>
    </Box>
  );
};
