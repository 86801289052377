import React from "react";
import { DepartmentInquirySchoolStatus } from "../../../../../types";
import { DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES } from "../../../../../consts";
import { SchoolStatusChip } from "../../../../../components/SchoolStatusChip";
import { useTranslation } from "react-i18next";

type StatusProps = {
  schoolStatus: DepartmentInquirySchoolStatus;
};

export const Status = ({ schoolStatus }: StatusProps) => {
  const { t } = useTranslation("common");

  return (
    <SchoolStatusChip
      chipStyle={DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES[schoolStatus]}
      label={t(`schoolDepartmentInquiryStatuses.${schoolStatus}`)}
    />
  );
};
