import React, { useMemo } from "react";
import {
  ColumnsExtension,
  createExtendableColumns,
} from "../../../../utils/createExtendableColumns";
import { ListColumn, RowBase } from "../../../../components/List/types";
import { useTranslation } from "react-i18next";
import { useDisplayPrice } from "../../../../utils/useDisplayPrice";
import { Box, Link as MaterialLink, Tooltip } from "@mui/material";
import { ImageSliderFixedHeight } from "../../../../components/ImageSliderFixedHeight";
import defaultImage from "../../../../assets/item-default-image.png";
import { Link } from "react-router-dom";
import { getShortText } from "../../../../utils/getShortText";
import { EnhancedSkeleton } from "../../../../components/EnhancedSkeleton";

export type Columns<MoreColumns> = {
  id: number;
  quantity: number;
  images: string[];
  productCode: string;
  productName: string | null;
  retailPrice: number | null;
  totalAmount: number;
  currency: string | null;
  itemId?: number;
} & MoreColumns;

export type ListColumns<
  MoreColumns,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Columns<MoreColumns> | undefined = undefined
> = ListColumn<Columns<MoreColumns>, SubRow, SubRowsKey>;

export type UseSchoolInquiryItemsListColumnsProps<
  MoreColumns,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Columns<MoreColumns> | undefined = undefined
> = {
  baseUrl: string;
  columns?: ColumnsExtension<ListColumns<MoreColumns, SubRow, SubRowsKey>>;
};

export const useSchoolInquiryItemsListColumns = <
  MoreColumns,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Columns<MoreColumns> | undefined = undefined
>({
  baseUrl,
  columns = [],
}: UseSchoolInquiryItemsListColumnsProps<MoreColumns, SubRow, SubRowsKey>) => {
  const { t } = useTranslation("pages");
  const displayPrice = useDisplayPrice();

  const na = t("na", { ns: "common" });
  const priceUponRequest = t("priceUponRequest", { ns: "common" });

  return useMemo(
    () =>
      createExtendableColumns<ListColumns<MoreColumns, SubRow, SubRowsKey>>([
        {
          field: "internalLineNumber",
          name: t("columns.id", { ns: "common" }),
          width: "40px",
          skipOnSubRow: true,
        },
        {
          field: "images",
          name: t("schoolInquiryDetails.departmentColumns.image"),
          width: "120px",
          skipOnSubRow: true,
          renderSkeleton: () => (
            <EnhancedSkeleton
              variant="rectangular"
              sx={{
                width: "100%",
                height: "100px",
              }}
            />
          ),
          renderCell: (row) => (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              {row.images.length > 0 ? (
                <ImageSliderFixedHeight
                  height={100}
                  images={row.images.map((url) => ({ src: url }))}
                />
              ) : (
                <ImageSliderFixedHeight
                  height={100}
                  images={[{ src: defaultImage }]}
                />
              )}
            </Box>
          ),
        },
        {
          field: "productCode",
          name: t("schoolInquiryDetails.departmentColumns.productCode"),
          skipOnSubRow: true,
          renderCell: (row) => row.productCode ?? na,
        },
        {
          field: "productName",
          name: t("schoolInquiryDetails.departmentColumns.productName"),
          skipOnSubRow: true,
          renderCell: ({ id, itemId, productCode, productName }) => {
            const value = productName ?? na;

            return (
              <Tooltip title={value}>
                {productCode ? (
                  <MaterialLink
                    component={Link}
                    to={`${baseUrl}/${itemId ?? id}`}
                  >
                    {getShortText(value, 30)}
                  </MaterialLink>
                ) : (
                  <span>{getShortText(value, 30)}</span>
                )}
              </Tooltip>
            );
          },
        },
        {
          field: "retailPrice",
          name: t("schoolInquiryDetails.departmentColumns.retailPrice"),
          mirrorMasterCell: true,
          renderCell: ({ productCode, retailPrice, currency }) => {
            const isPricePresent = retailPrice && currency;

            if (productCode && !isPricePresent) {
              return priceUponRequest;
            }

            const priceFormatted = displayPrice(retailPrice) ?? na;
            const currencyCode = currency ?? na;

            if (!productCode) {
              return priceFormatted;
            }

            return `${priceFormatted} ${currencyCode}`;
          },
        },
      ])(columns),
    [baseUrl, columns, displayPrice, na, priceUponRequest, t]
  );
};
