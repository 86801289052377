import React from "react";
import { useTranslation } from "react-i18next";
import { DialogContentText } from "@mui/material";

export const Message = ({
  detailsError,
  newItemsCount,
  submittedItemsCount,
}: {
  detailsError: string | false;
  newItemsCount: number;
  submittedItemsCount: number;
}) => {
  const { t } = useTranslation("pages");

  if (detailsError) {
    return <DialogContentText>{detailsError}</DialogContentText>;
  }

  return (
    <DialogContentText>
      {t("inquiryDetails.generateCommercialOffer.confirmationDescription", {
        count: submittedItemsCount,
      })}
      {newItemsCount !== 0 &&
        t("inquiryDetails.generateCommercialOffer.additionalDescription", {
          count: newItemsCount,
        })}
    </DialogContentText>
  );
};
