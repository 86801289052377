import React from "react";
import { ItemDetails } from "./types";
import { useApproveHandler } from "./useApproveHandler";
import { useSendToReviewHandler } from "./useSendToReviewHandler";
import { useCancelHandler } from "./useCancelHandler";
import { Box } from "@mui/material";
import { ApproveAction } from "../buttons/ApproveAction";
import { SendToReviewAction } from "../buttons/SendToReviewAction";
import { CancelAction } from "../buttons/CancelAction";

export const ItemStatusActions = (props: ItemDetails) => {
  const {
    handle: approveHandler,
    isLoading: isApproving,
    isPossible: isApprovalPossible,
  } = useApproveHandler(props);

  const {
    submitWithoutCommentHandler,
    isSubmittingWithoutComment,
    submitWithCommentHandler,
    isSubmittingWithComment,
    isPossible: isSendingToReviewPossible,
    isVisible: isSendToReviewVisible,
  } = useSendToReviewHandler(props);

  const {
    handle: cancelHandler,
    isLoading: isCancelling,
    isPossible: isCancellingPossible,
    isVisible: isCancelVisible,
  } = useCancelHandler(props);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <ApproveAction
        onClick={approveHandler}
        isLoading={isApproving}
        disabled={!isApprovalPossible}
      />
      {isSendToReviewVisible && (
        <SendToReviewAction
          onSubmitWithoutComment={submitWithoutCommentHandler}
          isSubmittingWithoutComment={isSubmittingWithoutComment}
          onSubmitWithComment={submitWithCommentHandler}
          isSubmittingWithComment={isSubmittingWithComment}
          disabled={!isSendingToReviewPossible}
        />
      )}
      {isCancelVisible && (
        <CancelAction
          onClick={cancelHandler}
          isLoading={isCancelling}
          disabled={!isCancellingPossible}
        />
      )}
    </Box>
  );
};
