import React, { useCallback } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Control, Controller, FormState, useFieldArray } from "react-hook-form";
import { FieldsArrayAccordion } from "../wideDrawer/FieldsArrayAccordion";
import { Remove } from "@mui/icons-material";

export const CustomFieldsField = <
  F extends {
    customFields: Array<{
      name: string;
    }>;
  }
>({
  control,
  formState,
}: {
  control: Control<F>;
  formState: FormState<F>;
}) => {
  const { t } = useTranslation("pages");

  const { fields, append, remove } = useFieldArray({
    control,
    // @ts-ignore
    name: "customFields" as const,
  });

  const addClickHandler = useCallback(() => {
    append({
      // @ts-ignore
      name: "",
    });
  }, [append]);

  const fieldsElements = fields.map((field, index) => {
    // @ts-ignore
    const error = formState.errors.customFields?.[index];
    const name = `customFields.${index}.name` as const;

    return (
      <Box
        key={name}
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Controller
          control={control}
          // @ts-ignore
          name={name}
          render={({ field: { value, onChange } }) => (
            <TextField
              label={`${t("inquiries.fields.customFields.label")} ${index}`}
              name={name}
              fullWidth
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error}
            />
          )}
        />
        <IconButton onClick={() => remove(index)} sx={{ marginLeft: "auto" }}>
          <Remove />
        </IconButton>
      </Box>
    );
  });

  return (
    <FieldsArrayAccordion
      id="product-form-images"
      label={t("inquiries.fields.customFields.plural")}
      addLabel={t("inquiries.fields.customFields.add")}
      addClickHandler={addClickHandler}
      // @ts-ignore
      error={!!formState.errors.customFields}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          marginBottom: 2,
        }}
      >
        {fieldsElements}
      </Box>
    </FieldsArrayAccordion>
  );
};
