import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/wideDrawer/Form";
import { FormProvider } from "react-hook-form";
import { ItemForm } from "./form/ItemForm";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import {
  useEditItemMutation,
  useGetItemQuery,
} from "../../../slices/items/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { useGetSuppliersQuery } from "../../../slices/suppliers/api";
import { useItemsTableBaseUrl } from "../hooks";
import { ItemForm as ItemFormProp } from "./form/types";
import { useGetInquiryCustomFieldsQuery } from "../../../slices/inquiries/api";
import { useItemForm } from "./form/useItemForm";

export const Edit = () => {
  const { t } = useTranslation("pages");

  const { baseUrl, inquiryId, detailId } = useItemsTableBaseUrl();
  const { id, closeDrawer } = useTableUrlTools(baseUrl);

  const {
    data: customFields,
    isLoading: areCustomFieldsLoading,
    error: customFieldsLoadingError,
  } = useGetInquiryCustomFieldsQuery({ inquiryId });

  const {
    isFetching: isItemLoading,
    data: item,
    error: itemLoadingError,
  } = useGetItemQuery(id ?? skipToken);

  const formMethods = useItemForm(customFields);
  const { handleSubmit, reset } = formMethods;

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    error: suppliersLoadingError,
  } = useGetSuppliersQuery({});

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("items.errors.edit")
  );

  const [editItem, { isLoading: isEditLoading }] = useEditItemMutation();

  const onSubmit = useCallback(
    (form: ItemFormProp) => {
      if (!id || !customFields) {
        return;
      }

      const collectedCustomFields: Record<string, string> = {};

      for (const customField of customFields) {
        collectedCustomFields[customField] = form[customField] ?? "";
      }

      editItem({
        id,
        departmentInquiryId: detailId,
        supplierId: form.supplierId,
        supplierProductCode: form.supplierProductCode,
        name: form.name,
        quantity: form.quantity,
        customerLineNumber: form.customerLineNumber,
        customFields: collectedCustomFields,
      })
        .unwrap()
        .then(() => {
          feedSuccess(t("items.successes.edit"), `${baseUrl}/${id}/details`);
          closeDrawer();
        })
        .catch(catchFormError);
    },
    [
      id,
      customFields,
      editItem,
      detailId,
      catchFormError,
      feedSuccess,
      t,
      baseUrl,
      closeDrawer,
    ]
  );

  const formattedItemData = useMemo(() => {
    if (!item || !suppliers || !id) {
      return null;
    }

    const { departmentInquiry, ...fields } = item;

    return {
      ...fields,
      supplierId: fields.supplier?.id ?? null,
      departmentInquiryId: departmentInquiry?.id,
    };
  }, [item, suppliers, id]);

  useEffect(() => {
    if (formattedItemData && customFields) {
      const customFieldsValues: Record<string, string> = {};

      for (const customField of customFields) {
        customFieldsValues[customField] =
          formattedItemData.customFields[customField] ?? "";
      }

      // @ts-ignore
      reset({
        supplierId: formattedItemData.supplierId,
        supplierProductCode: formattedItemData.supplierProductCode,
        name: formattedItemData.name,
        quantity: formattedItemData.quantity,
        customerLineNumber: formattedItemData.customerLineNumber,
        ...customFieldsValues,
      });
    }
  }, [customFields, formattedItemData, reset]);

  useEffect(() => {
    if (itemLoadingError || suppliersLoadingError || customFieldsLoadingError) {
      closeDrawer();
    }
  }, [
    closeDrawer,
    customFieldsLoadingError,
    itemLoadingError,
    suppliersLoadingError,
  ]);

  if (isItemLoading || areSuppliersLoading || areCustomFieldsLoading) {
    return <Spinner />;
  }

  if (!formattedItemData || !customFields) {
    return null;
  }

  return (
    <Form
      heading={t("items.edit")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isEditLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <ItemForm
          customFields={customFields}
          supplierId={formattedItemData.supplierId}
          fieldsErrors={fieldsErrors}
        />
      </FormProvider>
    </Form>
  );
};
