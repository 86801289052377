import React, { memo } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { existingFields, nonSortableFields } from "../existingFields";
import { useTranslation } from "react-i18next";
import { DragIndicator } from "@mui/icons-material";
import { IncludedField } from "../types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const Field = memo(
  ({
    id,
    enabled,
    onChange,
  }: IncludedField & {
    onChange: (id: string) => void;
  }) => {
    const { t } = useTranslation("pages");

    const isSortable = !nonSortableFields.some(
      (nonSortableField) => nonSortableField === id
    );

    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({
        id,
        disabled: !isSortable,
      });

    const isExisting = existingFields.some(
      (existingField) => existingField === id
    );

    return (
      <Box
        ref={setNodeRef}
        {...attributes}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          transform: CSS.Transform.toString(transform),
          transition,
        }}
      >
        {isSortable ? (
          <DragIndicator
            {...listeners}
            color="action"
            sx={{
              "&:hover": {
                cursor: "grab",
              },

              "&:active": {
                cursor: "grabbing",
              },
            }}
          />
        ) : (
          <DragIndicator color="disabled" />
        )}
        <FormControlLabel
          control={<Switch checked={enabled} onChange={() => onChange(id)} />}
          label={
            isExisting
              ? t(`inquiryDetails.generateCommercialOffer.existingFields.${id}`)
              : id
          }
        />
      </Box>
    );
  }
);
