import { InquiryDetailsFlattened } from "../../../../slices/inquiries/types";
import { useTranslation } from "react-i18next";

export const useGenerateCommercialOfferDetailsError = ({
  inquiryDetails,
}: {
  inquiryDetails: InquiryDetailsFlattened;
}) => {
  const { t } = useTranslation("pages");

  if (!inquiryDetails.astraEntity) {
    return t("inquiryDetails.generateCommercialOffer.noAstraEntitySet");
  }

  if (
    !(
      ((!!inquiryDetails?.shippingCost &&
        !!inquiryDetails?.shippingCostCurrency) ||
        !!inquiryDetails?.shippingCostMessage) &&
      !!inquiryDetails?.paymentTerms &&
      !!inquiryDetails?.deliveryTerms &&
      !!inquiryDetails?.leadTime &&
      !!inquiryDetails?.validityOffer
    )
  ) {
    return t("inquiryDetails.generateCommercialOffer.notFullCoDetails");
  }

  return false;
};
