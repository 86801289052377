import React from "react";
import { Box, Dialog, DialogProps, Typography } from "@mui/material";
import { colors } from "../theme/astra/colors";
import { IconButton } from "./astraComponents/IconButton";
import { Close } from "./astraIcons/Close";

type BodyProps =
  | {
      bodyType: "description";
      description: string;
    }
  | {
      bodyType: "custom";
      body: React.ReactNode;
    };

type AstraConfirmationDialogProps = Omit<DialogProps, "open" | "sx"> &
  BodyProps & {
    title: string;
    open: boolean;
    fullHeight?: boolean;
    buttons: React.ReactNode;
  };

export const AstraConfirmationDialog = ({
  title,
  open,
  fullHeight,
  onClose,
  buttons,
  ...props
}: AstraConfirmationDialogProps) => {
  return (
    <Dialog
      open={open}
      sx={{
        zIndex: "15000",

        ".MuiPaper-root": {
          paddingTop: "56px",
          paddingRight: "40px",
          paddingBottom: "48px",
          paddingLeft: "40px",
          height: fullHeight ? "100% !important" : "auto",
          minWidth: "607px",
        },
      }}
      onClose={onClose}
    >
      <IconButton
        variant="transparent"
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          color: colors.darkBlue,
        }}
        onClick={onClose}
      >
        <Close />
      </IconButton>
      <Typography
        component="span"
        sx={{
          fontSize: "20px",
          lineHeight: "28px",
          letterSpacing: "0.15px",
          fontWeight: "400",
          color: colors.darkBlue,
        }}
      >
        {title}
      </Typography>
      {props.bodyType === "description" ? (
        <Typography
          component="span"
          sx={{
            fontSize: "14px",
            lineHeight: "19px",
            fontWeight: "400",
            color: colors.dustBlue2,
          }}
        >
          {props.description}
        </Typography>
      ) : (
        props.body
      )}
      <Box
        sx={{
          marginTop: props.bodyType === "description" ? "80px" : "52px",
          display: "flex",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {buttons}
      </Box>
    </Dialog>
  );
};
