import React from "react";
import { Box } from "@mui/material";
import { colors } from "../../../../../theme/astra/colors";

export const Line = () => {
  return (
    <Box
      sx={{
        width: "1px",
        height: "31px",
        backgroundColor: colors.dustBlue5,
        marginTop: "5px",
        marginBottom: "8px",
      }}
    />
  );
};
