import { useHistory, useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { parseNumericParam } from "../../utils/parseNumericParam";

type SchoolInquiryItemsParams = {
  inquiryId: string;
  departmentInquiryId?: string;
  assignmentId?: string;
  itemId?: string;
};

export const useSchoolInquiryItemsTools = () => {
  const history = useHistory();

  const {
    inquiryId: rawInquiryId,
    departmentInquiryId: rawDepartmentInquiryId,
    assignmentId: rawAssignmentId,
    itemId: rawItemId,
  } = useParams<SchoolInquiryItemsParams>();

  const inquiryId = parseInt(rawInquiryId);
  const departmentInquiryId = parseNumericParam(rawDepartmentInquiryId);
  const assignmentId = parseNumericParam(rawAssignmentId);

  const itemId = parseNumericParam(rawItemId);

  const rootUrl = `/schoolinquiries/${inquiryId}/details`;

  const baseUrl = useMemo(() => {
    if (assignmentId) {
      return `${rootUrl}/${departmentInquiryId}/assignments/${assignmentId}`;
    }

    if (itemId) {
      return `${rootUrl}/${departmentInquiryId}/items/${itemId}`;
    }

    if (departmentInquiryId) {
      return `${rootUrl}/${departmentInquiryId}/items`;
    }

    return rootUrl;
  }, [assignmentId, itemId, departmentInquiryId, rootUrl]);

  const closeDrawer = useCallback(() => {
    history.goBack();
  }, [history]);

  return {
    rootUrl,
    baseUrl,
    closeDrawer,
    inquiryId,
    departmentInquiryId,
    assignmentId,
    itemId,
  };
};
