import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "../../../../components/wideDrawer/Form";
import { useTranslation } from "react-i18next";
import { useAddAstraEntityMutation } from "../../../../slices/astraEntities/api";
import { useYup } from "../../../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSubmitFormErrorHander } from "../../../../utils/formHelpers";
import { useTableUrlTools } from "../../../../utils/useTableUrlTools";
import { useFeedSuccess } from "../../../../utils/feedHooks";
import { AstraEntityForm } from "./AstraEntityForm";
import { astraEntitySchema } from "./schema";
import { AstraEntityFormProp } from "./types";
import { uploadAstraEntityLogo } from "../../../../slices/astraEntities/uploadAstraEntityLogo";

export const AddAstraEntity = () => {
  const { t } = useTranslation("pages");

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("astraEntities.errors.add")
  );

  const { control, handleSubmit, reset } = useForm<AstraEntityFormProp>({
    resolver: yupResolver(useYup(astraEntitySchema)),
    defaultValues: {
      logo: {
        fileList: undefined,
        url: undefined,
      },
    },
  });

  const { closeDrawer } = useTableUrlTools("/astra-entities");

  const [isLogoUploading, setIsLogoUploading] = useState(false);

  const [addAstraEntity, { isLoading: isAstraEntityAdding }] =
    useAddAstraEntityMutation();

  const onSubmit = useCallback(
    ({ logo, ...form }: AstraEntityFormProp) => {
      setIsLogoUploading(true);

      uploadAstraEntityLogo(logo.fileList)
        .then((logoS3Key) => {
          setIsLogoUploading(false);

          return addAstraEntity({
            logoS3Key,
            ...form,
          }).unwrap();
        })
        .then(({ id }) => {
          feedSuccess(
            t("astraEntities.successes.add"),
            `/astra-entities/${id}`
          );
          closeDrawer();
          reset();
        })
        .catch((error) => {
          setIsLogoUploading(false);
          catchFormError(error);
        });
    },
    [addAstraEntity, catchFormError, closeDrawer, feedSuccess, reset, t]
  );

  const isSubmitting = isLogoUploading || isAstraEntityAdding;

  return (
    <Form
      heading={t("astraEntities.addAstraEntity")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      formError={formError}
    >
      <AstraEntityForm control={control} fieldsErrors={fieldsErrors} />
    </Form>
  );
};
