import React, { forwardRef } from "react";
import { TextField, TextFieldProps } from "@mui/material";

type MessageTextFieldProps = Omit<
  TextFieldProps,
  "multiline" | "fullWidth" | "variant"
>;

export const MessageTextField = forwardRef(
  (props: MessageTextFieldProps, ref: MessageTextFieldProps["ref"]) => {
    return (
      <TextField ref={ref} multiline fullWidth variant="outlined" {...props} />
    );
  }
);
