import React, { useCallback } from "react";
import { useOptions } from "../useOptions";
import { Field } from "./Field";
import { Box } from "@mui/material";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { nonSortableFields } from "../existingFields";

export const Fields = ({
  includedFields,
  includedFieldsChangeHandler,
}: Pick<
  ReturnType<typeof useOptions>,
  "includedFields" | "includedFieldsChangeHandler"
>) => {
  const sensors = useSensors(useSensor(PointerSensor));

  const dragEndHandler = useCallback(
    (event: DragEndEvent) => {
      const previousIndex = includedFields.findIndex(
        (includedField) => includedField.id === event.active.id
      );

      const nextIndex = includedFields.findIndex(
        (includedField) => includedField.id === event.over?.id
      );

      if (nextIndex < includedFields.length - nonSortableFields.length) {
        includedFieldsChangeHandler(
          arrayMove(includedFields, previousIndex, nextIndex)
        );
      }
    },
    [includedFields, includedFieldsChangeHandler]
  );

  const includedFieldsSwitchChangeHandler = useCallback(
    (id: string) => {
      includedFieldsChangeHandler(
        includedFields.map((includedField) =>
          includedField.id === id
            ? { ...includedField, enabled: !includedField.enabled }
            : includedField
        )
      );
    },
    [includedFields, includedFieldsChangeHandler]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={dragEndHandler}
    >
      <SortableContext
        items={includedFields}
        strategy={verticalListSortingStrategy}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {includedFields.map((includedField) => (
            <Field
              key={includedField.id}
              {...includedField}
              onChange={includedFieldsSwitchChangeHandler}
            />
          ))}
        </Box>
      </SortableContext>
    </DndContext>
  );
};
