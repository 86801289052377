import React, { useEffect, useMemo } from "react";
import { useGetCountriesQuery } from "../../../slices/countries/api";
import { EntityDetailsTable } from "../../../components/EntityDetailsTable";
import { useTranslation } from "react-i18next";
import { DisplayContainer } from "../../../components/wideDrawer/DisplayContainer";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { usePermissions } from "../../../utils/usePermissions";
import { BooleanCheckboxDisplay } from "../../../components/dataDisplays/BooleanCheckboxDisplay";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";

export const Details: React.FC = () => {
  const { t } = useTranslation();
  const { id, closeDrawer } = useTableUrlTools("/countries");
  const { data, error } = useGetCountriesQuery();
  const can = usePermissions();
  const displayDate = useDisplayDateTime();

  useEffect(() => {
    if (error) {
      closeDrawer();
    }
  }, [error, closeDrawer]);

  const country = useMemo(
    () => data && data.find((entry) => entry.id === id),
    [data, id]
  );

  if (!country) {
    return null;
  }

  return (
    <DisplayContainer
      heading={country.name}
      editHref={`/countries/${id}/edit`}
      editLabel={t("countries.editCountry")}
      editingDisabled={!can("editCountry")}
    >
      <EntityDetailsTable
        entity={country}
        excludeFields={["name"]}
        customDisplays={{
          defaultAstraEntity: (astraEntity) => astraEntity?.name ?? null,
          active: (value) => <BooleanCheckboxDisplay value={!!value} />,
          createdAt: (value) => (value ? displayDate(value) : null),
          updatedAt: (value) => (value ? displayDate(value) : null),
        }}
      />
    </DisplayContainer>
  );
};
