import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

type ConfirmationDialogProps = Omit<DialogProps, "open" | "sx"> & {
  title: string;
  description: React.ReactNode;
  open: boolean;
  handleCancel: () => void;
  handleProceed?: () => void;
  proceedButtonText?: string;
  loading?: boolean;
  fullHeight?: boolean;
};

export const ConfirmationDialog = ({
  title,
  description,
  open,
  handleCancel,
  handleProceed,
  proceedButtonText,
  loading,
  fullHeight,
  ...props
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      sx={{
        zIndex: "15000",

        ".MuiDialog-paper": {
          height: fullHeight ? "100% !important" : "auto",
        },
      }}
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof description === "string" ? (
          <DialogContentText>{description}</DialogContentText>
        ) : (
          description
        )}
      </DialogContent>
      <DialogActions>
        {handleProceed ? (
          <>
            <Button variant="outlined" onClick={handleCancel}>
              {t("cancel")}
            </Button>
            <LoadingButton
              onClick={handleProceed}
              loading={loading}
              variant="outlined"
            >
              {proceedButtonText || t("proceed")}
            </LoadingButton>
          </>
        ) : (
          <LoadingButton variant="outlined" onClick={handleCancel}>
            {proceedButtonText || t("ok")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
