import React from "react";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { useGetSchoolDepartmentInquiryForHeadQuery } from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/query";
import { Title } from "../Title/Title";
import { TitleSkeleton } from "../Title/TitleSkeleton";
import { ListsSkeleton } from "./Lists/ListsSkeleton";
import { Lists } from "./Lists/Lists";
import { colors } from "../../../../../theme/astra/colors";
import { Typography } from "@mui/material";
import { EnhancedSkeleton } from "../../../../../components/EnhancedSkeleton";

export const HeaderForHead = () => {
  const { departmentInquiryId } = useSchoolInquiryItemsTools();

  const { data: departmentInquiry, isLoading: isDepartmentInquiryLoading } =
    useGetSchoolDepartmentInquiryForHeadQuery(
      departmentInquiryId ? { departmentInquiryId } : skipToken
    );

  const isLoading = isDepartmentInquiryLoading || !departmentInquiry;

  return (
    <>
      {isLoading ? (
        <TitleSkeleton />
      ) : (
        <Title
          inquiryName={departmentInquiry.departmentName}
          schoolName={departmentInquiry.schoolName}
        />
      )}
      {isLoading ? (
        <EnhancedSkeleton
          variant="text"
          width={[80, 300]}
          height={23}
          sx={{ lineHeight: "23px" }}
        />
      ) : (
        <Typography
          component="span"
          sx={{
            color: colors.darkBlue,
            fontSize: "22px",
            lineHeight: "23px",
            fontWeight: "500",
          }}
        >
          {departmentInquiry.departmentName}
        </Typography>
      )}
      {isLoading ? (
        <ListsSkeleton />
      ) : (
        <Lists departmentInquiry={departmentInquiry} />
      )}
    </>
  );
};
