import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useDispatch as useDefaultDispatch,
  useSelector as useDefaultSelector,
} from "react-redux";
import { authSlice } from "./slices/auth/slice";
import { uiSlice } from "./slices/ui/slice";
import { usersApi } from "./slices/users/api";
import { countriesApi } from "./slices/countries/api";
import { suppliersApi } from "./slices/suppliers/api";
import { departmentsApi } from "./slices/departments/api";
import { commoditycodesApi } from "./slices/commoditycodes/api";
import { categoriesApi } from "./slices/categories/api";
import { schoolsApi } from "./slices/schools/api";
import { unionItemsProductsInquiriesApi } from "./slices/unionItemsProductsInquiriesApi";
import { currenciesApi } from "./slices/currencies/api";
import { commentsApi } from "./slices/comments/api";
import { tagsApi } from "./slices/tags/api";
import { commercialOffersApi } from "./slices/commercialOffers/api";
import { purchaseOrdersApi } from "./slices/purchaseOrders/api";
import { deliveryNotesApi } from "./slices/deliveryNotes/api";
import { mainOrdersApi } from "./slices/mainOrders/api";
import { searchesApi } from "./slices/searches/api";
import { astraEntitiesApi } from "./slices/astraEntities/api";
import { shopSlice } from "./slices/shop/slice";

const reducer = combineReducers({
  auth: authSlice.reducer,
  ui: uiSlice.reducer,
  shop: shopSlice.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [suppliersApi.reducerPath]: suppliersApi.reducer,
  [departmentsApi.reducerPath]: departmentsApi.reducer,
  [commoditycodesApi.reducerPath]: commoditycodesApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [schoolsApi.reducerPath]: schoolsApi.reducer,
  [unionItemsProductsInquiriesApi.reducerPath]:
    unionItemsProductsInquiriesApi.reducer,
  [currenciesApi.reducerPath]: currenciesApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [commercialOffersApi.reducerPath]: commercialOffersApi.reducer,
  [purchaseOrdersApi.reducerPath]: purchaseOrdersApi.reducer,
  [deliveryNotesApi.reducerPath]: deliveryNotesApi.reducer,
  [mainOrdersApi.reducerPath]: mainOrdersApi.reducer,
  [searchesApi.reducerPath]: searchesApi.reducer,
  [astraEntitiesApi.reducerPath]: astraEntitiesApi.reducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(usersApi.middleware)
      .concat(countriesApi.middleware)
      .concat(suppliersApi.middleware)
      .concat(departmentsApi.middleware)
      .concat(commoditycodesApi.middleware)
      .concat(categoriesApi.middleware)
      .concat(unionItemsProductsInquiriesApi.middleware)
      .concat(schoolsApi.middleware)
      .concat(currenciesApi.middleware)
      .concat(commentsApi.middleware)
      .concat(tagsApi.middleware)
      .concat(commercialOffersApi.middleware)
      .concat(deliveryNotesApi.middleware)
      .concat(mainOrdersApi.middleware)
      .concat(searchesApi.middleware)
      .concat(astraEntitiesApi.middleware),
});

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
export type ThunkApi = {
  dispatch: Dispatch;
  state: State;
};

export const useDispatch = () => useDefaultDispatch<Dispatch>();
export const useSelector: TypedUseSelectorHook<State> = useDefaultSelector;
