import React from "react";
import { Tooltip } from "@mui/material";
import { Approve } from "../../../../../../components/astraIcons/Approve";
import { LoadingIconButton } from "../../../../../../components/LoadingIconButton";
import { useTranslation } from "react-i18next";

export const ApproveAction = ({
  onClick,
  isLoading,
  disabled,
}: {
  onClick: () => void;
  isLoading?: boolean;
  disabled: boolean;
}) => {
  const { t } = useTranslation("pages");

  return (
    <LoadingIconButton
      variant="transparent"
      onClick={onClick}
      loading={isLoading}
      disabled={disabled}
    >
      <Tooltip
        arrow
        title={t("schoolInquiryDetails.itemActions.approve.label")}
      >
        <Approve />
      </Tooltip>
    </LoadingIconButton>
  );
};
