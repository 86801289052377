import { useSelector } from "../../../../../store";
import { RequestedItemSchoolStatus } from "../../../../../types";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { useGetSchoolAssignmentQuery } from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/query";

export const useCanBulkSubmit = ({
  assignmentId,
}: {
  assignmentId?: number;
}): RequestedItemSchoolStatus | false => {
  const self = useSelector(selectSelf);

  const { data: assignment } = useGetSchoolAssignmentQuery(
    assignmentId ? { assignmentId } : skipToken
  );

  if (!self || !assignment) {
    return false;
  }

  if (!assignment.possibleActions.includes("bulkApproveRequestedItems")) {
    return false;
  }

  if (self.role === "teacher") {
    return "approvedByTeacher";
  }

  if (self.role === "headOfDepartment") {
    return "approvedByHead";
  }

  return false;
};
