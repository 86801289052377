import { getDefaultHeaders, getUrl } from "./api";

export const saveBlob = (blob: Blob | MediaSource, fileName: string) => {
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  a.dispatchEvent(new MouseEvent("click"));
};

export const downloadFileFromServer = async (id: number) => {
  const response = await fetch(getUrl(`/export/inquiry/${id}`), {
    headers: getDefaultHeaders(true),
  });
  const data = await response.json();
  window.location.assign(data.url);
};

export const downloadPrincipalInquiryFileFromServer = async (id: number) => {
  const response = await fetch(getUrl(`/export/principal/inquiry/${id}`), {
    headers: getDefaultHeaders(true),
  });
  const data = await response.json();
  window.location.assign(data.url);
};

export const downloadProductsFromServer = async (
  search: string,
  supplierIds: string[],
  activities: string[]
) => {
  const ids =
    supplierIds && supplierIds.length > 0 ? supplierIds.join(",") : "";
  const availabilities =
    activities && activities.length > 0 ? activities.join(",") : "";

  const response = await fetch(
    getUrl(
      `/export/products?search=${
        search || ""
      }&supplierIds=${ids}&activities=${availabilities}`
    ),
    {
      headers: getDefaultHeaders(true),
    }
  );
  const data = await response.json();
  window.location.assign(data.url);
};
