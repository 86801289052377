import React, { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FieldsErrorState } from "../../../../utils/formHelpers";
import { SelectSupplier } from "../../../../components/prefilledSelectors/SelectSupplier";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { ControlledNumericField } from "../../../../components/controlledFields/ControlledNumericField";
import { FreeAutocompleteSupplierCode } from "../../../../components/prefilledSelectors/FreeAutocompleteSupplierCode";
import { FreeAutocompleteItemName } from "../../../../components/prefilledSelectors/FreeAutocompleteItemName";
import { ControlledTextField } from "../../../../components/controlledFields/ControlledTextField";
import { ItemForm as ItemFormProp } from "./types";

type ItemFormProps = {
  supplierId?: number | null | undefined;
  fieldsErrors?: FieldsErrorState;
  customFields: string[];
};

/**
 * Needs to be inside <FormProvider />
 **/
export const ItemForm: React.FC<ItemFormProps> = ({
  supplierId,
  fieldsErrors,
  customFields,
}) => {
  const { t } = useTranslation("pages");

  const { control, setValue } = useFormContext<ItemFormProp>();

  const watchSupplierIdSelect = useWatch({
    control,
    defaultValue: supplierId,
    name: "supplierId",
  });

  const setName = useCallback(
    (name: string) => {
      setValue("name", name);
    },
    [setValue]
  );

  const setSupplier = useCallback(
    (id: number) => {
      setValue("supplierId", id);
    },
    [setValue]
  );

  const setSupplierProductCode = useCallback(
    (supplierProductCode: string) => {
      setValue("supplierProductCode", supplierProductCode);
    },
    [setValue]
  );

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <Grid item gridColumn="span 5">
        <ControlledCustomField
          control={control}
          name="supplierId"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field }) => (
            <SelectSupplier
              label={t("items.fields.supplier")}
              error={!!errorMessage}
              helperText={errorMessage}
              fullWidth
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 7">
        <ControlledCustomField
          control={control}
          name="supplierProductCode"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field: { value, onChange } }) => {
            return (
              <FreeAutocompleteSupplierCode
                id="item"
                onChange={onChange}
                setName={setName}
                value={(value as string) ?? ""}
                supplierId={watchSupplierIdSelect}
                error={!!errorMessage}
                helperText={errorMessage}
                label={t("items.fields.supplierProductCode")}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledCustomField
          control={control}
          name="name"
          fieldsErrors={fieldsErrors}
          render={(errorMessage, { field: { value, onChange } }) => {
            return (
              <FreeAutocompleteItemName
                id="item"
                onChange={onChange}
                setSupplier={setSupplier}
                setSupplierProductCode={setSupplierProductCode}
                value={(value as string) ?? ""}
                error={!!errorMessage}
                helperText={errorMessage}
                label={`${t("items.fields.name")} *`}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 5">
        <ControlledNumericField
          control={control}
          name="quantity"
          label={`${t("items.fields.quantity")} *`}
          fieldsErrors={fieldsErrors}
          positiveOnly
          fullWidth
        />
      </Grid>
      <Grid item gridColumn="span 7">
        <ControlledTextField
          control={control}
          name="customerLineNumber"
          label={t("items.fields.customerLineNumber")}
          fieldsErrors={fieldsErrors}
        />
      </Grid>
      {customFields.map((customField) => (
        <Grid key={customField} item gridColumn="span 12">
          <ControlledTextField
            control={control}
            name={customField}
            label={customField}
            fieldsErrors={fieldsErrors}
            defaultValue=""
          />
        </Grid>
      ))}
    </Grid>
  );
};
