import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ControlledCustomField } from "../../../../../components/controlledFields/ControlledCustomField";
import { useFormContext } from "react-hook-form";
import { AddInquiryForm } from "../../../../Inquiries/Drawers/types";
import { discountType } from "../../../../../types";
import { capitalize } from "../../../../../utils/capitalize";
import { BasicCODetailsFormProps } from "../BasicCODetails";

export const SelectDiscountType = ({
  fieldsErrors,
  inquiryData,
}: BasicCODetailsFormProps) => {
  const { t } = useTranslation("pages");
  const { control } = useFormContext<AddInquiryForm>();

  return (
    <Grid item gridColumn="span 12">
      <ControlledCustomField
        control={control}
        name="discountType"
        defaultValue={inquiryData?.discountType}
        fieldsErrors={fieldsErrors}
        render={(errorMessage, { field }) => (
          <TextField
            fullWidth
            label={t("inquiries.fields.discountType")}
            error={!!errorMessage}
            helperText={errorMessage}
            select
            {...field}
          >
            <MenuItem value="">{capitalize("N/A")}</MenuItem>
            {discountType.map((value) => (
              <MenuItem key={value} value={value}>
                {capitalize(value)}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Grid>
  );
};
