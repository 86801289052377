import React, { useCallback } from "react";
import { useOptions } from "../useOptions";
import { Box, Divider, FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Fields } from "./Fields";

export const Options = ({
  orientation,
  orientationChangeHandler,
  includedFields,
  includedFieldsChangeHandler,
}: ReturnType<typeof useOptions>) => {
  const { t } = useTranslation("pages");

  const orientationSwitchChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      orientationChangeHandler(event.target.checked ? "landscape" : "portrait");
    },
    [orientationChangeHandler]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginTop: 2,
      }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={orientation === "landscape"}
            onChange={orientationSwitchChangeHandler}
          />
        }
        label={`${t("portrait", { ns: "common" })} / ${t("landscape", {
          ns: "common",
        })}`}
      />
      <Divider />
      <Fields
        {...{
          includedFields,
          includedFieldsChangeHandler,
        }}
      />
    </Box>
  );
};
