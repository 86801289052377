import React, { useCallback } from "react";
import {
  FileField,
  FileFieldProps,
} from "../../../../components/fields/FileField";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

export type LogoFieldValue = {
  fileList: FileList | null | undefined;
  url: string | null | undefined;
};

export type LogoFieldProps = {
  value: LogoFieldValue;
  onChange: (value: LogoFieldValue) => void;
};

export const LogoField = ({ value, onChange }: LogoFieldProps) => {
  const { t } = useTranslation("pages");

  const filePickerChangeHandler = useCallback<FileFieldProps["onChange"]>(
    (value) => {
      onChange({
        fileList: value,
        url: null,
      });
    },
    [onChange]
  );

  const removeLogoHandler = useCallback(() => {
    onChange({
      fileList: null,
      url: null,
    });
  }, [onChange]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <Typography
        component="span"
        variant="body2"
        sx={{
          marginTop: "5px",
          marginRight: "16px",
        }}
      >
        {t("astraEntities.fields.logo.label")}:
      </Typography>
      {!value.url ? (
        <FileField
          value={value.fileList}
          onChange={filePickerChangeHandler}
          label={t("astraEntities.fields.logo.pick")}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 1,

            img: {
              width: "auto",
              height: "100px",
            },
          }}
        >
          <img src={value.url} />
          <IconButton onClick={removeLogoHandler}>
            <Close />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
