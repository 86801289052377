import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetUserQuery } from "../../../slices/users/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { EntityDetailsGrid } from "../../../components/EntityDetailsGrid";
import { BooleanCheckboxDisplay } from "../../../components/dataDisplays/BooleanCheckboxDisplay";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { DisplayContainer } from "../../../components/wideDrawer/DisplayContainer";

type UserDetailsProps = {
  id?: number;
  onClose: () => void;
};

export const UserDetails = ({ id, onClose }: UserDetailsProps) => {
  const { t } = useTranslation("pages");

  const {
    data: user,
    error: userLoadingError,
    isLoading: isUserLoading,
  } = useGetUserQuery(id ?? skipToken);

  useEffect(() => {
    if (userLoadingError) {
      onClose();
    }
  }, [userLoadingError, onClose]);

  if (isUserLoading) {
    return <Spinner />;
  }

  if (!id || !user) {
    return null;
  }

  return (
    <DisplayContainer heading={`${user.firstName} ${user.lastName}`}>
      <EntityDetailsGrid
        blocks={[
          [
            {
              label: t("users.fields.email"),
              value: user.email,
              xs: 12,
            },
          ],
          "divider",
          [
            {
              label: t("users.fields.role"),
              value: t(`roles.${user.role}`, { ns: "common" }),
              xs: 12,
            },
          ],
          "divider",
          [
            {
              label: t("users.fields.active"),
              view: <BooleanCheckboxDisplay value={user.active} />,
              xs: 12,
            },
          ],
        ]}
      />
    </DisplayContainer>
  );
};
