import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../../theme/astra/colors";
import { useTranslation } from "react-i18next";

type HeadingProps = {
  departmentName: string;
};

export const Heading = ({ departmentName }: HeadingProps) => {
  const { t } = useTranslation("pages");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        component="span"
        sx={{
          color: colors.darkBlue,
          fontSize: "22px",
          lineHeight: "23px",
          fontWeight: "500",
        }}
      >
        {departmentName}
      </Typography>
      <Typography
        component="span"
        sx={{
          color: colors.dustBlue1,
          fontSize: "13px !important",
          lineHeight: "23px",
          fontWeight: "400",
          marginTop: "4px",
        }}
      >
        {t("schoolInquiryDetails.listOfAllItems")}
      </Typography>
    </Box>
  );
};
