import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { usePermissions } from "../../../utils/usePermissions";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";
import { useGetAstraEntityQuery } from "../../../slices/astraEntities/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { DisplayContainer } from "../../../components/wideDrawer/DisplayContainer";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { EntityDetailsTable } from "../../../components/EntityDetailsTable";
import { Box } from "@mui/material";

export const AstraEntityDetails = () => {
  const { t } = useTranslation("pages");

  const can = usePermissions();
  const displayDate = useDisplayDateTime();

  const { id, closeDrawer } = useTableUrlTools("/astra-entities");

  const {
    data: astraEntity,
    isLoading: isAstraEntityLoading,
    error: astraEntityError,
  } = useGetAstraEntityQuery(id ? { id } : skipToken);

  useEffect(() => {
    if (astraEntityError) {
      closeDrawer();
    }
  }, [astraEntityError, closeDrawer]);

  if (isAstraEntityLoading) {
    return <Spinner />;
  }

  if (!astraEntity) {
    return null;
  }

  return (
    <DisplayContainer
      heading={astraEntity.name}
      editHref={`/astra-entities/${id}/edit`}
      editLabel={t("astraEntities.editAstraEntity")}
      editingDisabled={!can("editAstraEntities")}
    >
      {!!astraEntity.logoUrl && (
        <Box
          sx={{
            height: "100px",
            display: "flex",
            marginBottom: "16px",
          }}
        >
          <img
            src={astraEntity.logoUrl}
            alt={t("astraEntities.logo", { name: astraEntity.name })}
          />
        </Box>
      )}
      <EntityDetailsTable
        entity={astraEntity}
        excludeFields={["name", "logoUrl"]}
        customDisplays={{
          country: (value) => value.name,
          createdAt: (value) => (value ? displayDate(value) : null),
          updatedAt: (value) => (value ? displayDate(value) : null),
        }}
      />
    </DisplayContainer>
  );
};
