import React from "react";
import { Box } from "@mui/material";
import { EnhancedSkeleton } from "../../../../../../components/EnhancedSkeleton";

export const ListsSkeleton = () => {
  const skeleton = (
    <EnhancedSkeleton
      variant="rectangular"
      sx={{
        width: "100%",
        height: "50px",
      }}
    />
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: "10px",
        marginTop: "10px",
      }}
    >
      {skeleton}
      {skeleton}
      {skeleton}
    </Box>
  );
};
