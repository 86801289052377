import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InquiryFlattened } from "../../../../slices/inquiries/types";
import { FieldsErrorState } from "../../../../utils/formHelpers";
import * as yup from "yup";
import { EditInquiryCODetailsForm } from "./types";
import { createYupSchema } from "../../../../utils/validation";
import { ControlledNumericCurrencyField } from "../../../../components/controlledFields/ControlledNumericCurrencyField";
import { ControlledCustomField } from "../../../../components/controlledFields/ControlledCustomField";
import { SelectCurrency } from "../../../../components/prefilledSelectors/SelectCurrency";
import { ControlledTextField } from "../../../../components/controlledFields/ControlledTextField";
import { SelectDeliveryTerms } from "../../../Inquiries/Drawers/customFields/SelectDeliveryTerms";
import { deliveryTerms, DiscountType, discountType } from "../../../../types";
import { SelectDiscountType } from "./customFields/SelectDiscountType";

export const basicCODetailsSchemaCreator = createYupSchema((t) =>
  yup.object({
    id: yup.number().positive().optional(),
    shippingCost: yup
      .number()
      .typeError(t("number"))
      .positive(t("positivePrice"))
      .optional()
      .nullable(true),
    shippingCostCurrencyId: yup.number().min(0, t("required")).optional(),
    shippingCostMessage: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    paymentTerms: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    deliveryTerms: yup
      .string()
      .oneOf([...deliveryTerms, undefined, null])
      .nullable()
      .optional(),
    leadTime: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    validityOffer: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    discountType: yup
      .string()
      .oneOf([...discountType, undefined, null])
      .nullable()
      .optional(),
    discountValue: yup
      .number()
      .typeError(t("number"))
      .positive(t("positivePrice"))
      .when("discountType", {
        is: (discountType: DiscountType) => !discountType,
        then: yup
          .number()
          .nullable()
          .notRequired()
          .equals([null, undefined], t("discountValueWithouthDiscountType")),
        otherwise: yup
          .number()
          .nullable()
          .optional()
          .when("discountType", {
            is: (discountType: DiscountType) => discountType === "rate",
            then: yup.number().max(100, t("tooBig", { maxNum: 100 })),
          }),
      }),

    discountCurrency: yup.number().min(0, t("required")).optional(),
  })
);

export type BasicCODetailsFormProps = {
  inquiryData?: Omit<InquiryFlattened, "school"> & {
    schoolId: number;
  };
  fieldsErrors?: FieldsErrorState;
};

/**
 * Needs to be inside <FormProvider/>
 **/
export const BasicCODetailsForm: React.FC<BasicCODetailsFormProps> = ({
  inquiryData,
  fieldsErrors,
}) => {
  const { t } = useTranslation("pages");
  const { control, setValue } = useFormContext<EditInquiryCODetailsForm>();

  const discountType = useWatch({
    control,
    name: "discountType",
  });

  useEffect(() => {
    if (!discountType) {
      setValue("discountType", null);
      setValue("discountValue", null);
    }
  }, [discountType, setValue]);

  useEffect(() => {
    if (inquiryData) {
      setValue("discountType", inquiryData?.discountType);
      setValue("discountValue", inquiryData?.discountValue);
    }
  }, [inquiryData, setValue]);

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <Grid item gridColumn="span 6">
        <ControlledNumericCurrencyField
          control={control}
          name="shippingCost"
          label={`${t("inquiries.fields.shippingCost")}`}
          fieldsErrors={fieldsErrors}
          defaultValue={inquiryData?.shippingCost}
          positiveOnly
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledCustomField
          control={control}
          name="shippingCostCurrencyId"
          defaultValue={inquiryData?.shippingCostCurrency?.id}
          render={(errorMessage, { field }) => (
            <SelectCurrency
              id="inquiry-shipping-cost-currency"
              label={`${t("inquiries.fields.shippingCostCurrency")}`}
              fullWidth
              error={!!errorMessage}
              helperText={errorMessage}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledTextField
          control={control}
          name="shippingCostMessage"
          label={t("inquiries.fields.shippingCostMessage")}
          defaultValue={inquiryData?.shippingCostMessage}
          fieldsErrors={fieldsErrors}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledTextField
          control={control}
          name="paymentTerms"
          label={t("inquiries.fields.paymentTerms")}
          defaultValue={inquiryData?.paymentTerms}
          fieldsErrors={fieldsErrors}
        />
      </Grid>
      <SelectDeliveryTerms
        fieldsErrors={fieldsErrors}
        inquiryData={inquiryData}
      />
      <Grid item gridColumn="span 12">
        <ControlledTextField
          control={control}
          name="leadTime"
          label={t("inquiries.fields.leadTime")}
          defaultValue={inquiryData?.leadTime}
          fieldsErrors={fieldsErrors}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledTextField
          control={control}
          name="validityOffer"
          label={t("inquiries.fields.validityOffer")}
          defaultValue={inquiryData?.validityOffer}
          fieldsErrors={fieldsErrors}
        />
      </Grid>
      <SelectDiscountType
        fieldsErrors={fieldsErrors}
        inquiryData={inquiryData}
      />
      <Grid item gridColumn="span 6">
        <ControlledNumericCurrencyField
          control={control}
          name="discountValue"
          label={`${t("inquiries.fields.discountValue")}`}
          fieldsErrors={fieldsErrors}
          defaultValue={inquiryData?.discountValue}
          positiveOnly
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledCustomField
          control={control}
          name="discountCurrencyId"
          defaultValue={inquiryData?.discountCurrency?.id}
          render={(errorMessage, { field }) => (
            <SelectCurrency
              id="inquiry-discount-currency"
              label={`${t("inquiries.fields.discountCurrency")}`}
              fullWidth
              error={!!errorMessage}
              helperText={errorMessage}
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
