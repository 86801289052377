import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { ReviewCommentsResponseDtoBase } from "../../../../../../slices/schoolInquiries/types";
import { Comment } from "./Comment";
import { Dotted } from "../../../../../../components/spinners/Dotted";

type CommentsListProps = {
  reviewComments: ReviewCommentsResponseDtoBase;
  areReviewCommentsLoading?: boolean;
};

export const CommentsList = ({
  reviewComments,
  areReviewCommentsLoading,
}: CommentsListProps) => {
  const boxRef = useRef<HTMLDivElement | null>(null);

  /*
   * I ChatGPTd it to be completely honest, I don't like how it looks and not
   * sure if this is the best way to do this, but I've been in a hurry
   */
  useEffect(() => {
    /*
     * Chat didn't offer this condition, it was added by me to scroll down on
     * each subsequent dialog opening
     */
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }

    const observer = new MutationObserver(() => {
      if (boxRef.current) {
        boxRef.current.scrollTop = boxRef.current.scrollHeight;
      }
    });

    if (boxRef.current) {
      observer.observe(boxRef.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [reviewComments]);

  return (
    <Box
      ref={boxRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginTop: "42px",
        maxWidth: "550px",
        overflowY: "auto",

        ...(areReviewCommentsLoading
          ? { alignItems: "center", justifyContent: "center" }
          : {}),
      }}
    >
      {areReviewCommentsLoading && <Dotted />}
      {!areReviewCommentsLoading &&
        reviewComments.map((reviewComment) => (
          <Comment key={reviewComment.id} {...reviewComment} />
        ))}
    </Box>
  );
};
