import React from "react";
import { useGetSchoolDepartmentInquiryForPrincipalQuery } from "../../../../../slices/schoolInquiries/api";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { skipToken } from "@reduxjs/toolkit/query";
import { Title } from "../Title/Title";
import { List } from "../List/List";
import { Status } from "./Status";
import { ListSkeleton } from "../List/ListSkeleton";
import { TitleSkeleton } from "../Title/TitleSkeleton";
import { Approve } from "../actions/departmentInquiry/Approve";
import { NeedReview } from "../actions/departmentInquiry/NeedReview";
import { ContextMenu } from "../actions/departmentInquiry/ContextMenu";

export const HeaderForPrincipal = () => {
  const { inquiryId, departmentInquiryId } = useSchoolInquiryItemsTools();

  const { data: departmentInquiry, isLoading: isDepartmentInquiryLoading } =
    useGetSchoolDepartmentInquiryForPrincipalQuery(
      departmentInquiryId ? { departmentInquiryId } : skipToken
    );

  const isLoading = isDepartmentInquiryLoading || !departmentInquiry;

  if (!inquiryId || !departmentInquiryId) {
    return null;
  }

  return (
    <>
      {isLoading ? (
        <TitleSkeleton />
      ) : (
        <Title
          inquiryName={departmentInquiry.departmentName}
          schoolName={departmentInquiry.schoolName}
        />
      )}
      {isLoading ? (
        <ListSkeleton />
      ) : (
        <List
          departmentName={departmentInquiry.departmentName}
          status={<Status schoolStatus={departmentInquiry.schoolStatus} />}
          spent={departmentInquiry.budgetSpent}
          assigned={departmentInquiry.budgetAssigned}
          currency={departmentInquiry.budgetCurrency}
          itemsWithoutPrice={departmentInquiry.itemsWithoutPrice}
          actions={
            <>
              <NeedReview
                {...{
                  inquiryId,
                  departmentInquiryId,
                }}
                disabled={
                  !departmentInquiry.possibleActions.includes("sendToReview")
                }
              />
              <Approve
                {...{
                  inquiryId,
                  departmentInquiryId,
                }}
                disabled={
                  !departmentInquiry.possibleActions.includes("approve")
                }
                displayHelp={departmentInquiry.displayHelpMessageForActions.includes(
                  "approve"
                )}
              />
              <ContextMenu
                {...{
                  inquiryId,
                  departmentInquiryId,
                }}
                editAction={{
                  disabled: !departmentInquiry.possibleActions.includes("edit"),
                }}
                cancelAction={{
                  disabled:
                    !departmentInquiry.possibleActions.includes("cancel"),
                }}
                deleteAction={{
                  disabled:
                    !departmentInquiry.possibleActions.includes("delete"),
                }}
              />
            </>
          }
        />
      )}
    </>
  );
};
