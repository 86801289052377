import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../../../../theme/astra/colors";
import { Role } from "../../../../../../../permissions/permissions";

type NameProps =
  | {
      type: "departmentInquiry";
    }
  | {
      type: "assignment";
      user: string;
      role: Role;
    };

export const Name = (props: NameProps) => {
  const { t } = useTranslation("pages");

  if (props.type === "departmentInquiry") {
    return (
      <Container>
        <Heading component="span">
          {t("schoolInquiryDetails.listOfAllItems")}
        </Heading>
      </Container>
    );
  }

  return (
    <Container>
      <Heading component="span">{props.user}</Heading>
      <Typography
        component="span"
        sx={{
          color: colors.dustBlue1,
          fontSize: "10px !important",
          lineHeight: "23px",
          fontWeight: "400",
        }}
      >
        •
      </Typography>
      <Typography
        component="span"
        sx={{
          color: colors.dustBlue1,
          fontSize: "10px !important",
          lineHeight: "23px",
          fontWeight: "400",
        }}
      >
        {t(`roles.${props.role}`, { ns: "common" })}
      </Typography>
    </Container>
  );
};

const Container = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "6px",
});

type HeadingProps = {
  component: "span";
};

const Heading = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "component",
})<HeadingProps>({
  color: colors.dustBlue1,
  fontSize: "13px",
  lineHeight: "23px",
  fontWeight: "400",
});
