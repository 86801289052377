import React from "react";
import { EnhancedSkeleton } from "../../../../../components/EnhancedSkeleton";
import { Line } from "./Line";
import { Box } from "@mui/material";

export const TitleSkeleton = () => {
  return (
    <Box sx={{ marginTop: "50px" }}>
      <EnhancedSkeleton
        variant="text"
        width={[80, 180, "px"]}
        sx={{ lineHeight: "21px" }}
      />
      <EnhancedSkeleton
        variant="text"
        width={[80, 180, "px"]}
        sx={{ lineHeight: "10px" }}
      />
      <Line />
    </Box>
  );
};
