import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GetItemResponseDto } from "../../../../slices/items/types";
import { usePermissions } from "../../../../utils/usePermissions";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { SelectItemDetailsInquiryStatus } from "../../selectors/SelectItemDetailsInquiryStatus";
import { MatchCard } from "./MatchCard";
import { SelectItemDetailsOrderStatus } from "../../selectors/SelectItemDetailsOrderStatus";
import { useItemDetailsUrlTools } from "../../useItemDetailsUrlTools";
import { Link } from "react-router-dom";
import { useGetInquiryCustomFieldsQuery } from "../../../../slices/inquiries/api";
import { skipToken } from "@reduxjs/toolkit/query/react";

type ItemDataProps = {
  item: GetItemResponseDto;
};

export const ItemData = ({
  item: {
    id,
    internalLineNumber,
    customerLineNumber,
    name,
    inquiryStatus,
    orderStatus,
    supplier,
    supplierProductCode,
    quantity,
    departmentInquiry,
    image,
    product,
    matchingProduct,
    customFields,
  },
}: ItemDataProps) => {
  const { t } = useTranslation("pages");
  const can = usePermissions();

  const { inquiryId, baseUrl } = useItemDetailsUrlTools();

  const { data: inquiryCustomFields } = useGetInquiryCustomFieldsQuery(
    inquiryId ? { inquiryId } : skipToken
  );

  const images = useMemo(() => {
    if (image) {
      return [{ src: image }];
    }

    if (matchingProduct) {
      return matchingProduct.images.map((image) => ({
        src: image,
      }));
    }

    return null;
  }, [image, matchingProduct]);

  const content = useMemo(() => {
    const customFieldsKeys = Object.keys(customFields).filter(
      (customFieldsKey) => inquiryCustomFields?.includes(customFieldsKey)
    );

    return (
      <>
        <Typography variant="body1">
          {t("columns.id", { ns: "common" })}
          {": "}
          {internalLineNumber || t("na", { ns: "common" })}
        </Typography>
        <Typography variant="body1">
          {t("items.fields.customerLineNumber")}
          {": "}
          {customerLineNumber || t("na", { ns: "common" })}
        </Typography>
        <Typography variant="body1">
          {supplier?.name || t("na", { ns: "common" })}{" "}
          {supplierProductCode || t("na", { ns: "common" })}
        </Typography>
        <Typography variant="body1" dir="auto">
          {name || t("na", { ns: "common" })}
        </Typography>
        <Typography variant="body1">{`${t(
          "items.fields.quantity"
        )}: ${quantity}`}</Typography>
        {customFieldsKeys.length > 0 &&
          customFieldsKeys.map((customFieldKey) => (
            <Box
              key={customFieldKey}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography variant="subtitle2" dir="auto">
                {customFieldKey}
              </Typography>
              <Typography variant="subtitle1" dir="auto">
                {customFields[customFieldKey]}
              </Typography>
            </Box>
          ))}
      </>
    );
  }, [
    customFields,
    t,
    internalLineNumber,
    customerLineNumber,
    supplier?.name,
    supplierProductCode,
    name,
    quantity,
    inquiryCustomFields,
  ]);

  const actions = useMemo(() => {
    const displayInquiryStatus = can("editInquiryStatus");
    const displayOrderStatus = can("editOrderStatus") && orderStatus;
    const displayEdit = can("editItem") && !product;

    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          ...(displayOrderStatus
            ? {
                alignItems: "stretch",
                flexDirection: "column",
                gap: 1,
              }
            : {
                alignItems: "flex-end",
                gap: 2,
              }),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexShrink: 1,
            gap: 1,
          }}
        >
          {displayInquiryStatus && (
            <Tooltip title={t("itemDetails.inquiryStatus")} placement="top">
              <SelectItemDetailsInquiryStatus
                id={id}
                defaultValue={inquiryStatus}
                inquiryId={departmentInquiry.inquiry.id}
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              />
            </Tooltip>
          )}
          {displayOrderStatus && (
            <Tooltip title={t("itemDetails.orderStatus")} placement="top">
              <SelectItemDetailsOrderStatus
                id={id}
                defaultValue={orderStatus}
                inquiryId={departmentInquiry.inquiry.id}
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              />
            </Tooltip>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
          }}
        >
          {displayEdit && (
            <Button
              component={Link}
              to={`${baseUrl}/edit`}
              size="small"
              sx={{ marginLeft: "auto !important" }}
            >
              {t("itemDetails.editItem")}
            </Button>
          )}
          {matchingProduct && (
            <Button
              component={Link}
              to={`${baseUrl}/product/${matchingProduct.id}`}
              size="small"
            >
              {t("itemDetails.viewProduct")}
            </Button>
          )}
        </Box>
      </Box>
    );
  }, [
    can,
    orderStatus,
    product,
    t,
    id,
    inquiryStatus,
    departmentInquiry.inquiry.id,
    baseUrl,
    matchingProduct,
  ]);

  return (
    <MatchCard
      label={t("itemDetails.itemLabel")}
      actions={actions}
      images={images}
    >
      {content}
    </MatchCard>
  );
};
