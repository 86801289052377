import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import { useHistory } from "react-router-dom";
import {
  useChangeRequestedItemsStatusMutation,
  useGetSchoolAssignmentQuery,
} from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useCanBulkSubmit } from "./useCanBulkSubmit";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { Hint } from "../../../../../components/hints/Hint";
import { RouteState } from "../../../../../types";
import AddIcon from "@mui/icons-material/Add";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { ToolbarButton } from "../ToolbarButton";

type TableControlsProps = {
  allIds: number[];
  selectedIds?: number[];
};

export const TableControls = ({ allIds, selectedIds }: TableControlsProps) => {
  const { t } = useTranslation("pages");
  const history = useHistory<RouteState>();

  const { handleSuccess, handleError } = useMessageHandlers();

  const { baseUrl, inquiryId, departmentInquiryId, assignmentId } =
    useSchoolInquiryItemsTools();

  const { data: assignment } = useGetSchoolAssignmentQuery(
    assignmentId ? { assignmentId } : skipToken
  );

  const addItemHandler = useCallback(() => {
    history.push(`${baseUrl}/add/item`);
  }, [baseUrl, history]);

  const [changeStatus, { isLoading: isChanging }] =
    useChangeRequestedItemsStatusMutation();

  const bulkApproveStatus = useCanBulkSubmit({ assignmentId });

  const bulkSelectedApproveHandler = useCallback(() => {
    if (
      bulkApproveStatus &&
      departmentInquiryId &&
      assignmentId &&
      selectedIds &&
      selectedIds.length > 0
    ) {
      changeStatus({
        inquiryId,
        departmentInquiryId,
        assignmentId,
        itemsIds: selectedIds,
        schoolStatus: bulkApproveStatus,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    assignmentId,
    bulkApproveStatus,
    changeStatus,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    selectedIds,
  ]);

  const bulkApproveHandler = useCallback(() => {
    if (bulkApproveStatus && departmentInquiryId && assignmentId) {
      changeStatus({
        inquiryId,
        departmentInquiryId,
        assignmentId,
        itemsIds: allIds,
        schoolStatus: bulkApproveStatus,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    allIds,
    assignmentId,
    bulkApproveStatus,
    changeStatus,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
  ]);

  const buttons = (() => {
    if (selectedIds && selectedIds.length > 0) {
      return (
        <Hint
          helpText={t("hints.schoolInquiryDetails.approveTableItems")}
          hide={!bulkApproveStatus}
        >
          <ConfirmationButton
            component={ToolbarButton}
            onClick={bulkSelectedApproveHandler}
            confirmationTitle={t(
              "schoolInquiryDetails.itemsTableActions.approve.confirmationTitle"
            )}
            confirmationDescription={t("noUndo", { ns: "common" })}
            loading={isChanging}
            disabled={!bulkApproveStatus}
            size="small"
            variant="outlined"
          >
            {t("schoolInquiryDetails.itemsTableActions.approve.label")}
          </ConfirmationButton>
        </Hint>
      );
    }

    if (!assignment) {
      return null;
    }

    return (
      <>
        <Hint helpText={t("hints.schoolInquiryDetails.addProduct")}>
          <ToolbarButton
            onClick={addItemHandler}
            variant="outlined"
            disabled={!assignment.possibleActions.includes("addRequestedItem")}
            size="small"
          >
            <AddIcon
              sx={{
                fontSize: "18px",
                marginRight: "10px",
              }}
            />
            {t("schoolInquiryDetails.itemsTableActions.addItem")}
          </ToolbarButton>
        </Hint>
        {allIds.length > 0 && (
          <ConfirmationButton
            component={ToolbarButton}
            confirmationTitle={t(
              "schoolInquiryDetails.itemsTableActions.approveAllItems.confirmationTitle"
            )}
            confirmationDescription={t(
              "schoolInquiryDetails.itemsTableActions.approveAllItems.confirmationDescription"
            )}
            size="small"
            variant="outlined"
            onClick={bulkApproveHandler}
            disabled={!bulkApproveStatus}
          >
            {t("schoolInquiryDetails.itemsTableActions.approveAllItems.label")}
          </ConfirmationButton>
        )}
      </>
    );
  })();

  return (
    <Box
      sx={{
        marginTop: "16px",
        marginBottom: "16px",
        display: "flex",
        gap: "16px",
      }}
    >
      {buttons}
    </Box>
  );
};
