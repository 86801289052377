import React, { useCallback, useEffect, useState } from "react";
import { useTableUrlTools } from "../../../../utils/useTableUrlTools";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Form } from "../../../../components/wideDrawer/Form";
import { AstraEntityForm } from "./AstraEntityForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../../utils/validation";
import { astraEntitySchema } from "./schema";
import {
  useEditAstraEntityMutation,
  useGetAstraEntityQuery,
} from "../../../../slices/astraEntities/api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useFeedSuccess } from "../../../../utils/feedHooks";
import { useSubmitFormErrorHander } from "../../../../utils/formHelpers";
import { Spinner } from "../../../../components/wideDrawer/Spinner";
import { AstraEntityFormProp } from "./types";
import { uploadAstraEntityLogo } from "../../../../slices/astraEntities/uploadAstraEntityLogo";

export const EditAstraEntity = () => {
  const { t } = useTranslation("pages");

  const { id, closeDrawer } = useTableUrlTools("/astra-entities");

  const { control, handleSubmit, reset } = useForm<AstraEntityFormProp>({
    resolver: yupResolver(useYup(astraEntitySchema)),
    defaultValues: {
      logo: {
        fileList: undefined,
        url: undefined,
      },
    },
  });

  const {
    data: astraEntity,
    isLoading: isAstraEntityLoading,
    error: astraEntityLoadingError,
  } = useGetAstraEntityQuery(id ? { id } : skipToken);

  useEffect(() => {
    if (astraEntityLoadingError) {
      closeDrawer();
    }
  }, [astraEntityLoadingError, closeDrawer]);

  useEffect(() => {
    if (astraEntity) {
      reset({
        logo: {
          fileList: null,
          url: astraEntity.logoUrl,
        },
        name: astraEntity.name,
        code: astraEntity.code,
        addressLine: astraEntity.addressLine,
        addressLine2: astraEntity.addressLine2 ?? "",
        state: astraEntity.state,
        city: astraEntity.city,
        zipCode: astraEntity.zipCode,
        countryId: astraEntity.country.id,
      });
    }
  }, [astraEntity, reset]);

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("astraEntities.errors.edit")
  );

  const [isLogoUploading, setIsLogoUploading] = useState(false);

  const [editAstraEntity, { isLoading: isAstraEntitySubmitting }] =
    useEditAstraEntityMutation();

  const onSubmit = useCallback(
    ({ logo, ...form }: AstraEntityFormProp) => {
      if (!id) {
        return;
      }

      setIsLogoUploading(true);

      uploadAstraEntityLogo(logo.fileList)
        .then((logoS3Key) => {
          setIsLogoUploading(false);

          return editAstraEntity({
            id,
            logoS3Key,
            removeLogo: !logo.url && !logoS3Key,
            ...form,
          }).unwrap();
        })
        .then(() => {
          feedSuccess(
            t("astraEntities.successes.edit"),
            `/astra-entities/${id}`
          );
          closeDrawer();
        })
        .catch((error) => {
          setIsLogoUploading(false);
          catchFormError(error);
        });
    },
    [catchFormError, closeDrawer, editAstraEntity, feedSuccess, id, t]
  );

  if (isAstraEntityLoading) {
    return <Spinner />;
  }

  const isSubmitting = isLogoUploading || isAstraEntitySubmitting;

  return (
    <Form
      heading={t("astraEntities.editAstraEntity")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      formError={formError}
    >
      <AstraEntityForm control={control} fieldsErrors={fieldsErrors} />
    </Form>
  );
};
