import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFeedSuccess } from "../../../utils/feedHooks";
import { FormProvider } from "react-hook-form";
import { Form } from "../../../components/wideDrawer/Form";
import { useAddItemMutation } from "../../../slices/items/api";
import { ItemForm } from "./form/ItemForm";
import { useSubmitFormErrorHander } from "../../../utils/formHelpers";
import { useItemsTableBaseUrl } from "../hooks";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { useGetInquiryCustomFieldsQuery } from "../../../slices/inquiries/api";
import { Spinner } from "../../../components/wideDrawer/Spinner";
import { ItemForm as ItemFormProp } from "./form/types";
import { useItemForm } from "./form/useItemForm";

export const Add = () => {
  const { t } = useTranslation("pages");

  const { baseUrl, inquiryId, detailId } = useItemsTableBaseUrl();
  const { closeDrawer } = useTableUrlTools(baseUrl);

  const {
    data: customFields,
    isLoading: areCustomFieldsLoading,
    error: customFieldsLoadingError,
  } = useGetInquiryCustomFieldsQuery({ inquiryId });

  const formMethods = useItemForm(customFields);
  const { handleSubmit, reset } = formMethods;

  const feedSuccess = useFeedSuccess();
  const { catchFormError, formError, fieldsErrors } = useSubmitFormErrorHander(
    t("items.errors.add")
  );

  const [addItem, { isLoading }] = useAddItemMutation();

  const onSubmit = useCallback(
    (form: ItemFormProp) => {
      if (!customFields) {
        return;
      }

      const collectedCustomFields: Record<string, string> = {};

      for (const customField of customFields) {
        collectedCustomFields[customField] = form[customField] ?? "";
      }

      addItem({
        inquiryId,
        departmentInquiryId: detailId,
        supplierId: form.supplierId,
        supplierProductCode: form.supplierProductCode,
        name: form.name,
        quantity: form.quantity,
        customerLineNumber: form.customerLineNumber,
        customFields: collectedCustomFields,
      })
        .unwrap()
        .then(({ id }) => {
          feedSuccess(t("items.successes.add"), `${baseUrl}/${id}/details`);
          closeDrawer();
          reset();
        })
        .catch(catchFormError);
    },
    [
      addItem,
      baseUrl,
      catchFormError,
      closeDrawer,
      customFields,
      detailId,
      feedSuccess,
      inquiryId,
      reset,
      t,
    ]
  );

  useEffect(() => {
    if (customFieldsLoadingError) {
      closeDrawer();
    }
  }, [closeDrawer, customFieldsLoadingError]);

  if (areCustomFieldsLoading) {
    return <Spinner />;
  }

  if (!customFields) {
    return null;
  }

  return (
    <Form
      heading={t("items.add")}
      onSubmit={handleSubmit(onSubmit)}
      loading={isLoading}
      formError={formError}
    >
      <FormProvider {...formMethods}>
        <ItemForm customFields={customFields} fieldsErrors={fieldsErrors} />
      </FormProvider>
    </Form>
  );
};
