import React from "react";
import { useTranslation } from "react-i18next";
import { Control, Controller } from "react-hook-form";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { fieldError, FieldsErrorState } from "../../../utils/formHelpers";
import { usePermissions } from "../../../utils/usePermissions";
import { SelectAstraEntity } from "../../../components/prefilledSelectors/SelectAstraEntity";
import { EditCountryForm } from "./Edit";
import { AddCountryForm } from "./Add";

type CountryFormProps = {
  control: Control<AddCountryForm> | Control<EditCountryForm>;
  fieldsErrors?: FieldsErrorState;
  isEditing?: boolean;
};

export const CountryForm: React.FC<CountryFormProps> = ({
  control,
  fieldsErrors,
  isEditing = false,
}) => {
  const { t } = useTranslation();
  const can = usePermissions();

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "name",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("name")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="code"
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "code",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("code")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="defaultAstraEntityId"
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => {
            const [serverInvalid, serverError] = fieldError(
              "defaultAstraEntityId",
              fieldsErrors
            );
            return (
              <SelectAstraEntity
                value={value}
                onChange={onChange}
                label={t("countries.fields.defaultAstraEntity", {
                  ns: "pages",
                })}
                fullWidth
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 3">
        <Controller
          control={control}
          name="active"
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              disabled={!can("changeCountryActive") || !isEditing}
              label={t("countries.activeStatus") as string}
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
