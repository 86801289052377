import React from "react";
import { LoadingIconButton } from "../../../../../../components/LoadingIconButton";
import { Tooltip } from "@mui/material";
import { Trash } from "../../../../../../components/astraIcons/Trash";
import { ConfirmationButton } from "../../../../../../components/ConfirmationButton";
import { useTranslation } from "react-i18next";

export const DeleteAction = ({
  onClick,
  isLoading,
  disabled,
}: {
  onClick: () => void;
  isLoading?: boolean;
  disabled: boolean;
}) => {
  const { t } = useTranslation("pages");

  return (
    <ConfirmationButton
      component={LoadingIconButton}
      confirmationTitle={t(
        "schoolInquiryDetails.itemActions.delete.confirmationTitle"
      )}
      confirmationDescription={t(
        "schoolInquiryDetails.itemActions.delete.confirmationDescription"
      )}
      variant="transparent"
      onClick={onClick}
      loading={isLoading}
      disabled={disabled}
    >
      <Tooltip title={t("schoolInquiryDetails.itemActions.delete.label")}>
        <Trash />
      </Tooltip>
    </ConfirmationButton>
  );
};
