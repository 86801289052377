import React, { useCallback, useEffect, useState } from "react";
import { getDownloadUrl } from "../../../utils/S3UploadFile";
import { GridRowId } from "@mui/x-data-grid";
import { saveBlob } from "../../../utils/downloadFileFromServer";
import { FileIcon } from "../../../components/FileIcon";
import { CircleButton } from "../../../components/toolbar/CircleButton";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

type PdfProp = {
  name: string;
  url: string;
};

type DownloadFileCellBtnProps = {
  commercialOfferId: GridRowId;
  original?: boolean;
};

export const DownloadFileCellBtn = ({
  commercialOfferId,
  original,
}: DownloadFileCellBtnProps) => {
  const { t } = useTranslation("pages");

  const intId =
    typeof commercialOfferId === "string"
      ? parseInt(commercialOfferId)
      : commercialOfferId;

  const [pdf, setPdf] = useState<PdfProp | null>(null);

  useEffect(() => {
    getDownloadUrl(intId, original ? "origco" : "co")
      .then((response) => response.json())
      .then((resData) => {
        setPdf({
          name: resData.filename,
          url: resData.url,
        });
      });
  }, [intId, original]);

  const clickHandler = useCallback(() => {
    if (pdf) {
      fetch(pdf.url, {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => saveBlob(blob, pdf.name));
    }
  }, [pdf]);

  return (
    <CircleButton
      onClick={clickHandler}
      tooltip={t("downloadFile", { ns: "common" })}
      confirmation={{
        title: t("inquiryDetails.commercialOfferPreview.title"),
        description: (
          <Box
            component="iframe"
            src={pdf?.url}
            sx={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
          />
        ),
        proceedButtonText: t(
          "inquiryDetails.commercialOfferPreview.proceedButtonText"
        ),
      }}
      disabled={!pdf}
      DialogProps={{
        fullWidth: true,
        fullHeight: true,
        maxWidth: "lg",
      }}
    >
      <FileIcon filename="pdf" />
    </CircleButton>
  );
};
