import React, { memo } from "react";
import {
  Box,
  Button,
  IconButton,
  StandardTextFieldProps,
  styled,
  TextField,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Circular } from "../spinners/Circular";
import { LoadingButton } from "@mui/lab";

export type NewToolbarProps = {
  addButtonText?: string;
  addButtonDisabled?: boolean;
  onAddClick?: () => void;
  additionalButtonText?: string;
  additionalButtonDisabled?: boolean;
  additionalButtonDownloadInProcess?: boolean;
  onAdditionalClick?: () => void;
  hideAddButtonPlusSymbol?: boolean;
  searchValue: string;
  searchLabel?: string;
  onSearchChange: StandardTextFieldProps["onChange"];
  onReset: () => void;
  filters?: React.ReactNode;
  buttons?: React.ReactNode;
  contentLoading?: boolean;
};

export const NewToolbar = memo<NewToolbarProps>(
  ({
    addButtonText,
    addButtonDisabled,
    onAddClick,
    additionalButtonText,
    additionalButtonDisabled,
    additionalButtonDownloadInProcess,
    onAdditionalClick,
    searchValue,
    searchLabel,
    hideAddButtonPlusSymbol,
    onSearchChange,
    onReset,
    filters,
    buttons,
    contentLoading,
  }) => {
    const { t } = useTranslation("components");

    return (
      <>
        {((addButtonText && onAddClick) ||
          (additionalButtonText && onAdditionalClick)) && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "right",
              marginBottom: 3,
            }}
          >
            {addButtonText && onAddClick && (
              <NewDesignButton
                startIcon={hideAddButtonPlusSymbol ? null : <Add />}
                variant="outlined"
                onClick={onAddClick}
                disabled={addButtonDisabled}
              >
                {addButtonText}
              </NewDesignButton>
            )}

            {additionalButtonText &&
              onAdditionalClick &&
              (!additionalButtonDownloadInProcess ? (
                <NewDesignButton
                  variant="outlined"
                  onClick={onAdditionalClick}
                  disabled={additionalButtonDisabled}
                >
                  {additionalButtonText}
                </NewDesignButton>
              ) : (
                <LoadingButton loading variant="outlined" />
              ))}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            gap: 3,
            marginBottom: 2,
          }}
        >
          {buttons}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: 1,
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexShrink: 1,

                "> *": {
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "auto",
                  maxWidth: "250px",
                },
              }}
            >
              {filters}
            </Box>
            <TextField
              variant="outlined"
              size="small"
              label={searchLabel || t("toolbar.search")}
              value={searchValue}
              onChange={onSearchChange}
              sx={{
                flexGrow: 1,
                flexShrink: 1,
                marginLeft: "auto",
              }}
            />
            {contentLoading ? (
              <Box
                sx={{
                  width: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Circular size={25} thickness={175} />
              </Box>
            ) : (
              <IconButton onClick={onReset}>
                <Close />
              </IconButton>
            )}
          </Box>
        </Box>
      </>
    );
  }
);

// TODO: move to theme, if necessary
const NewDesignButton = styled(Button)({
  textTransform: "none",
  fontSize: "13px",
  padding: "6px 20px",
  color: "#3390FF",
  backgroundColor: "#F1F7FF",
  "&:hover": {
    backgroundColor: "#E7F1FF",
  },
});
