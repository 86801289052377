import React from "react";
import { EnhancedSkeleton } from "../../../../../components/EnhancedSkeleton";

export const ListSkeleton = () => {
  return (
    <EnhancedSkeleton
      variant="rectangular"
      sx={{
        width: "100%",
        height: "50px",
      }}
    />
  );
};
