import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useItemFormSchema } from "./schema";
import { ItemForm } from "./types";

export const useItemForm = (customFields?: string[]) =>
  useForm<ItemForm>({
    resolver: yupResolver(useItemFormSchema(customFields ?? [])),
    // @ts-ignore
    defaultValues: {
      name: "",
      supplierId: undefined,
      supplierProductCode: "",
      quantity: 1,
      customerLineNumber: null,
    },
  });
