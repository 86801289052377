import React from "react";
import { MenuItem, TextFieldProps } from "@mui/material";
import { useGetOrderManagersQuery } from "../../slices/users/api";
import { LoadingTextField } from "../LoadingTextField";
import { displayFullName } from "../../utils/displayFullName";

export const SelectOrderManager = (props: Omit<TextFieldProps, "select">) => {
  const { data: orderManagers = [], isLoading: areOrderManagersLoading } =
    useGetOrderManagersQuery();

  return (
    <LoadingTextField loading={areOrderManagersLoading} select {...props}>
      {orderManagers.map((inquiryManager) => (
        <MenuItem key={inquiryManager.id} value={inquiryManager.id}>
          {displayFullName({
            firstName: inquiryManager.firstName,
            lastName: inquiryManager.lastName,
          })}
        </MenuItem>
      ))}
    </LoadingTextField>
  );
};
