import React, { useCallback } from "react";
import { Checkbox, CheckboxProps } from "@mui/material";
import { useChangeIncludedInCommercialOfferMutation } from "../../../../slices/items/api";
import { useMessageHandlers } from "../../../../utils/useMessageHandlers";

type IncludedInCommercialOfferProps = {
  inquiryId: number;
  itemId: number;
  value: boolean;
};

export const IncludedInCommercialOffer = ({
  inquiryId,
  itemId,
  value,
}: IncludedInCommercialOfferProps) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const [change, { isLoading: isChanging }] =
    useChangeIncludedInCommercialOfferMutation();

  const changeHandler = useCallback<NonNullable<CheckboxProps["onChange"]>>(
    (_event, checked) => {
      change({
        inquiryId,
        itemsIds: [itemId],
        includedInCommercialOffer: checked,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    },
    [change, handleError, handleSuccess, inquiryId, itemId]
  );

  return (
    <Checkbox checked={value} onChange={changeHandler} disabled={isChanging} />
  );
};
