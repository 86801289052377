import { useCallback } from "react";
import { RequestedItemDetails } from "./types";
import { useSelector } from "../../../../../../store";
import { selectSelf } from "../../../../../../slices/auth/selectors";
import { useChangeRequestedItemsStatusMutation } from "../../../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";

export const useApproveHandler = ({
  inquiryId,
  departmentInquiryId,
  assignmentId,
  requestedItemId,
  possibleActions,
}: RequestedItemDetails) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const [changeRequestedItemsStatus, { isLoading }] =
    useChangeRequestedItemsStatusMutation();

  const handle = useCallback(() => {
    const schoolStatus = (() => {
      if (self?.role === "headOfDepartment") {
        return "approvedByHead";
      }

      if (self?.role === "teacher") {
        return "approvedByTeacher";
      }
    })();

    if (!schoolStatus) {
      return;
    }

    changeRequestedItemsStatus({
      inquiryId,
      departmentInquiryId,
      assignmentId,
      itemsIds: [requestedItemId],
      schoolStatus,
    })
      .unwrap()
      .then(handleSuccess)
      .catch((e) => {
        handleError(e);
      });
  }, [
    assignmentId,
    changeRequestedItemsStatus,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    requestedItemId,
    self,
  ]);

  const isPossible = (() => {
    if (self?.role === "headOfDepartment") {
      return possibleActions.includes("setApprovedByHead");
    }

    if (self?.role === "teacher") {
      return possibleActions.includes("setApprovedByTeacher");
    }

    return false;
  })();

  return { handle, isPossible, isLoading };
};
