import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl, prepareHeaders } from "../../utils/api";
import { AddCountryRequestDto, Country, EditCountryRequestDto } from "./types";

export const countriesApi = createApi({
  reducerPath: "countriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getUrl("/countries"),
    prepareHeaders,
  }),
  tagTypes: ["Country"],
  endpoints: (builder) => ({
    getCountries: builder.query<Country[], void>({
      query: () => "",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Country" as const,
                id,
              })),
              { type: "Country", id: "list" },
            ]
          : [{ type: "Country", id: "list" }],
    }),
    getCountry: builder.query<Country, number>({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: "Country", id }],
    }),
    addCountry: builder.mutation<Country, AddCountryRequestDto>({
      query: (body) => ({ url: "", method: "POST", body }),
      invalidatesTags: [{ type: "Country", id: "list" }],
    }),
    editCountry: builder.mutation<Country, EditCountryRequestDto>({
      query: ({ id, ...body }) => ({ url: `/${id}`, method: "PUT", body }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Country", id: "list" },
        { type: "Country", id },
      ],
    }),
    changeCountryActivity: builder.mutation<
      Country,
      { id: number; active: boolean }
    >({
      query: ({ id, ...body }) => ({
        url: `/${id}/changeActivity`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Country", id: "list" },
        { type: "Country", id },
      ],
    }),
    deleteCountry: builder.mutation<{ message: string }, number>({
      query: (id) => ({ url: `${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "Country", id: "list" },
        { type: "Country", id },
      ],
    }),
  }),
});

export const {
  useGetCountriesQuery,
  useAddCountryMutation,
  useEditCountryMutation,
  useChangeCountryActivityMutation,
  useDeleteCountryMutation,
} = countriesApi;
