import React, { useCallback, useMemo } from "react";
import { NewDesignContextMenu } from "../../../../../../components/NewDesignContextMenu";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { useHistory } from "react-router-dom";
import { useSchoolInquiryItemsTools } from "../../../../useSchoolInquiryItemsTools";
import {
  useCancelSchoolDepartmentInquiryMutation,
  useDeleteSchoolDepartmentInquiryMutation,
} from "../../../../../../slices/schoolInquiries/api";
import { useSelector } from "../../../../../../store";
import { selectSelf } from "../../../../../../slices/auth/selectors";

type ActionProp = {
  disabled: boolean;
};

type ContextMenuProps = {
  inquiryId: number;
  departmentInquiryId: number;
  editAction: ActionProp;
  cancelAction: ActionProp;
  deleteAction: ActionProp;
};

export const ContextMenu = ({
  inquiryId,
  departmentInquiryId,
  editAction,
  cancelAction,
  deleteAction,
}: ContextMenuProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();
  const history = useHistory();

  const self = useSelector(selectSelf);

  const { baseUrl } = useSchoolInquiryItemsTools();

  const [cancel] = useCancelSchoolDepartmentInquiryMutation();

  const [deleteSchoolDepartmentInquiry] =
    useDeleteSchoolDepartmentInquiryMutation();

  const deleteHandler = useCallback(() => {
    if (departmentInquiryId) {
      deleteSchoolDepartmentInquiry({ inquiryId, departmentInquiryId })
        .unwrap()
        .then(handleSuccess)
        .then(() => {
          history.push(baseUrl);
        })
        .catch(handleError);
    }
  }, [
    deleteSchoolDepartmentInquiry,
    departmentInquiryId,
    handleError,
    handleSuccess,
    history,
    inquiryId,
    baseUrl,
  ]);

  const editHandler = useCallback(() => {
    history.push(`${baseUrl}/edit/department`);
  }, [baseUrl, history]);

  const cancelClickHandler = useCallback(() => {
    if (departmentInquiryId) {
      cancel({ inquiryId, departmentInquiryId })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [cancel, departmentInquiryId, handleError, handleSuccess, inquiryId]);

  return useMemo(() => {
    if (self?.role !== "principal") {
      return null;
    }

    return (
      <NewDesignContextMenu
        sx={{ marginLeft: "auto" }}
        buttons={[
          {
            label: t("schoolInquiryDetails.departmentControls.cancel.label"),
            handler: cancelClickHandler,
            disabled: cancelAction.disabled,
            confirmation: {
              title: t(
                "schoolInquiryDetails.departmentControls.cancel.confirmationTitle"
              ),
              description: t("noUndo", { ns: "common" }),
            },
          },
          {
            label: t("schoolInquiryDetails.departmentControls.edit"),
            disabled: editAction.disabled,
            handler: editHandler,
          },
          {
            label: t("schoolInquiryDetails.departmentControls.delete.label"),
            handler: deleteHandler,
            disabled: deleteAction.disabled,
            confirmation: {
              title: t(
                "schoolInquiryDetails.departmentControls.delete.confirmationTitle"
              ),
              description: t("noUndo", { ns: "common" }),
            },
          },
        ]}
      />
    );
  }, [
    cancelAction.disabled,
    cancelClickHandler,
    deleteAction.disabled,
    deleteHandler,
    editAction.disabled,
    editHandler,
    self?.role,
    t,
  ]);
};
