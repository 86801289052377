import {
  Box,
  Checkbox,
  CheckboxProps,
  styled,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import {
  ListColumn,
  ListIsEverythingExpanded,
  ListIsEverythingSelected,
  RowBase,
} from "./types";
import { colors } from "../../theme/astra/colors";
import { IconButtonProps } from "../astraComponents/IconButton";
import { ExpandButton } from "./ExpandButton";

type HeaderProps<
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
> = {
  columns: ListColumn<Row, SubRow, SubRowsKey>[];
  selected?: ListIsEverythingSelected;
  onSelectedChange?: (checked: boolean) => void;
  expanded?: ListIsEverythingExpanded;
  onExpand?: () => void;
};

export const Header = <
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
>({
  columns,
  selected,
  onSelectedChange,
  expanded,
  onExpand,
}: HeaderProps<Row, SubRow, SubRowsKey>) => {
  const expandClickHandler = useCallback<
    NonNullable<IconButtonProps["onClick"]>
  >(() => {
    if (onExpand) {
      onExpand();
    }
  }, [onExpand]);

  const checkboxChangeHandler = useCallback<
    NonNullable<CheckboxProps["onChange"]>
  >(
    (_event, checked) => {
      if (onSelectedChange) {
        onSelectedChange(checked);
      }
    },
    [onSelectedChange]
  );

  return (
    <>
      {typeof expanded === "string" && (
        <Cell sx={{ justifyContent: "center" }}>
          <ExpandButton
            header
            expanded={expanded === "all"}
            onClick={expandClickHandler}
          />
        </Cell>
      )}
      {typeof selected === "string" && (
        <Cell sx={{ justifyContent: "center" }}>
          <Checkbox
            checked={selected === "all" || selected === "part"}
            indeterminate={selected === "part"}
            onChange={checkboxChangeHandler}
          />
        </Cell>
      )}
      {columns.map((column) => (
        <Cell
          key={column.field}
          sx={{
            paddingLeft: "12px",
            paddingRight: "12px",
            ...column.boxSx,
          }}
        >
          <Typography
            component="span"
            sx={{
              color: colors.dustBlue1,
              fontSize: "12px",
              lineHeight: "24px",
              fontWeight: "400",
            }}
          >
            {column.name}
          </Typography>
        </Cell>
      ))}
    </>
  );
};

const Cell = styled(Box)({
  backgroundColor: colors.dustBlue6,
  borderBottom: `1px solid ${colors.dustBlue4}`,
  height: "48px",
  display: "flex",
  alignItems: "center",
});
