import React from "react";
import { ContainerBase } from "../../../components/layout/ContainerBase";
import { Items } from "./Items/Items";
import { useTranslation } from "react-i18next";
import { Back } from "../../../components/layout/Back";
import { Header } from "./Header/Header";

export const Content = () => {
  const { t } = useTranslation("pages");

  return (
    <ContainerBase
      sx={{
        maxWidth: "100%",
        paddingTop: "30px",
      }}
      maxWidth="xl"
    >
      <Back to="/schoolinquiries" sx={{ marginLeft: "-8px" }}>
        {t("schoolInquiryDetails.backToInquiries")}
      </Back>
      <Header />
      <Items />
    </ContainerBase>
  );
};
