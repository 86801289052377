import React, { useCallback } from "react";
import { IconButton } from "../../../../../../components/astraComponents/IconButton";
import { AnnotationDots } from "../../../../../../components/astraIcons/AnnotationDots";
import { Box, Button } from "@mui/material";
import { AstraConfirmationDialog } from "../../../../../../components/AstraConfirmationDialog";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ReviewCommentFormProp } from "../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../../../../utils/validation";
import { reviewCommentFormSchema } from "../reviewCommentFormSchema";
import { MessageTextField } from "../MessageTextField";
import { LoadingButton } from "@mui/lab";
import { ReviewCommentsResponseDtoBase } from "../../../../../../slices/schoolInquiries/types";
import { CommentsList } from "./CommentsList";

type ReviewCommentsProps = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  code: string;
  reviewComments: ReviewCommentsResponseDtoBase;
  areReviewCommentsLoading?: boolean;
  onSubmit: (reviewComment: string) => void;
  isSubmitting?: boolean;
};

export const ReviewComments = ({
  open,
  onOpen,
  onClose,
  code,
  reviewComments,
  areReviewCommentsLoading,
  onSubmit,
  isSubmitting,
}: ReviewCommentsProps) => {
  const { t } = useTranslation("pages");

  const { register, handleSubmit, formState, reset } =
    useForm<ReviewCommentFormProp>({
      defaultValues: {
        text: "",
      },
      resolver: yupResolver(useYup(reviewCommentFormSchema)),
    });

  const addCommentClickHandler = useCallback(
    (form: ReviewCommentFormProp) => {
      onSubmit(form.text);
      reset({
        text: "",
      });
    },
    [onSubmit, reset]
  );

  return (
    <>
      <IconButton variant="transparent" onClick={onOpen}>
        <AnnotationDots />
      </IconButton>
      <AstraConfirmationDialog
        open={open}
        onClose={onClose}
        title={`${t("schoolInquiryDetails.reviewComments.title")} ${code}`}
        bodyType="custom"
        body={
          <CommentsList
            reviewComments={reviewComments}
            areReviewCommentsLoading={areReviewCommentsLoading}
          />
        }
        buttons={
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <MessageTextField
              {...register("text")}
              placeholder={t("schoolInquiryDetails.reviewComments.placeholder")}
              sx={{
                ".MuiInputBase-input": {
                  minHeight: "80px",
                },
              }}
              error={"text" in formState.errors}
              helperText={formState.errors.text?.message}
              disabled={isSubmitting}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}
            >
              <Button
                variant="text"
                onClick={onClose}
                sx={{
                  marginRight: "auto",
                }}
              >
                {t("cancel", { ns: "common" })}
              </Button>
              <LoadingButton
                variant="contained"
                onClick={handleSubmit(addCommentClickHandler)}
                loading={isSubmitting}
              >
                {t("schoolInquiryDetails.reviewComments.addComment")}
              </LoadingButton>
            </Box>
          </Box>
        }
      />
    </>
  );
};
