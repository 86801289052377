import React, { useEffect } from "react";
import {
  Control,
  Controller,
  FormState,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InquiryFlattened } from "../../../slices/inquiries/types";
import { fieldError, FieldsErrorState } from "../../../utils/formHelpers";
import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { enGB } from "date-fns/locale";
import { AddInquiryForm } from "./types";
import { SelectInquiryPriority } from "../../../components/prefilledSelectors/SelectInquiryPriority";
import { SelectSchool } from "../../../components/prefilledSelectors/SelectSchool";
import { TagsSelectDepartments } from "../../../components/prefilledSelectors/TagsSelectDepartments";
import { createYupSchema } from "../../../utils/validation";
import { ControlledCustomField } from "../../../components/controlledFields/ControlledCustomField";
import { SelectPrincipal } from "../../../components/prefilledSelectors/SelectPrincipal";
import { deliveryTerms } from "../../../types";
import { SelectInquiryManager } from "../../../components/prefilledSelectors/SelectInquiryManager";
import { SelectOrderManager } from "../../../components/prefilledSelectors/SelectOrderManager";
import { SelectAstraEntity } from "../../../components/prefilledSelectors/SelectAstraEntity";
import { useGetAstraEntitiesAsOptionsQuery } from "../../../slices/astraEntities/api";
import { useGetSchoolsQuery } from "../../../slices/schools/api";
import { CustomFieldsField } from "../../../components/fields/CustomFieldsField";

export const inquiryFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    id: yup.number().positive().optional(),
    name: yup
      .string()
      .min(1, t("required"))
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    priority: yup.string().required(t("required")),
    dueDate: yup.date().required(t("required")).typeError(t("incorrectDate")),
    schoolId: yup.number().min(0, t("required")).required(t("required")),
    userId: yup.number().min(0, t("required")).optional().nullable(),
    departments: yup.array(),
    shippingCost: yup
      .number()
      .typeError(t("number"))
      .positive(t("positivePrice"))
      .optional(),
    shippingCostCurrencyId: yup.number().min(0, t("required")).optional(),
    paymentTerms: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    deliveryTerms: yup
      .string()
      .oneOf([...deliveryTerms, undefined, null])
      .nullable()
      .optional(),
    leadTime: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    validityOffer: yup
      .string()
      .nullable()
      .optional()
      .max(256, t("tooLong", { maxLength: 256 })),
    budget: yup.number().typeError(t("number")).nullable().optional(),
    budgetCurrencyId: yup.number().min(0, t("required")).optional(),
    astraEntityId: yup.number().positive().optional().nullable(),
    customFields: yup.array().of(
      yup.object({
        name: yup.string().min(1, t("required")).required(t("required")),
      })
    ),
  })
);

export type InquiryFormProps = {
  control: Control<AddInquiryForm>;
  formState: FormState<AddInquiryForm>;
  setValue: UseFormSetValue<AddInquiryForm>;
  inquiryData?: Omit<InquiryFlattened, "school"> & {
    schoolId: number;
  };
  fieldsErrors?: FieldsErrorState;
};

/**
 * Needs to be inside <FormProvider/>
 **/
export const InquiryForm: React.FC<InquiryFormProps> = ({
  control,
  formState,
  setValue,
  inquiryData,
  fieldsErrors,
}) => {
  const { t } = useTranslation("pages");

  const schoolId = useWatch({
    control,
    name: "schoolId",
  });

  const astraEntityId = useWatch({
    control,
    name: "astraEntityId",
  });

  const { data: schools = [] } = useGetSchoolsQuery();
  const { data: astraEntities = [] } = useGetAstraEntitiesAsOptionsQuery();

  useEffect(() => {
    const school = schools.find((school) => school.id === schoolId);

    const astraEntity = astraEntities.find(
      (astraEntity) => astraEntity.countryId === school?.country.id
    );

    if (astraEntity && !astraEntityId) {
      setValue("astraEntityId", astraEntity.id);
    }
  }, [astraEntities, astraEntityId, schoolId, schools, setValue]);

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
    >
      <Grid item gridColumn="span 6">
        <Controller
          control={control}
          name="schoolId"
          defaultValue={inquiryData ? inquiryData.schoolId : -1}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "schoolId",
              fieldsErrors
            );
            return (
              <SelectSchool
                fullWidth
                label={`${t("inquiries.fields.school")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 6">
        <ControlledCustomField
          control={control}
          fieldsErrors={fieldsErrors}
          name="userId"
          render={(errorMessage, { field }) => (
            <SelectPrincipal
              schoolId={schoolId}
              fullWidth
              label={t("inquiries.fields.principal")}
              error={!!errorMessage}
              helperText={errorMessage}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledCustomField
          control={control}
          fieldsErrors={fieldsErrors}
          name="inquiryManagersIds"
          defaultValue={
            inquiryData
              ? inquiryData.inquiryManagers.map(
                  (inquiryManager) => inquiryManager.id
                )
              : []
          }
          render={(errorMessage, { field }) => (
            <SelectInquiryManager
              fullWidth
              SelectProps={{ multiple: true }}
              error={!!errorMessage}
              helperText={errorMessage}
              label={t("inquiries.fields.inquiryManagers")}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <ControlledCustomField
          control={control}
          fieldsErrors={fieldsErrors}
          name="orderManagersIds"
          defaultValue={
            inquiryData
              ? inquiryData.orderManagers.map((orderManager) => orderManager.id)
              : []
          }
          render={(errorMessage, { field }) => (
            <SelectOrderManager
              fullWidth
              SelectProps={{ multiple: true }}
              error={!!errorMessage}
              helperText={errorMessage}
              label={t("inquiries.fields.orderManagers")}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="name"
          defaultValue={inquiryData ? inquiryData.name : ""}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "name",
              fieldsErrors
            );
            return (
              <TextField
                variant="outlined"
                size="small"
                label={`${t("inquiries.fields.name")} *`}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                fullWidth
                {...field}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="priority"
          defaultValue={inquiryData ? inquiryData.priority : "normal"}
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "roles",
              fieldsErrors
            );
            return (
              <SelectInquiryPriority
                label={`${t("inquiries.fields.priority")} *`}
                id="add-inquiry"
                size="small"
                selectProps={field}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="dueDate"
          defaultValue={
            inquiryData && inquiryData.dueDate
              ? new Date(inquiryData.dueDate)
              : null
          }
          render={({ field, fieldState: { invalid, error } }) => {
            const [serverInvalid, serverError] = fieldError(
              "dueDate",
              fieldsErrors
            );
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
                <DatePicker
                  label={`${t("inquiries.fields.dueDate")} *`}
                  PopperProps={{ placement: "bottom-start" }}
                  renderInput={({ ...params }) => {
                    return (
                      <TextField
                        helperText={error?.message || serverError}
                        fullWidth
                        size="small"
                        {...params}
                        error={invalid || serverInvalid}
                      />
                    );
                  }}
                  {...field}
                />
              </LocalizationProvider>
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="relatedDepartments"
          defaultValue={inquiryData ? inquiryData.relatedDepartments : []}
          render={({
            field: { value, ...field },
            fieldState: { invalid, error },
          }) => {
            const [serverInvalid, serverError] = fieldError(
              "departments",
              fieldsErrors
            );
            return (
              <TagsSelectDepartments
                id="add-inquiry"
                label={`${t("inquiries.fields.departments")}`}
                size="small"
                autocompleteProps={{
                  value: Array.isArray(value) ? value : [],
                  ...field,
                }}
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <Controller
          control={control}
          name="astraEntityId"
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => {
            const [serverInvalid, serverError] = fieldError(
              "astraEntityId",
              fieldsErrors
            );
            return (
              <SelectAstraEntity
                value={value}
                onChange={onChange}
                label={t("inquiries.fields.astraEntity")}
                fullWidth
                error={invalid || serverInvalid}
                helperText={error?.message || serverError}
                nullable
              />
            );
          }}
        />
      </Grid>
      <Grid item gridColumn="span 12">
        <CustomFieldsField control={control} formState={formState} />
      </Grid>
    </Grid>
  );
};
