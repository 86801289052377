import { ItemDetails } from "./types";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { useChangeItemsStatusMutation } from "../../../../../../slices/schoolInquiries/api";
import { useCallback } from "react";

export const useSendToReviewHandler = ({
  inquiryId,
  departmentInquiryId,
  assignmentsIds,
  itemId,
  possibleActions,
}: ItemDetails) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const [
    changeItemsStatusWithoutComment,
    { isLoading: isSubmittingWithoutComment },
  ] = useChangeItemsStatusMutation();

  const submitWithoutCommentHandler = useCallback(() => {
    return changeItemsStatusWithoutComment({
      inquiryId,
      departmentInquiryId,
      assignmentsIds,
      itemsIds: [itemId],
      schoolStatus: "needReviewByHead",
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    assignmentsIds,
    changeItemsStatusWithoutComment,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    itemId,
  ]);

  const [changeItemsStatusWithComment, { isLoading: isSubmittingWithComment }] =
    useChangeItemsStatusMutation();

  const submitWithCommentHandler = useCallback(
    (reviewComment?: string) => {
      return changeItemsStatusWithComment({
        inquiryId,
        departmentInquiryId,
        assignmentsIds,
        itemsIds: [itemId],
        schoolStatus: "needReviewByHead",
        reviewComment,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    },
    [
      assignmentsIds,
      changeItemsStatusWithComment,
      departmentInquiryId,
      handleError,
      handleSuccess,
      inquiryId,
      itemId,
    ]
  );

  const isPossible = possibleActions.includes("setNeedReviewByHead");

  const isVisible = possibleActions.includes("showNeedReviewAction");

  return {
    submitWithoutCommentHandler,
    isSubmittingWithoutComment,
    submitWithCommentHandler,
    isSubmittingWithComment,
    isPossible,
    isVisible,
  };
};
