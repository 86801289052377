import { getDefaultHeaders, getUrl } from "../../utils/api";
import { GetSigndedLinkResponseDto } from "../../utils/S3UploadFile";

export const uploadAstraEntityLogo = async (
  fileList: FileList | null | undefined
) => {
  if (!fileList) {
    return null;
  }

  const file = fileList[0];

  const response = await fetch(
    getUrl(
      `/astra-entities/get-signed-url-for-logo?fileName=${file.name}&fileType=${file.type}`
    ),
    {
      headers: getDefaultHeaders(true),
      method: "GET",
    }
  );

  if (!response.ok) {
    throw new Error();
  }

  const { signedUrl, key } =
    (await response.json()) as GetSigndedLinkResponseDto;

  await fetch(signedUrl, {
    method: "PUT",
    body: file,
  });

  return key;
};
