import React, { useMemo } from "react";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { useCanBulkSubmit } from "./useCanBulkSubmit";
import { TableControls } from "./TableControls";
import { useListTools } from "../../../../../components/List/useListTools";
import { useGetSchoolDepartmentInquiryItemsQuery } from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { List } from "../../../../../components/List/List";
import { useSelector } from "../../../../../store";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { useDepartmentInquiryItemsColumns } from "./useDepartmentInquiryItemsColumns";

export const DepartmentInquiryItems = () => {
  const {
    selectedIds,
    selectedIdsChangeHandler,
    expandedIds,
    expandedIdsChangeHandler,
  } = useListTools();

  const self = useSelector(selectSelf);

  const { baseUrl, inquiryId, departmentInquiryId } =
    useSchoolInquiryItemsTools();

  const canBulkSubmit = useCanBulkSubmit({ departmentInquiryId });

  const {
    data: items,
    isLoading: areItemsLoading,
    isFetching: areItemsFetching,
  } = useGetSchoolDepartmentInquiryItemsQuery(
    departmentInquiryId ? { departmentInquiryId } : skipToken
  );

  const columns = useDepartmentInquiryItemsColumns({
    baseUrl,
    inquiryId,
    departmentInquiryId,
    loading: areItemsFetching,
  });

  const displaySubRows = self?.role === "headOfDepartment";

  const itemsIds = useMemo(() => {
    if (!items) {
      return [];
    }

    return items.map((item) => item.id);
  }, [items]);

  return (
    <>
      <TableControls allIds={itemsIds} selectedIds={selectedIds} />
      <List
        selectedIds={canBulkSubmit ? selectedIds : undefined}
        onSelectedIdsChange={selectedIdsChangeHandler}
        columns={columns}
        rows={items ?? []}
        displaySkeleton={areItemsLoading}
        subRowsKey={displaySubRows ? "requestedItems" : undefined}
        expandedIds={expandedIds}
        onExpandedIdsChange={expandedIdsChangeHandler}
      />
    </>
  );
};
