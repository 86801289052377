import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../theme/astra/colors";

type CellProps = {
  label: string;
  value: string;
  currency?: string | null;
};

export const Cell = ({ label, value, currency }: CellProps) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <Typography
          component="span"
          sx={{
            color: colors.darkBlue,
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "500",
          }}
        >
          {value}
        </Typography>
        {currency && (
          <Typography
            component="span"
            sx={{
              color: colors.darkBlue,
              fontSize: "12px",
              lineHeight: "20px",
              fontWeight: "400",
              marginLeft: "4px",
            }}
          >
            {currency}
          </Typography>
        )}
      </Box>
      <Typography
        component="span"
        sx={{
          color: colors.dustBlue1,
          fontSize: "10px",
          lineHeight: "15px",
          marginTop: "-2px",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
