import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../../../theme/astra/colors";
import { useDisplayDateTime } from "../../../../../../utils/useDisplayDateTime";

type CommentProps = {
  fullName: string;
  text: string;
  createdAt: Date;
};

export const Comment = ({ fullName, text, createdAt }: CommentProps) => {
  const displayDate = useDisplayDateTime(true);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          gap: "10px",
        }}
      >
        <Typography
          component="span"
          sx={{
            fontSize: "16px",
            lineHeight: "19px",
            color: colors.darkBlue,
            fontWeight: "500",
          }}
        >
          {fullName}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: "13px",
            lineHeight: "19px",
            color: colors.dustBlue2,
          }}
        >
          {displayDate(createdAt)}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: "22px",
          color: colors.dustBlue2,
          fontWeight: "400",
          marginTop: "8px",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
