import React, { useCallback, useState } from "react";
import { LoadingIconButton } from "../../../../../../components/LoadingIconButton";
import { Button, Tooltip } from "@mui/material";
import { NeedReview } from "../../../../../../components/astraIcons/NeedReview";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useYup } from "../../../../../../utils/validation";
import { AstraConfirmationDialog } from "../../../../../../components/AstraConfirmationDialog";
import { LoadingButton } from "@mui/lab";
import { ReviewCommentFormProp } from "../../reviewComments/types";
import { reviewCommentFormSchema } from "../../reviewComments/reviewCommentFormSchema";
import { MessageTextField } from "../../reviewComments/MessageTextField";

export type SendToReviewActionProps = {
  onSubmitWithoutComment: () => Promise<unknown>;
  isSubmittingWithoutComment?: boolean;
  onSubmitWithComment: (reviewComment?: string) => Promise<unknown>;
  isSubmittingWithComment?: boolean;
  disabled: boolean;
};

export const SendToReviewAction = ({
  onSubmitWithoutComment,
  isSubmittingWithoutComment,
  onSubmitWithComment,
  isSubmittingWithComment,
  disabled,
}: SendToReviewActionProps) => {
  const { t } = useTranslation("pages");

  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const { register, handleSubmit, formState, watch, reset } =
    useForm<ReviewCommentFormProp>({
      defaultValues: {
        text: "",
      },
      resolver: yupResolver(useYup(reviewCommentFormSchema)),
    });

  const sendWithoutCommentClickHandler = useCallback(() => {
    reset({
      text: "",
    });

    onSubmitWithoutComment().then(() => {
      setOpen(false);
    });
  }, [onSubmitWithoutComment, reset]);

  const addCommentAndSendClickHandler = useCallback(
    (form: ReviewCommentFormProp) => {
      reset({
        text: "",
      });

      onSubmitWithComment(form.text).then(() => {
        setOpen(false);
      });
    },
    [onSubmitWithComment, reset]
  );

  return (
    <>
      <LoadingIconButton
        onClick={openDialog}
        disabled={disabled}
        variant="transparent"
      >
        <Tooltip
          arrow
          title={t("schoolInquiryDetails.itemActions.sendToReview.label")}
        >
          <NeedReview />
        </Tooltip>
      </LoadingIconButton>
      <AstraConfirmationDialog
        open={open}
        onClose={closeDialog}
        title={t(
          "schoolInquiryDetails.itemActions.sendToReview.confirmationTitle"
        )}
        bodyType="custom"
        body={
          <MessageTextField
            {...register("text")}
            placeholder={t(
              "schoolInquiryDetails.itemActions.sendToReview.placeholder"
            )}
            sx={{
              marginTop: "8px",

              ".MuiInputBase-input": {
                minHeight: "160px",
              },
            }}
            error={"text" in formState.errors}
            helperText={formState.errors.text?.message}
          />
        }
        buttons={
          <>
            <Button
              variant="text"
              onClick={closeDialog}
              sx={{
                marginRight: "auto",
              }}
            >
              {t("cancel", { ns: "common" })}
            </Button>
            <LoadingButton
              variant="outlined"
              onClick={sendWithoutCommentClickHandler}
              loading={isSubmittingWithoutComment}
            >
              {t(
                "schoolInquiryDetails.itemActions.sendToReview.sendWithoutComment"
              )}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit(addCommentAndSendClickHandler)}
              loading={isSubmittingWithComment}
              disabled={watch("text").length === 0}
            >
              {t(
                "schoolInquiryDetails.itemActions.sendToReview.addCommentAndSend"
              )}
            </LoadingButton>
          </>
        }
      />
    </>
  );
};
