import { useCallback } from "react";
import { useDeleteRequestedItemMutation } from "../../../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { RequestedItemDetails } from "./types";

export const useDeleteHandler = ({
  inquiryId,
  departmentInquiryId,
  assignmentId,
  requestedItemId,
  possibleActions,
}: RequestedItemDetails) => {
  const { handleSuccess, handleError } = useMessageHandlers();

  const [deleteRequestedItem, { isLoading }] = useDeleteRequestedItemMutation();

  const handle = useCallback(() => {
    deleteRequestedItem({
      inquiryId,
      departmentInquiryId,
      assignmentId,
      requestedItemId,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    assignmentId,
    deleteRequestedItem,
    departmentInquiryId,
    handleError,
    handleSuccess,
    inquiryId,
    requestedItemId,
  ]);

  const isPossible = possibleActions.includes("delete");

  const isVisible = possibleActions.includes("showDeleteAction");

  return { handle, isLoading, isPossible, isVisible };
};
