import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useApproveSchoolDepartmentInquiryMutation } from "../../../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { ConfirmationButton } from "../../../../../../components/ConfirmationButton";
import { Box, Button, styled } from "@mui/material";
import { useSelector } from "../../../../../../store";
import { Hint } from "../../../../../../components/hints/Hint";
import { selectSelfRole } from "../../../../../../slices/auth/selectors";
import { colors } from "../../../../../../theme/astra/colors";
import { ReactComponent as UnlockIcon } from "../../../../../../assets/newDesignItems/lock-unlocked.svg";

type ApproveProps = {
  inquiryId: number;
  departmentInquiryId: number;
  disabled: boolean;
  displayHelp: boolean;
};

export const Approve = ({
  inquiryId,
  departmentInquiryId,
  disabled,
  displayHelp,
}: ApproveProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const selfRole = useSelector(selectSelfRole);

  const [approve, { isLoading: isApproving }] =
    useApproveSchoolDepartmentInquiryMutation();

  const approveHandler = useCallback(() => {
    if (disabled || displayHelp) {
      return;
    }

    approve({ inquiryId, departmentInquiryId })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    approve,
    departmentInquiryId,
    disabled,
    displayHelp,
    handleError,
    handleSuccess,
    inquiryId,
  ]);

  if (selfRole !== "principal") {
    return null;
  }

  return (
    <Hint
      helpText={
        disabled
          ? t("hints.schoolInquiryDetails.departmentControls.approve.disabled")
          : t("hints.schoolInquiryDetails.departmentControls.approve.available")
      }
    >
      <ConfirmationButton
        component={SendToAstraButton}
        variant="contained"
        onClick={displayHelp ? undefined : approveHandler}
        confirmationTitle={
          displayHelp
            ? t(
                "schoolInquiryDetails.departmentControls.approve.approveAllFirst"
              )
            : t(
                "schoolInquiryDetails.departmentControls.approve.confirmationTitle"
              )
        }
        confirmationDescription={
          displayHelp
            ? t(
                "schoolInquiryDetails.departmentControls.approve.approveItemsFirst"
              )
            : t("noUndo", { ns: "common" })
        }
        proceedButtonText={displayHelp ? t("ok", { ns: "legacy" }) : undefined}
        loading={isApproving}
        disabled={disabled && !displayHelp}
        sx={{
          textTransform: "capitalize",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            marginLeft: "-10px",
          }}
        >
          <UnlockIcon />
          {t("schoolInquiryDetails.departmentControls.approve.newLabel")}
        </Box>
      </ConfirmationButton>
    </Hint>
  );
};

const SendToAstraButton = styled(Button)({
  color: colors.white,
  backgroundColor: colors.blue,
  fontSize: "13px",
});
