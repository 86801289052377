import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../../utils/capitalize";
import { useDisplayDateTime } from "../../../utils/useDisplayDateTime";
import { Box } from "@mui/system";
import { useInquiryDetailsUrlTools } from "../hooks";
import { useGetInquiryDetailsQuery } from "../../../slices/inquiries/api";
import { displayFullName } from "../../../utils/displayFullName";

export const InquiryData = () => {
  const { t } = useTranslation("pages");
  const displayDate = useDisplayDateTime(false);

  const { inquiryId } = useInquiryDetailsUrlTools();

  const { data: inquiry } = useGetInquiryDetailsQuery(inquiryId);

  if (!inquiry) {
    return null;
  }

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
      gap={2}
      sx={{ alignSelf: "left", marginBottom: 1 }}
    >
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.name")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {inquiry.name}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.school")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {inquiry.school?.name} ({inquiry.school?.currency.code})
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.priority")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {capitalize(inquiry.priority || t("na", { ns: "common" }))}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.dueDate")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {inquiry.dueDate ? displayDate(inquiry.dueDate) : t("na")}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 3">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiries.fields.astraEntity")}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {inquiry.astraEntity?.name ?? t("na", { ns: "common" })}
        </Typography>
      </Grid>
      <Grid item gridColumn="span 9">
        <Typography variant="subtitle2" gutterBottom>
          {t("inquiryDetails.departments")}
        </Typography>
        <Box sx={{ marginBottom: "8px" }}>
          {inquiry.relatedDepartmentsInquiries
            .map((department) => department.name)
            .join(", ")}
        </Box>
      </Grid>
      {inquiry.inquiryManagers.length > 0 && (
        <Grid item gridColumn="span 12">
          <Typography variant="subtitle2" gutterBottom>
            {t("inquiryDetails.inquiryManagers")}
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            {inquiry.inquiryManagers
              .map(({ firstName, lastName }) =>
                displayFullName({ firstName, lastName })
              )
              .join(", ")}
          </Box>
        </Grid>
      )}
      {inquiry.orderManagers.length > 0 && (
        <Grid item gridColumn="span 12">
          <Typography variant="subtitle2" gutterBottom>
            {t("inquiryDetails.orderManagers")}
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            {inquiry.orderManagers
              .map(({ firstName, lastName }) =>
                displayFullName({ firstName, lastName })
              )
              .join(", ")}
          </Box>
        </Grid>
      )}
      {inquiry.customFields.length > 0 && (
        <Grid item gridColumn="span 3">
          <Typography variant="subtitle2" gutterBottom>
            {t("inquiryDetails.customFields")}
          </Typography>
          <Box sx={{ marginBottom: "8px" }}>
            {inquiry.customFields.join(", ")}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
