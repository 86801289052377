import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCanBulkSubmit } from "./useCanBulkSubmit";
import { useSchoolInquiryItemsTools } from "../../../useSchoolInquiryItemsTools";
import { useGetSchoolAssignmentRequestedItemsQuery } from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { TableControls } from "./TableControls";
import { useListTools } from "../../../../../components/List/useListTools";
import { RequestedItemSchoolStatus } from "../../../../../types";
import { RequestedItemQuantityField } from "../fields/RequestedItemQuantityField";
import { useSchoolInquiryItemsListColumns } from "../useSchoolInquiryItemsListColumns";
import { List } from "../../../../../components/List/List";
import { useDisplayTotalAmount } from "../useDisplayTotalAmount";
import { RequestedItemActions } from "../../../../../slices/schoolInquiries/types";
import { REQUEST_ITEM_SCHOOL_STATUS_CHIP_STYLES } from "../../../../../consts";
import { SchoolStatusChip } from "../../../../../components/SchoolStatusChip";
import { RequestedItemStatusActions } from "../actions/requestedItems/RequestedItemStatusActions";
import { Box } from "@mui/material";
import { RequestedItemReviewComments } from "../reviewComments/RequestedItemReviewComments";

export const AssignmentItems = () => {
  const { t } = useTranslation("pages");

  const displayTotalAmount = useDisplayTotalAmount();
  const { selectedIds, selectedIdsChangeHandler } = useListTools();

  const { baseUrl, inquiryId, departmentInquiryId, assignmentId } =
    useSchoolInquiryItemsTools();

  const canBulkSubmit = useCanBulkSubmit({ assignmentId });

  const {
    data: requestedItems,
    isLoading: areRequestedItemsLoading,
    isFetching: areRequestedItemsFetching,
  } = useGetSchoolAssignmentRequestedItemsQuery(
    assignmentId ? { assignmentId } : skipToken
  );

  const columns = useSchoolInquiryItemsListColumns<{
    schoolStatus: RequestedItemSchoolStatus;
    reviewCommentsCount: number;
    possibleActions: RequestedItemActions[];
  }>({
    baseUrl,
    columns: useMemo(
      () => [
        {
          order: 4,
          column: {
            field: "quantity",
            name: t("schoolInquiryDetails.departmentColumns.quantity"),
            width: "150px",
            renderCell: (requestedItem) => {
              if (!departmentInquiryId || !assignmentId) {
                return null;
              }

              if (
                requestedItem.possibleActions.includes("hideChangeQuantity")
              ) {
                return requestedItem.quantity;
              }

              return (
                <RequestedItemQuantityField
                  id={requestedItem.id}
                  value={requestedItem.quantity}
                  inquiryId={inquiryId}
                  departmentInquiryId={departmentInquiryId}
                  assignmentId={assignmentId}
                  loading={areRequestedItemsFetching}
                  disabled={
                    !requestedItem.possibleActions.includes("changeQuantity")
                  }
                />
              );
            },
          },
        },
        {
          order: 8,
          column: {
            field: "totalAmount",
            name: t("schoolInquiryDetails.departmentColumns.totalAmount"),
            renderCell: ({ productCode, retailPrice, currency, totalAmount }) =>
              displayTotalAmount({
                productCode,
                retailPrice,
                currency,
                totalAmount,
              }),
          },
        },
        {
          order: 9,
          column: {
            field: "status",
            name: t("schoolInquiryDetails.departmentColumns.status"),
            width: "250px",
            renderCell: (requestedItem) =>
              departmentInquiryId &&
              assignmentId && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <SchoolStatusChip
                    label={t(
                      `requestedItemSchoolStatuses.${requestedItem.schoolStatus}`,
                      { ns: "common" }
                    )}
                    chipStyle={
                      REQUEST_ITEM_SCHOOL_STATUS_CHIP_STYLES[
                        requestedItem.schoolStatus
                      ]
                    }
                  />
                  <RequestedItemReviewComments
                    inquiryId={inquiryId}
                    departmentInquiryId={departmentInquiryId}
                    assignmentId={assignmentId}
                    id={requestedItem.id}
                    reviewCommentsCount={requestedItem.reviewCommentsCount}
                    code={requestedItem.productCode}
                  />
                </Box>
              ),
          },
        },
        {
          order: 10,
          column: {
            field: "actions",
            name: t("columns.actions", { ns: "common" }),
            boxSx: {
              justifyContent: "flex-end",
            },
            renderCell: (requestedItem) =>
              departmentInquiryId &&
              assignmentId && (
                <RequestedItemStatusActions
                  inquiryId={inquiryId}
                  departmentInquiryId={departmentInquiryId}
                  assignmentId={assignmentId}
                  requestedItemId={requestedItem.id}
                  possibleActions={requestedItem.possibleActions}
                />
              ),
          },
        },
      ],
      [
        areRequestedItemsFetching,
        assignmentId,
        departmentInquiryId,
        displayTotalAmount,
        inquiryId,
        t,
      ]
    ),
  });

  const requestedItemsIds = useMemo(() => {
    if (!requestedItems) {
      return [];
    }

    return requestedItems.map((requestedItem) => requestedItem.id);
  }, [requestedItems]);

  return (
    <>
      <TableControls allIds={requestedItemsIds} selectedIds={selectedIds} />
      <List
        selectedIds={canBulkSubmit ? selectedIds : undefined}
        onSelectedIdsChange={selectedIdsChangeHandler}
        columns={columns}
        rows={requestedItems ?? []}
        displaySkeleton={areRequestedItemsLoading}
      />
    </>
  );
};
