import { ItemSchoolStatus } from "../../../../../slices/items/types";
import { useSelector } from "../../../../../store";
import { selectSelf } from "../../../../../slices/auth/selectors";
import {
  useGetSchoolDepartmentInquiryForHeadQuery,
  useGetSchoolDepartmentInquiryForPrincipalQuery,
} from "../../../../../slices/schoolInquiries/api";
import { skipToken } from "@reduxjs/toolkit/query/react";

export const useCanBulkSubmit = ({
  departmentInquiryId,
}: {
  departmentInquiryId?: number;
}): ItemSchoolStatus | false => {
  const self = useSelector(selectSelf);

  // Having 2 separate queries here is clearly a design flow. But it's way better
  // than whatever used to be here before

  const { data: departmentInquiryForPrincipal } =
    useGetSchoolDepartmentInquiryForPrincipalQuery(
      self?.role === "principal" && departmentInquiryId
        ? { departmentInquiryId }
        : skipToken
    );

  const { data: departmentInquiryForHead } =
    useGetSchoolDepartmentInquiryForHeadQuery(
      self?.role === "headOfDepartment" && departmentInquiryId
        ? { departmentInquiryId }
        : skipToken
    );

  if (
    self?.role === "principal" &&
    departmentInquiryForPrincipal?.possibleActions?.includes("bulkApproveItems")
  ) {
    return "approvedByPrincipal";
  }

  if (
    self?.role === "headOfDepartment" &&
    departmentInquiryForHead?.possibleActions?.includes("bulkApproveItems")
  ) {
    return "approvedByHead";
  }

  return false;
};
