import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material";
import { colors } from "../../theme/astra/colors";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  minHeight: "360px",
  fontSize: "12px",

  border: `1px solid ${colors.dustBlue5}`,
  boxShadow: "2px 6px 12px 0px #1641730D",

  color: theme.palette.primary.dark,

  "& .MuiDataGrid-columnSeparator ": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaders": {
    maxHeight: "48px !important",
    minHeight: "48px !important",
    lineHeight: "48px !important",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 400,
  },
  "& .MuiDataGrid-virtualScroller": {
    marginTop: "48px !important",
  },
  "& .MuiDataGrid-columnHeader": {
    maxHeight: "48px !important",
    minHeight: "48px !important",

    color: theme.palette.secondary.dark,
    fontWeight: "400",
    textAlign: "center",

    backgroundColor: colors.dustBlue6,

    borderWidth: "0px 0px 1px 0px",
    borderColor: theme.palette.secondary.light,

    padding: "0px 12px 0px 16px",
    gap: "8px",
  },
}));
