import { createYupSchema } from "../../../utils/validation";
import * as yup from "yup";

export const countryFormSchemaCreator = createYupSchema((t) =>
  yup.object({
    id: yup.number().positive().optional(),
    name: yup
      .string()
      .min(1, t("tooShort", { minLength: 1 }))
      .max(256, t("tooLong", { maxLength: 256 }))
      .required(t("required")),
    code: yup
      .string()
      .min(2, t("tooShort", { minLength: 2 }))
      .max(3, t("tooLong", { maxLength: 3 }))
      .required(t("required")),
    defaultAstraEntityId: yup.number().positive().optional().nullable(),
    active: yup.boolean(),
  })
);
