import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../../../utils/usePermissions";
import { useItemsTableBaseUrl } from "../../../hooks";
import { useGetInquiryCustomFieldsQuery } from "../../../../../slices/inquiries/api";
import { useImportHandler } from "./useImportHandler";

export const ImportItems = () => {
  const { t } = useTranslation("pages");

  const can = usePermissions();

  const { inquiryId } = useItemsTableBaseUrl();

  const { data: customFields, isLoading: areCustomFieldsLoading } =
    useGetInquiryCustomFieldsQuery({ inquiryId });

  const clickHandler = useImportHandler();

  const fieldsOrderLine = ["Supplier", "Code", "Name", "Quantity"];

  if (customFields && customFields.length !== 0) {
    fieldsOrderLine.push(...customFields);
  }

  fieldsOrderLine.push("Custom Item ID (optional)");

  return (
    <ConfirmationButton
      onClick={clickHandler}
      component={IconButton}
      confirmationTitle={t("items.importItems.confirmation.title")}
      confirmationDescription={`${t(
        "items.importItems.confirmation.description"
      )}: ${fieldsOrderLine.join(", ")}`}
      disabled={!can("importItems") || areCustomFieldsLoading}
    >
      <Tooltip title={t("items.importFile") as string}>
        <FileUploadOutlinedIcon />
      </Tooltip>
    </ConfirmationButton>
  );
};
