import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTableUrlTools } from "../../../utils/useTableUrlTools";
import { NewDesignDisplayContainer } from "../../../components/wideDrawer/NewDesignDisplayContainer";
import { useGetUserQuery } from "../../../slices/users/api";
import { usePermissions } from "../../../utils/usePermissions";
import { useSelector } from "../../../store";
import { UserDetails } from "./Details/UserDetails";
import { selectSelfId } from "../../../slices/auth/selectors";
import { skipToken } from "@reduxjs/toolkit/query";

export const Details: React.FC = () => {
  const { t } = useTranslation("pages");

  const { id, closeDrawer } = useTableUrlTools("/users");
  const selfId = useSelector(selectSelfId);
  const can = usePermissions();

  const { data: user, error: userLoadingError } = useGetUserQuery(
    id ?? skipToken
  );

  useEffect(() => {
    if (userLoadingError) {
      closeDrawer();
    }
  }, [userLoadingError, closeDrawer]);

  const canEdit = useMemo(
    () =>
      can("editUsers") ||
      can("editUsersOfOwnSchool") ||
      (can("editSelf") && id === selfId),
    [can, id, selfId]
  );

  if (!user) {
    return null;
  }

  return (
    <NewDesignDisplayContainer
      heading={`${user.firstName} ${user.lastName}`}
      editHref={`/users/${id}/edit`}
      editLabel={t("users.edit")}
      editingDisabled={!canEdit}
    >
      <UserDetails user={user} />
    </NewDesignDisplayContainer>
  );
};
